import { useState, useEffect, useRef, useMemo, useCallback, useSyncExternalStore, memo } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { assetsTextRewriteTextAssetCreate, assetsTextUpdate } from '@goldcast/api/content';
import PostList from './components/PostList';
import { TEXT_QUEUE_INTERVAL, TEXT_QUEUE_CHUNK_SIZE, POST_TYPES, contentPrompts, eventPrompts } from './constants';
import { TextAsset, TextConfig, TextGenerateWithMultiModal } from './types';
import GeneratePostModal from './components/GeneratePostModal';
import PostOutputEditor from './components/PostOutputEditor';
import PostHelpText from './components/PostHelpText';
import VoiceProfileStepRenderer from '../BrandKit/components/BrandTone/VoiceProfileStepRenderer';
import { useAppContext } from '@/context/AppContext/AppContext';
import { loadTextAssets } from '@/libs/textContentUtil';
import { showErrorToast } from '@/libs/toast/toast';
import Loader from '@/components/atoms/Loader';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { core } from '@/stores/core';
import { generateBlogPost, generateMultiModalPost } from '@/libs/text';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useAnalytics from '@/hooks/useAnalytics';
import { isCustomUpload } from '@/libs/clipContentUtil';
import { classnames } from '@/libs/utils';
import usePost from '@/hooks/usePost';
import { userPreferencesStore, toggleListCollapse } from '@/stores/userPreferencesStore';
import { usePostGenerationContext } from '@/context/PostGenerationContext/PostGenerationContext';
import { promptOverrides } from '@/stores/debugToolsStores';
import useAssetRating from '@/hooks/useAssetRating';
import { loadVoiceProfileList } from '@/stores/voiceProfile';

function Post({ postType }) {
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();
  const location = useLocation();
  const { logger } = useAppContext();
  const { incrementTextSummariesUsed } = useFreeTrialHook();
  const { resetAllAssetRatings } = useAssetRating();

  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedPost, setSelectedPost] = useState<TextAsset | null>(null);
  const [posts, setPosts] = useState<TextAsset[]>([]);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isGeneratingPost, setIsGeneratingPost] = useState(false);
  const [textQueue, setTextQueue] = useState<string>('');
  const [output, setOutput] = useState<string>('');
  const [isRewriting, setIsRewriting] = useState(false);
  const { isListCollapsed } = useSyncExternalStore(userPreferencesStore.subscribe, userPreferencesStore.getSnapshot);
  const abortController = useRef<AbortController | null>(null);
  const outputIntervalId = useRef<ReturnType<typeof setInterval>>();
  const currentQueueIndex = useRef(0);
  let processingStarted: boolean = false;
  const assetType = useMemo(() => (postType === POST_TYPES.SOCIAL ? 'LINKEDIN' : postType), [postType]);
  const [searchParams, setSearchParams] = useSearchParams();
  const activePostId = searchParams.get('activePostId');
  const content = core.getSnapshot().content;
  const { trackTextFetched, trackContentCreated } = useAnalytics();
  const { handleSelectedPostChange } = usePost();
  const isGeneratingAssets = usePostGenerationContext();

  function getTextAssets() {
    return loadTextAssets(eventId!, broadcastId!, assetType);
  }

  const { isFetching, refetch } = useQuery({
    queryKey: [broadcastId, eventId, postType],
    queryFn: getTextAssets,
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
    enabled: !isGeneratingAssets,
    onSuccess: data => {
      setSelectedPost(null);
      setOutput('');
      onPostSelected(data.find(post => post.id === activePostId) || data[0]);
      setPosts(data);
      trackTextFetched({ count: (data || []).length });
    },
    onError: () =>
      showErrorToast(
        'Whoops! Struggling to fetch the generated text right now. Could you check your network connection and give it another whirl?'
      )
  });

  useEffect(() => {
    if (isGeneratingAssets) {
      setPosts([]);
      setSelectedPost(null);
      setOutput('');
    }
  }, [isGeneratingAssets]);

  const onPostDelete = () => {
    const selectedPostIndex = posts.findIndex(post => post.id === selectedPost?.id);
    const nextPost = posts[selectedPostIndex + 1] || posts[selectedPostIndex - 1] || posts[0];
    onPostSelected(nextPost);
    refetch();
  };

  const onPostSelected = (post: TextAsset | null) => {
    const prevActivePostId = searchParams.get('activePostId');

    if (post && post.id === activePostId) {
      return;
    }

    if (!post) {
      setSearchParams(undefined, { replace: !prevActivePostId });
    } else {
      setSearchParams({ activePostId: post.id }, { replace: !prevActivePostId });
    }
  };

  const onGeneratePost = () => {
    setIsGenerateModalOpen(true);
  };

  const handlePostStream = useCallback((chunk, isPromptChanged, source, prompt = undefined) => {
    const decoder = new TextDecoder();
    if (chunk) {
      setTextQueue(prev => prev + decoder.decode(chunk));
    } else {
      setIsGeneratingPost(false);
      refetch();
      currentQueueIndex.current = 0;
      setTextQueue('');
      EventBus.dispatch(CustomEvents.ReloadAllClips);
      trackContentCreated({
        source,
        state: 'Created',
        assetType,
        isPromptChanged,
        prompt
      });
    }
  }, []);

  const generate = async prompt => {
    abortController.current = new AbortController();
    setIsGeneratingPost(true);
    setOutput('');
    onPostSelected(null);
    const isMultimodal = assetType === 'LINKEDIN';
    const customBody: TextGenerateWithMultiModal = {
      ...prompt,
      is_multimodal: isMultimodal,
      event_id: eventId!,
      broadcast_id: broadcastId!,
      text_type: assetType,
      constant_prompt_overrides: promptOverrides.getSnapshot()
    };
    const defaultPrompt = prompt !== isCustomUpload() ? contentPrompts[postType] : eventPrompts[postType];
    const isPromptChanged = defaultPrompt !== prompt.prompt;
    trackContentCreated({
      isPromptChanged,
      source: 'Prompt',
      state: 'Started',
      assetType,
      isMultimodal,
      prompt: prompt.prompt
    });

    try {
      await generateBlogPost(customBody, abortController.current!.signal, chunk =>
        handlePostStream(chunk, isPromptChanged, 'Prompt', prompt.prompt)
      );
      incrementTextSummariesUsed(1);
    } catch (e) {
      trackContentCreated({
        isPromptChanged,
        source: 'Prompt',
        state: 'Failed',
        assetType,
        failureReason: `${e}`,
        isMultimodal,
        prompt: prompt.prompt
      });
      logger.error(e);
      showErrorToast('Whoops! We hit a snag generating text. Check your inputs and try again.');
    }
  };

  const generateSocialPost = useCallback(
    async (clipId: string) => {
      abortController.current = new AbortController();
      setIsGeneratingPost(true);
      const customBody = {
        organization: content?.organization as string,
        content_id: broadcastId!,
        project_id: eventId!,
        length: 'short',
        type: 'LINKEDIN',
        brand_tone: 'professional',
        is_multimodal: true,
        clip_assets: [clipId],
        text_type: assetType
      };
      trackContentCreated({
        source: 'ClipSocialPost',
        state: 'Started',
        assetType,
        isMultimodal: true
      });
      try {
        await generateMultiModalPost(customBody, abortController.current!.signal, chunk =>
          handlePostStream(chunk, false, 'ClipSocialPost')
        );
        setSearchParams(undefined, { replace: true });
        incrementTextSummariesUsed(1);
      } catch (e) {
        logger.error(e);
        trackContentCreated({
          source: 'ClipSocialPost',
          state: 'Failed',
          assetType,
          failureReason: `${e}`,
          isMultimodal: true
        });
        showErrorToast('Whoops! We hit a snag while generating social post content. Please try again.');
      }
    },
    [
      assetType,
      broadcastId,
      content?.organization,
      eventId,
      handlePostStream,
      incrementTextSummariesUsed,
      trackContentCreated
    ]
  );

  useEffect(() => {
    if (location.state?.clipId) {
      generateSocialPost(location.state.clipId);
    }
  }, [location.state?.clipId, generateSocialPost]);

  useEffect(() => {
    return () => {
      if (abortController.current) {
        setTextQueue('');
        setIsGeneratingPost(false);
        abortController.current.abort();
      }
      resetAllAssetRatings();
    };
  }, [postType]);

  useEffect(() => {
    if (processingStarted || !textQueue.length) return;
    outputIntervalId.current = setInterval(() => {
      processingStarted = true;
      const newOutput = textQueue.substring(0, currentQueueIndex.current);
      setOutput(newOutput);
      currentQueueIndex.current = currentQueueIndex.current + TEXT_QUEUE_CHUNK_SIZE;

      if (currentQueueIndex.current > textQueue.length + TEXT_QUEUE_CHUNK_SIZE) {
        clearInterval(outputIntervalId.current);
      }
    }, TEXT_QUEUE_INTERVAL);
    return () => clearInterval(outputIntervalId.current);
  }, [textQueue]);

  useEffect(() => {
    const activePost = posts.find(post => post.id === activePostId);
    if (activePost) {
      setSelectedPost(activePost);
      handleSelectedPostChange(activePost);
      setOutput(activePost?.asset_metadata?.output || '');
    }
  }, [activePostId, posts]);

  useEffect(() => {
    loadVoiceProfileList().catch(e => {
      logger.error('Failed to load saved voice profile', e);
    });
  }, []);

  const handleGenerateClick = prompt => {
    generate(prompt);
    setIsGenerateModalOpen(false);
  };

  const onClose = () => {
    setIsGenerateModalOpen(false);
  };

  const updateTextChange = content => {
    if (!selectedPost) return;
    const previousOutput = output;
    const updatedMetadata = {
      ...selectedPost.asset_metadata,
      output: content.output
    };
    setIsUpdating(true);
    assetsTextUpdate({
      id: selectedPost.id,
      body: {
        title: content.title,
        asset_metadata: updatedMetadata
      }
    })
      .then(newPost => {
        const updatedPost = posts.map(post =>
          post.id === selectedPost.id
            ? { ...post, title: newPost.title, updated_at: newPost.updated_at, asset_metadata: updatedMetadata }
            : post
        );
        setOutput(content.output);
        setPosts(updatedPost);
      })
      .catch(() => {
        logger.error('Error updating text');
        setOutput(previousOutput);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleAssetUpdate = (updatedAssetMetadata: TextConfig) => {
    if (!selectedPost) return;

    const updatedSelectedPost = {
      ...selectedPost,
      asset_metadata: updatedAssetMetadata
    };
    const updatedPostList = posts.map(post => (post.id === selectedPost.id ? updatedSelectedPost : post));

    setSelectedPost(updatedSelectedPost);
    setPosts(updatedPostList);
  };

  const onActiveClipChange = async (index: number) => {
    if (!selectedPost) return;
    const updatedMetadata = {
      ...selectedPost?.asset_metadata,
      currentClipIndex: index
    };
    const updatedPost = {
      ...selectedPost,
      asset_metadata: updatedMetadata
    };
    setSelectedPost(updatedPost);
    setPosts(posts.map(post => (post.id === selectedPost?.id ? updatedPost : post)));
    await assetsTextUpdate({
      id: selectedPost?.id || '',
      body: { asset_metadata: updatedMetadata, title: selectedPost?.title || '' }
    }).catch(e => {
      showErrorToast('Whoops! We hit a snag while updating the active clip. Please try again.');
      logger.error(e);
    });
  };

  const onPostShared = () => {
    setSelectedPost({
      ...selectedPost!,
      shared: true
    });
  };

  const onSelectedVoiceProfile = ({ value, type }) => {
    setIsRewriting(true);
    const body = {
      ...(type === 'default' && { tone_of_voice: value }),
      ...(type === 'custom' && { voice_profile: value })
    };
    assetsTextRewriteTextAssetCreate({
      id: selectedPost?.id || '',
      body
    })
      .then(() => {
        refetch();
        setSearchParams(undefined, { replace: true });
      })
      .catch(e => {
        showErrorToast(
          'Whoops! We encountered an issue while attempting to rewrite the post using the selected voice profile. Please try again.'
        );
        logger.error(e);
      })
      .finally(() => {
        setIsRewriting(false);
      });
  };

  return (
    <>
      <div className="flex h-full overflow-hidden">
        <div
          className={classnames(
            'absolute flex h-full w-[29rem] overflow-visible py-2 transition-all duration-[400ms] ease-in-out',
            {
              '!w-[20rem]': isListCollapsed
            }
          )}
        >
          <PostList
            posts={posts}
            selectedPost={selectedPost}
            isCollapsed={isListCollapsed}
            postType={postType}
            isGeneratingPost={isGeneratingPost}
            isLoading={isFetching}
            onDelete={onPostDelete}
            onPostSelected={onPostSelected}
            onGeneratePost={onGeneratePost}
            toggleCollapse={toggleListCollapse}
            isGeneratingAssets={isGeneratingAssets}
          />
        </div>
        <div
          className={classnames(
            'flex grow flex-col overflow-hidden pl-[29rem] transition-all duration-[400ms] ease-in-out',
            {
              '!pl-[20rem]': isListCollapsed
            }
          )}
        >
          <div className="relative flex grow flex-col overflow-hidden">
            {isFetching || (isGeneratingPost && !output) ? (
              <div className="absolute inset-1/2">
                <Loader />
              </div>
            ) : !isGeneratingPost && !posts.length && isGeneratingAssets ? (
              <PostHelpText postType={postType} isGeneratingAssets={isGeneratingAssets} />
            ) : output || selectedPost ? (
              <PostOutputEditor
                output={output}
                selectedPost={selectedPost}
                isGeneratingPost={isGeneratingPost}
                isUpdating={isUpdating}
                isRewriting={isRewriting}
                updateTextChange={updateTextChange}
                onSelectedAssetUpdate={handleAssetUpdate}
                onActiveClipChange={onActiveClipChange}
                onPostShared={onPostShared}
                onSelectedVoiceProfile={onSelectedVoiceProfile}
                postType={postType}
              />
            ) : (
              <PostHelpText postType={postType} isGeneratingAssets={isGeneratingAssets} />
            )}
          </div>
        </div>
      </div>
      <GeneratePostModal
        isOpen={isGenerateModalOpen}
        postType={postType}
        handleGenerateClick={handleGenerateClick}
        onClose={onClose}
      />
      <VoiceProfileStepRenderer />
    </>
  );
}

export default memo(Post);
