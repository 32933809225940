import { useMemo, useState } from 'react';
import { AssetList, Downloads, TypeEnum, assetsDownloadCreate, assetsTextDownloadCreate } from '@goldcast/api/content';
import { useAppContext } from '@/context/AppContext/AppContext';
import useEmailVerifiedHook from '@/hooks/useEmailVerifiedHook';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { downloadURI } from '@/libs/core';
import { showActionToast, showErrorToast, showWarningToast } from '@/libs/toast/toast';
import { currentUser } from '@/stores/user';
import useDownloadToasts from '@/hooks/useDownloadToasts';
import { ApiButtonStatus } from '@/components/atoms/ApiButton/ApiButtonTypes';
import useAnalytics from '@/hooks/useAnalytics';
import { Clip } from '@/domains/asset';
import { TextAsset } from '@/Pages/PostsListPage/types';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { downloadsPopupOpen, loadContentDownloadsList } from '@/stores/downloadsStore';
import { storeDownloadIdForSession } from '@/libs/downloadSessionUtil';
import { savedAISearch } from '@/stores/savedAISearch';

export default function useDownloadAsset(
  asset: {
    type: TypeEnum;
    clip?: Pick<AssetList, 'asset_metadata' | 'id' | 'title' | 'content'> | undefined;
    textAsset?: TextAsset | null;
  },
  navigateToClip?: (clip: AssetList, options: { downloadIntent: boolean }) => void
) {
  const { logger, adminAppStore } = useAppContext();
  const { isFreeTrialEnabled, isFreemiumUser } = useFreeTrialHook();
  const { checkEmailVerified } = useEmailVerifiedHook();
  const { showClipDownloadFailedToast, showTextAssetDownloadFailedToast } = useDownloadToasts();
  const [isDownloadProcessed, setIsDownloadProcessed] = useState(false);
  const [isDownloadFailed, setIsDownloadFailed] = useState(false);
  const [isDownloadProcessingStarted, setIsDownloadProcessingStarted] = useState(false);
  const [shouldUpscaleVideo, setShouldUpscaleVideo] = useState(false);
  const isUpscaleVideoEnabled = useMemo(
    () => asset.clip?.asset_metadata?.layout !== 'AUDIOGRAM',
    [asset.clip?.asset_metadata?.layout]
  );
  const { trackDownloadContent } = useAnalytics();

  function onDownloadClip(): Promise<void> {
    if ((isFreeTrialEnabled || isFreemiumUser) && !checkEmailVerified()) {
      return Promise.reject();
    }

    adminAppStore.dispatch?.('user/showForceChangePasswordDialogIfRequired');

    if (asset.clip && !asset.clip?.asset_metadata.config) {
      navigateToClip?.(asset.clip as AssetList, { downloadIntent: true });

      return Promise.reject();
    }

    if (asset.clip && asset.clip.asset_metadata.isLayoutProcessing) {
      showWarningToast('Speaker detection in progress, please try again later.');
      return Promise.reject();
    }

    trackDownloadContent({
      clip: asset.clip,
      assetType: asset.type,
      state: 'ButtonClick',
      isUpscaled: shouldUpscaleVideo
    });

    return onAsyncDownloadClip();
  }

  function onDownloadTextAsset(): Promise<void> {
    if ((isFreeTrialEnabled || isFreemiumUser) && !checkEmailVerified()) {
      return Promise.reject();
    }

    adminAppStore.dispatch?.('user/showForceChangePasswordDialogIfRequired');

    if (!asset.textAsset) {
      return Promise.reject();
    }

    trackDownloadContent({ textAsset: asset.textAsset, assetType: asset.type, state: 'ButtonClick' });

    return onAsyncDownloadTextAsset();
  }

  function onAsyncDownloadTextAsset(): Promise<any> {
    if (!asset.textAsset) {
      return Promise.reject();
    }

    return assetsTextDownloadCreate({
      id: asset.textAsset.id
    })
      .then(data => {
        if (data.status === 'FAILED') {
          setIsDownloadFailed(true);
          showTextAssetDownloadFailedToast(data, asset.textAsset!);
          return data;
        }

        onDownloadTriggerSuccess(data);
        return data;
      })
      .catch(err => {
        if (!err?.message) {
          logger.error('Error while async downloading text asset', err);
        }

        trackDownloadContent({ textAsset: asset.textAsset, assetType: asset.type, state: 'Failed' });
        showErrorToast(
          err?.message ||
            'Download failed due to a technical issue. Please try again later or contact support for help.'
        );
      });
  }

  function onAsyncDownloadClip(): Promise<any> {
    if (!asset.clip) {
      return Promise.reject();
    }

    return assetsDownloadCreate({
      body: {
        asset: asset.clip.id,
        organization: currentUser.getSnapshot()?.organization as string,
        post_processed_config: {
          upscale: shouldUpscaleVideo
        }
      }
    })
      .then(data => {
        const downloadsData = data as Downloads;
        if (downloadsData.status === 'FAILED') {
          setIsDownloadFailed(true);
          showClipDownloadFailedToast(downloadsData, asset.clip as Clip, shouldUpscaleVideo);
          return data;
        }
        onDownloadTriggerSuccess(downloadsData);
        return data;
      })
      .catch(err => {
        logger.error('Error while async downloading clip', err);
        trackDownloadContent({
          clip: asset.clip,
          assetType: asset.type,
          state: 'Failed',
          isUpscaled: shouldUpscaleVideo
        });
        showErrorToast('Download failed due to a technical issue. Please try again later or contact support for help.');
      });
  }

  async function onDownloadTriggerSuccess(downloadsData: any) {
    const isProcessed = !!downloadsData.download_url;
    const contendId = asset.clip?.content?.id || asset.textAsset?.content?.id || '';

    setIsDownloadProcessed(isProcessed);

    if (isProcessed) {
      downloadURI(downloadsData.download_url);
      loadContentDownloadsList(contendId, savedAISearch.getSnapshot()?.id);
    } else {
      await loadContentDownloadsList(contendId, savedAISearch.getSnapshot()?.id);
      EventBus.dispatch(CustomEvents.CloseSharePopup);
      EventBus.dispatch(CustomEvents.DownloadTriggerSuccess);
      storeDownloadIdForSession(downloadsData.id);

      if (!downloadsPopupOpen.getSnapshot()) {
        showActionToast('success', {
          title: 'Processing Started',
          content: (
            <div>
              We'll send an email to <span className="tw-font-bold">{adminAppStore.user!.email}</span> when your
              download is ready.
            </div>
          )
        });
      }
    }
  }

  const updateDownloadState = (status: ApiButtonStatus) => {
    setIsDownloadProcessingStarted(status === ApiButtonStatus.COMPLETED);
    // Only if it's not completed we reset processed or failed states
    if (status !== ApiButtonStatus.COMPLETED) {
      setIsDownloadProcessed(false);
      setIsDownloadFailed(false);
    }
  };

  return {
    isDownloadProcessed,
    isDownloadFailed,
    isDownloadProcessingStarted,
    shouldUpscaleVideo,
    isUpscaleVideoEnabled,
    onDownloadClip,
    onDownloadTextAsset,
    updateDownloadState,
    setShouldUpscaleVideo
  };
}
