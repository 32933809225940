import React from 'react';
import EmptyProfilePicturePlaceHolder from './EmptyProfilePicturePlaceHolder';
import AudiogramImage from './AudiogramImage';
import { LandscapeProps } from './types';
import AudiogramCaptions from './AudiogramCaptions';
import Waveform from '@/components/atoms/Waveform/Waveform';
import Captions from '@/components/molecules/Captions';
import { getSpeakerImage } from '@/libs/utils';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function Landscape({
  previewOnly = false,
  height,
  width,
  sizeMultiplier = 1,
  backgroundColor,
  textColor,
  activeSpeaker,
  truncatedName,
  truncatedTitle,
  fontFamilyStyle,
  isTemplatePreview,
  backgroundImage
}: LandscapeProps) {
  const speakerImage = getSpeakerImage(activeSpeaker.profile_picture_url);
  const imageStyle = speakerImage && previewOnly ? { backgroundImage: `url(${speakerImage})` } : {};
  const { playerStore } = useClipsContext();

  const backgroundStyle = !backgroundImage ? { backgroundColor } : { backgroundImage: `url(${backgroundImage})` };
  return (
    <div
      className={`flex h-full ${
        previewOnly ? 'static' : 'absolute'
      } left-0 top-0 overflow-hidden rounded bg-cover bg-center`}
    >
      <div
        className="aspect-square bg-cover bg-center"
        data-testid="speaker-image"
        style={{
          backgroundColor,
          ...imageStyle,
          width: width / 2 + 'px'
        }}
      >
        {activeSpeaker.id && !activeSpeaker.profile_picture_url ? (
          <EmptyProfilePicturePlaceHolder previewOnly={previewOnly || isTemplatePreview} />
        ) : (
          !previewOnly && <AudiogramImage activeSpeaker={activeSpeaker} />
        )}
      </div>
      <div
        className="flex grow flex-col justify-between bg-cover bg-center"
        style={{
          color: textColor,
          width: width / 2 + 'px',
          padding: width * 0.037 + 'px ' + width * 0.033 + 'px',
          ...backgroundStyle
        }}
      >
        <div style={{ lineHeight: width * 0.04 * sizeMultiplier + 'px' }}>
          <Captions previewOnly={previewOnly || isTemplatePreview}>
            <AudiogramCaptions
              width={width}
              fontFamilyStyle={fontFamilyStyle}
              sizeMultiplier={sizeMultiplier}
              textColor={textColor}
              fontSizeCorrectionFactor={0.039}
            />
          </Captions>
        </div>

        <div className="flex items-center justify-between gap-3">
          <div>
            <div
              className="w-full text-left"
              style={{ fontSize: width * 0.023 + 'px', lineHeight: width * 0.023 + 'px', ...fontFamilyStyle }}
            >
              {truncatedName}
            </div>
            <div className="w-full text-left" style={{ fontSize: width * 0.023 + 'px', ...fontFamilyStyle }}>
              {truncatedTitle}
            </div>
          </div>
          {textColor && <Waveform textColor={textColor} paused={playerStore.paused} height={height * 0.085} />}
        </div>
      </div>
    </div>
  );
}
