import { SetupContentStep } from '../types';
import { getPredefinedSteps } from '../constants';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { isCustomUpload } from '@/libs/clipContentUtil';

const EMPTY_SPEAKER = {
  first_name: '',
  last_name: '',
  company: '',
  title: '',
  profile_picture_url: ''
};

export function getDefaultSpeakersValues(
  speakerValues: Record<string, SpeakerWithDetails>
): Record<string, SpeakerWithDetails> {
  const payload = {};
  Object.keys(speakerValues).forEach((speakerKey, i) => {
    const speaker = speakerValues[speakerKey];
    payload[i] = {
      ...EMPTY_SPEAKER,
      first_name: speaker.first_name || `Speaker ${i + 1}`,
      last_name: speaker.last_name || '',
      profile_picture_url: speaker.profile_picture_url || '',
      key: speaker.key
    };
  });
  return payload;
}

export function areAllDefaultSpeakers(speakersWithDetails: Record<string, SpeakerWithDetails>): boolean {
  return Object.keys(speakersWithDetails).every(key => {
    return Number.isInteger(speakersWithDetails[key].id);
  });
}

export function mapSpeakerToRequest(speaker: SpeakerWithDetails, contentId: string) {
  return {
    id: speaker.id,
    first_name: speaker.first_name,
    last_name: speaker.last_name,
    title: speaker.title,
    company: speaker.company,
    profile_picture_url: speaker.profile_picture_url,
    content: contentId,
    label: speaker.key,
    key: speaker.key,
    pronouns: speaker.pronouns
  };
}

function moveIdentifiedSpeakersToTheEnd(speakerA: SpeakerWithDetails, speakerB: SpeakerWithDetails, clipId?: string) {
  if (!clipId) {
    return 0;
  }

  const speakersAnalysis = speakersAnalysisStore.getSnapshot();
  const speakerAnalysisForClip = speakersAnalysis[clipId].speaker_mapping;

  const isSpeakerAIdentified = speakerAnalysisForClip[speakerA.id]?.length > 0;
  const isSpeakerBIdentified = speakerAnalysisForClip[speakerB.id]?.length > 0;

  if (isSpeakerAIdentified && !isSpeakerBIdentified) {
    return 1;
  }

  if (!isSpeakerAIdentified && isSpeakerBIdentified) {
    return -1;
  }

  return 0;
}

export function getSetupContentSteps(
  speakers: SpeakerWithDetails[],
  isInitialPopup: boolean,
  clipId?: string,
  isInlineIdentification?: boolean
): SetupContentStep[] {
  const numberOfSpeakers = speakers.length;
  const predefinedSteps = getPredefinedSteps(isInitialPopup);
  let newSpeakers: SpeakerWithDetails[] = [...speakers];
  if (clipId && isCustomUpload()) {
    newSpeakers = newSpeakers.sort((speakerA, speakerB) => moveIdentifiedSpeakersToTheEnd(speakerA, speakerB, clipId));
  }

  return [
    ...predefinedSteps,
    ...newSpeakers.map((speaker, index) => {
      return {
        title: `Who is speaking${isInlineIdentification ? ' in this Clip' : ''}? (${index + 1} of ${numberOfSpeakers})`,
        key: index + predefinedSteps.length, // 2 predefined steps
        speakerData: getContentSpeakerInfo(isInitialPopup, speaker, clipId),
        showSaveButton: index + 1 === numberOfSpeakers
      };
    })
  ];
}

function getContentSpeakerInfo(
  isInitialPopup: boolean,
  speaker: SpeakerWithDetails,
  clipId?: string
): SpeakerWithDetails {
  let speakerAnalysisForClip = {};
  if (clipId && !isInitialPopup) {
    const speakerAnalysis = speakersAnalysisStore.getSnapshot();
    speakerAnalysisForClip = speakerAnalysis[clipId].speaker_mapping;
  }
  const { face_ids, ...speakerWithoutFaceIds } = speaker;

  return isInitialPopup
    ? {
        ...speaker,
        face_ids: [],
        ...EMPTY_SPEAKER
      }
    : { ...speakerWithoutFaceIds, face_ids: speakerAnalysisForClip[speaker.id] || [] };
}
