import React, { useCallback, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import { IconVideo } from '@tabler/icons-react';
import AudiogramPreview from '../SideBar/AudiogramPreview';
import ClipInterimThumbnailCanvasPreview from './ClipInterimThumbnailCanvasPreview';
import { Clip } from '@/domains/asset';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { getClipThumbnailUrl } from '@/Pages/Sessions/AssetsTable/ClipPreview/ClipPreviewUtils';
import { ClipsContext } from '@/context/ClipsContext/ClipsContext';
import { SpeakerSegmentContext } from '@/context/SpeakerSegmentContext/SpeakerSegmentContext';
import { WordType } from '@/domains/transcript';
import { core } from '@/stores/core';
import { SpeakersAnalysis } from '@/stores/speakersAnalysis/speakersAnalysisTypes';
import { roundToNDecimalPlaces, timeStringToSeconds } from '@/libs/utils';
import { useAppContext } from '@/context/AppContext/AppContext';

/**
 * Interim thumbnail for clips without a download config. Using the clip's asset metadata, this component
 * will render a preview of the clip. Once the clip's download config is available, this will be replaced
 * with the actual thumbnail.
 */
export default function ClipInterimThumbnail({ clip }: { clip: Clip }) {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  const isUpload = useMemo(() => {
    return coreStore.content?.media_source_type === 'UPLOAD';
  }, [coreStore.content?.media_source_type]);

  const firstFrameSpeakerAnalysis: SpeakersAnalysis = useMemo(() => {
    if (!isUpload || !clip.first_frame_analysis) {
      return {
        face_ids: [],
        speaker_mapping: {},
        time_analysis: [],
        loading: false,
        times: [],
        visible_face_ids: [],
        accurate: false
      };
    }

    const timeAnalysis = clip.first_frame_analysis.time_analysis || [];

    return {
      face_ids: clip.first_frame_analysis.face_ids || [],
      speaker_mapping: clip.first_frame_analysis.speaker_mapping || {},
      time_analysis: timeAnalysis,
      loading: false,
      times: timeAnalysis?.map(t => roundToNDecimalPlaces(timeStringToSeconds(t.time) + clip.asset_metadata.start, 4)),
      visible_face_ids: clip.first_frame_analysis.face_ids?.slice(0, 4) || [],
      accurate: clip.first_frame_analysis.accurate || false
    };
  }, [clip.first_frame_analysis, clip.asset_metadata.start, isUpload]);

  const transcriptStore = useTranscriptContext();
  const { logger } = useAppContext();
  const clipThumbnailUrl = getClipThumbnailUrl(clip);

  const clipThumbnailRef = useRef<HTMLImageElement>(null);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const firstSpeaker = useMemo(() => {
    const firstSpeakerWord = transcriptStore.transcript.find(word => {
      return !word.hidden && clip.asset_metadata.start <= word.end_time;
    });
    return transcriptStore.usedSpeakers.find(speaker => speaker.key === firstSpeakerWord?.speaker_label);
  }, [clip.asset_metadata.start, transcriptStore.usedSpeakers, transcriptStore.transcript]);

  const onImageLoaded = useCallback(() => {
    setImageLoaded(true);
    clipThumbnailRef.current?.setAttribute('currentTime', clip.asset_metadata.start.toString());
  }, [clip.asset_metadata.start]);

  const onImageLoadError = useCallback(() => {
    logger.error('Clip does not have a thumbnail');
    setImageError(true);
  }, []);

  return (
    <div className="absolute top-0 h-full w-full overflow-hidden rounded-md bg-cover bg-center">
      <div className="relative grid h-full items-start" data-testid="composite-player">
        {!imageError ? (
          <img
            alt="Preview"
            className="h-full w-full object-cover"
            src={clipThumbnailUrl}
            ref={clipThumbnailRef}
            onLoad={onImageLoaded}
            onError={onImageLoadError}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-slate-200/60">
            <IconVideo className="h-5 w-5 text-slate-400" />
          </div>
        )}
        {imageLoaded && !!clipThumbnailRef.current && firstSpeaker && clip.asset_metadata.layout === 'DEFAULT' && (
          <ClipInterimThumbnailCanvasPreview
            clip={clip}
            clipThumbnailRef={clipThumbnailRef}
            firstSpeaker={firstSpeaker}
          />
        )}
        {firstSpeaker && clip.asset_metadata.layout === 'AUDIOGRAM' && (
          <ClipsContext.Provider
            value={
              {
                clipId: clip.id,
                clipData: clip,
                playerStore: {}
              } as any
            }
          >
            <SpeakerSegmentContext.Provider
              value={{
                captions: MOCK_WORDS,
                segmentSpeaker: { [firstSpeaker.key]: firstSpeaker },
                segmentWords: {},
                srtCaptions: { 0: MOCK_WORDS },
                startSegment: 0
              }}
            >
              <AudiogramPreview clipTemplate={clip.asset_metadata} currentSpeaker={firstSpeaker} />
            </SpeakerSegmentContext.Provider>
          </ClipsContext.Provider>
        )}
        {imageLoaded &&
          !!clipThumbnailRef.current &&
          firstSpeaker &&
          ['SPEAKER', 'GRID'].includes(clip.asset_metadata.layout) &&
          isUpload &&
          firstFrameSpeakerAnalysis && (
            <ClipsContext.Provider
              value={
                {
                  clipId: clip.id,
                  clipData: clip,
                  speakersAnalysis: firstFrameSpeakerAnalysis,
                  playerStore: {}
                } as any
              }
            >
              <SpeakerSegmentContext.Provider
                value={{
                  captions: MOCK_WORDS,
                  segmentSpeaker: { [firstSpeaker.key]: firstSpeaker },
                  segmentWords: {},
                  srtCaptions: { 0: MOCK_WORDS },
                  startSegment: 0
                }}
              >
                <ClipInterimThumbnailCanvasPreview
                  clip={clip}
                  clipThumbnailRef={clipThumbnailRef}
                  firstSpeaker={firstSpeaker}
                />
              </SpeakerSegmentContext.Provider>
            </ClipsContext.Provider>
          )}
      </div>
    </div>
  );
}

const MOCK_WORDS = [
  {
    start_time: 16,
    end_time: 18,
    content: '.........',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 0,
    hidden: false
  },
  {
    start_time: 20,
    end_time: 25,
    content: '.......',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 1,
    hidden: false
  },
  {
    start_time: 25,
    end_time: 30,
    content: '.......',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 2,
    hidden: false
  },
  {
    start_time: 30,
    end_time: 35,
    content: '....',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 3,
    hidden: false
  }
];
