export const convertToMarkdown = blocks => {
  const markdown = [] as string[];
  let title: string = '';

  blocks.forEach(block => {
    const blockType = block.type;
    const content = block.content || [];

    switch (blockType) {
      case 'paragraph':
        markdown.push(processParagraph(content));
        break;
      case 'heading':
        markdown.push(processHeading(block));
        break;
      case 'clipPlayer':
        markdown.push(content.map(item => item.text).join(' '));
        break;
      case 'table':
        markdown.push(processTable(block));
        break;
      case 'titleBlock':
        title = content.map(item => item.text).join(' ');
        break;
      default:
        markdown.push(content.map(item => item.text).join(' '));
    }

    markdown.push('\n');
  });
  return {
    title,
    markdownBody: markdown.join('\n')
  };
};

function processParagraph(content) {
  return content
    .map(item => {
      if (item.type === 'link') {
        return item.content.map(linkItem => linkItem.text).join(' ');
      }

      return applyTextStyles(item.text, item.styles);
    })
    .join(' ');
}

function processHeading(block) {
  const level = block.props.level || 1;
  const content = block.content.map(item => item.text).join(' ');
  return '#'.repeat(level) + ' ' + content;
}

function processTable(block) {
  const rows = block.content.rows || [];
  const markdownTable = [] as string[];

  rows.forEach((row, rowIndex) => {
    const cells = row.cells.map(cellGroup =>
      cellGroup.map(cell => applyTextStyles(cell.text.replace(/\n/g, '  \n'), cell.styles)).join(' ')
    );
    markdownTable.push(`| ${cells.join(' | ')} |`);

    if (rowIndex === 0) {
      const headerSeparator = cells.map(() => '---').join(' | ');
      markdownTable.push(`| ${headerSeparator} |`);
    }
  });

  return markdownTable.join('\n');
}

function applyTextStyles(text: string, styles) {
  let styledText = text;

  if (styles.bold) {
    styledText = `**${styledText}**`;
  }
  if (styles.italic) {
    styledText = `*${styledText}*`;
  }
  if (styles.underline) {
    styledText = `<u>${styledText}</u>`;
  }
  if (styles.strikethrough) {
    styledText = `~~${styledText}~~`;
  }
  return styledText;
}
