import { memo, useMemo } from 'react';
import { DRAG_HANDLE_WIDTH, getDragHandleClassName } from './DragHandleUtils';
import { DragHandleProps } from './DragHandleTypes';

const DragHandle: React.FC<DragHandleProps> = ({ type, positionX, onPointerDown }) => {
  const classNames = useMemo(() => {
    const commonClassNames = 'absolute top-0 z-50 flex h-full cursor-ew-resize items-center justify-center bg-black/80';

    return commonClassNames + ' ' + getDragHandleClassName(type);
  }, [type]);

  const style = useMemo(
    () => ({ width: DRAG_HANDLE_WIDTH + 'px', [type === 'end' ? 'right' : 'left']: positionX }),
    [type, positionX]
  );

  return (
    <div className={classNames} onPointerDown={onPointerDown} style={style}>
      <div className="h-[70%] w-[3px] rounded-full bg-white" />
    </div>
  );
};

export default memo(DragHandle);
