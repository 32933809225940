import { TextGenerate, ClipToTextGeneration } from '@goldcast/api/content';
import { getEnvConfig } from '../constants';
import { promptOverrides } from '@/stores/debugToolsStores';

export function generateBlogPost(
  customBody: TextGenerate,
  signal: AbortSignal,
  callback: (_res?: BufferSource) => void
) {
  return fetch(`${getEnvConfig('API_URL')}/text/generate/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.accessToken}`
    },
    body: JSON.stringify({
      ...customBody,
      streaming: true
    }),
    signal
  })
    .then(async response => {
      if (response.status >= 400) throw new Error();
      if (!response.body) return;

      const reader = response.body.getReader();
      let readResult = await reader.read();

      while (!readResult.done) {
        callback(readResult.value);
        readResult = await reader.read();
      }

      callback();
    })
    .catch(err => {
      // Ignore DOMException thrown by AbortSignal
      if (!(err instanceof DOMException)) {
        throw err;
      }
    });
}

export function generateMultiModalPost(customBody, signal: AbortSignal, callback: (_res?: BufferSource) => void) {
  const payload: ClipToTextGeneration = {
    clip_id: customBody.clip_assets[0],
    project_id: customBody.project_id,
    content_id: customBody.content_id,
    content_type: customBody.type,
    organization_id: customBody?.organization as string,
    content_metadata: {
      length: customBody.length
    },
    constant_prompt_overrides: promptOverrides.getSnapshot()
  };
  return fetch(`${getEnvConfig('API_URL')}/multi-modal/clip_to_text/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.accessToken}`
    },
    body: JSON.stringify({
      ...payload
    }),
    signal
  })
    .then(async response => {
      if (response.status >= 400) throw new Error();
      if (!response.body) return;

      const reader = response.body.getReader();
      let readResult = await reader.read();

      while (!readResult.done) {
        callback(readResult.value);
        readResult = await reader.read();
      }

      callback();
    })
    .catch(err => {
      // Ignore DOMException thrown by AbortSignal
      if (!(err instanceof DOMException)) {
        throw err;
      }
    });
}
