import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { RecordingDetails, SetupContentStep } from '../types';
import { INFO_STEP, RECORDING_DETAILS_STEP } from '../constants';
import IdentifySpeakerStep from './steps/IdentifySpeakerStep';
import RecordingDetailsStep from './steps/RecordingDetailsStep';
import { areRecordingDetailsInvalid, isActiveStep, isStepInvalid } from './utils';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import Button from '@/components/atoms/Button/Button';
import Loader from '@/components/atoms/Loader';

export default function SetupContentModal({
  recordingDetails,
  isVideoInitialization,
  steps,
  speakerValues,
  isInlineIdentification = false,
  setSpeakerValues,
  onSave,
  onSkip,
  setDialogTitle,
  setRecordingDetails,
  isSaving
}: {
  recordingDetails: RecordingDetails;
  isVideoInitialization: boolean;
  isInlineIdentification?: boolean;
  steps: SetupContentStep[];
  speakerValues: Record<string, SpeakerWithDetails>;
  setSpeakerValues: Dispatch<SetStateAction<Record<string, SpeakerWithDetails>>>;
  onSave: () => void;
  onSkip: () => void;
  setDialogTitle: (string) => void;
  setRecordingDetails: Dispatch<SetStateAction<RecordingDetails>>;
  isSaving: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<SetupContentStep>(steps[0]);
  const [isInvalidStep, setIsInvalidStep] = useState(false);

  const confirmButtonLabel = useMemo(() => {
    return currentStep.showSaveButton ? 'Save' : 'Next';
  }, [currentStep]);

  function goToStep(index: number) {
    const newStep = steps[index];
    setDialogTitle(newStep.title);
    setCurrentStep(newStep);
  }

  function goBack() {
    goToStep(currentStep.key - 1);
  }

  function goToNextStep() {
    setIsInvalidStep(false);
    goToStep(currentStep.key + 1);
  }

  function onRecordingUpdate(field: string, value: string) {
    setRecordingDetails({
      ...recordingDetails,
      [field]: value
    });
  }

  function onSpeakerUpdate(value: SpeakerWithDetails) {
    setSpeakerValues(values => {
      values[currentStep.key] = value;
      return values;
    });
  }

  function confirm() {
    if (currentStep.title === RECORDING_DETAILS_STEP.title && areRecordingDetailsInvalid(recordingDetails)) {
      setIsInvalidStep(true);
      return;
    }
    if (currentStep.speakerData && isStepInvalid(speakerValues[currentStep.key] || {})) {
      setIsInvalidStep(true);
      return;
    }
    currentStep.showSaveButton ? onSave() : goToNextStep();
  }

  return (
    <div>
      <div className="w-full border-b border-b-slate-200 px-3 py-3.5">
        {currentStep &&
          steps.map(step => {
            if (isActiveStep(currentStep, step, RECORDING_DETAILS_STEP.title))
              return (
                <div className="p-2" key="recording-details">
                  <RecordingDetailsStep
                    recordingDetails={recordingDetails}
                    onRecordingUpdate={onRecordingUpdate}
                    isInvalid={isInvalidStep}
                  />
                </div>
              );
            else if (isActiveStep(currentStep, step, INFO_STEP.title))
              return (
                <div className="w-full whitespace-pre-line text-left text-sm" key={step.key}>
                  We detected {Object.keys(speakerValues).length > 1 ? 'multiple speakers' : 'one speaker'} in this
                  recording. <br />
                  Watch a short clip of each speaker and identify them.
                </div>
              );
            return (
              step.speakerData && (
                <IdentifySpeakerStep
                  key={step.key}
                  isActive={step.key === currentStep.key}
                  speaker={step.speakerData}
                  isInvalid={isInvalidStep}
                  isInlineIdentification={isInlineIdentification}
                  onUpdate={onSpeakerUpdate}
                />
              )
            );
          })}
      </div>
      <div className="flex w-full items-center justify-start space-x-2 p-2">
        {currentStep.key !== 0 && (
          <Button onClick={goBack} variation="outline" trackingId="back-button" buttonSize="large">
            Back
          </Button>
        )}
        {isVideoInitialization && (
          <Button onClick={onSkip} variation="text" trackingId="skip-button" buttonSize="large">
            Skip
          </Button>
        )}
        <Button
          onClick={confirm}
          variation="filled"
          className="!ml-auto"
          trackingId="confirm-button"
          disabled={isSaving}
          buttonSize="large"
        >
          {isSaving && <Loader size="xs" />}
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
}
