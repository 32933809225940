import { useSyncExternalStore, useMemo } from 'react';
import { VoiceProfile } from '@goldcast/api/content';
import { voiceProfileStore } from '@/stores/voiceProfile';

export default function useVoiceProfile(): {
  voiceProfiles: VoiceProfile[];
  activeStep: number;
  selectedVoiceProfile: VoiceProfile | null;
} {
  const voiceProfileState = useSyncExternalStore(voiceProfileStore.subscribe, voiceProfileStore.getSnapshot);
  const voiceProfiles = useMemo(() => voiceProfileState.voiceProfiles, [voiceProfileState.voiceProfiles]);

  return {
    voiceProfiles,
    activeStep: voiceProfileState.activeStep,
    selectedVoiceProfile: voiceProfileState.selectedVoiceProfile
  };
}
