import { getCommonBits, getIntroVideo, getOutroVideo } from './common';
import { roundToNDecimalPlaces } from '../utils';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { getVideoManifestUrl } from '@/stores/core';
import { SharedAPIStore } from '@/stores/sharedAPI/sharedAPI';
import { Clip } from '@/domains/asset';

export function getDefaultLayoutCanvasData({
  clip,
  sharedAPIStoreForClip
}: {
  clip: Clip;
  sharedAPIStoreForClip: SharedAPIStore;
}) {
  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);

  const {
    introVideoSource,
    outroVideoSource,
    clipDuration,
    introVideoDuration,
    outroVideoDuration,
    modifiedExcludes,
    showCaption,
    captions,
    canvas_height,
    canvas_width
  } = getCommonBits(sizeConfig, clip, sharedAPIStoreForClip);

  const {
    source_crop_x,
    source_crop_y,
    source_crop_width,
    source_crop_height,
    target_crop_x,
    target_crop_y,
    target_crop_width,
    target_crop_height
  } = sizeConfig.getCropPositions({
    videoWidth: sharedAPIStoreForClip.mainPlayerRef?.videoWidth || canvas_width,
    videoHeight: sharedAPIStoreForClip.mainPlayerRef?.videoHeight || canvas_height,
    showCaption,
    shouldUseDefaultLayoutValues: true
  });

  const background_image = clip.asset_metadata.magicLayout?.backgroundImage;

  const { end: introEnd, introVideo } = getIntroVideo(
    introVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    introVideoSource
  );

  const mainVideo = {
    start: introEnd,
    end: roundToNDecimalPlaces(introEnd + clipDuration, 3),
    x: target_crop_x,
    y: target_crop_y,
    z: 0,
    bounding_width: target_crop_width,
    bounding_height: target_crop_height,
    radius: sizeConfig.getBorderVideoRadius(),
    meta: {
      excludes: modifiedExcludes,
      crop_x: source_crop_x,
      crop_y: source_crop_y,
      crop_width: source_crop_width,
      crop_height: source_crop_height,
      source: getVideoManifestUrl(),
      in_time: clip.asset_metadata.start
    }
  };

  const { end: outroEnd, outroVideo } = getOutroVideo(
    mainVideo.end,
    outroVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    outroVideoSource
  );

  return {
    canvas_width,
    canvas_height,
    duration: outroEnd,
    background_color: clip.asset_metadata.magicLayout?.backgroundColor || '#000000',
    ...(background_image && { background_image }),
    filename: clip.title,
    videos: [...introVideo, mainVideo, ...outroVideo],
    captions
  };
}
