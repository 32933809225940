import {
  ContentUpload,
  MediaContent,
  assetsContentUploadRetrieve,
  assetsMediaContentRetrieve
} from '@goldcast/api/content';
import createStore from './store';
import { currentUser } from './user';
import { getEnvConfig } from '@/constants';

export interface Branding {
  titleFontFamily: number;
  subtitleFontFamily: number;
  titleColor: string;
  subtitleColor: string;
  titleBgColor: string;
  subtitleBgColor: string;
  titleFontSize: number;
  subtitleFontSize: number;
  titleY: number;
  subtitleY: number;
  titleVisible: boolean;
  subtitleVisible: boolean;
}

interface UIFont {
  name?: string;
  label?: string;
  path?: string;
}

export interface CoreStore {
  content?: MediaContent;
  uploadData?: ContentUpload;
  fontFamilies: UIFont[];
  branding: Branding;
}

export const core = createStore<CoreStore>({
  branding: {
    titleFontFamily: 0,
    subtitleFontFamily: 0,
    titleColor: '#ffffff',
    subtitleColor: '#000000',
    titleBgColor: '#000000',
    subtitleBgColor: '#ffffff',
    titleFontSize: 25,
    subtitleFontSize: 15,
    titleY: 300,
    subtitleY: 10,
    titleVisible: true,
    subtitleVisible: true
  },
  content: undefined,
  uploadData: undefined,
  fontFamilies: []
});

function makeColors(theme_obj: { [key: string]: any } | undefined) {
  const { branding } = core.getSnapshot();
  return {
    ...branding,
    title: theme_obj?.primary_text_color,
    subTitle: theme_obj?.secondary_text_color,
    titleBg: theme_obj?.theme_primary_color,
    subTitleBg: theme_obj?.theme_secondary_color
  };
}

export function initContent(contentId: string): Promise<{ mediaContent: MediaContent; uploadData: ContentUpload }> {
  return assetsMediaContentRetrieve({ id: contentId }).then((content: MediaContent) => {
    const user = currentUser.getSnapshot();
    if (!user?.is_admin && user?.organization !== content.organization && process.env.NODE_ENV !== 'development') {
      console.error('User is not admin or does not belong to this organization');
      return Promise.reject({
        message: 'User is not admin or does not belong to this organization',
        statusCode: 404
      });
    }
    core.update(data => ({ ...data, content, uploadData: null, branding: makeColors(content.project?.theme_obj) }));
    if (content.media_source_type === 'UPLOAD') {
      return initUploadData(content.id).then(res => {
        return { mediaContent: content, uploadData: res };
      });
    }
    return { mediaContent: content, uploadData: null };
  });
}

export function initSharedContent(contentId: string): Promise<MediaContent> {
  return assetsMediaContentRetrieve({ id: contentId }).then((content: MediaContent) => {
    core.update(data => ({ ...data, content, uploadData: null, branding: makeColors(content.project?.theme_obj) }));
    if (content.media_source_type === 'UPLOAD') {
      return initUploadData(content.id).then(() => {
        return content;
      });
    }
    return content;
  });
}

function initUploadData(contentId: string): Promise<any> {
  return assetsContentUploadRetrieve({ id: contentId }).then(res => {
    core.update(data => ({ ...data, uploadData: res }));
    return res;
  });
}

export function isAudioContent(): boolean {
  const { content } = core.getSnapshot();
  return content?.media_type === 'AUDIO';
}

export function getVideoManifestUrl(clipContentId?: string, clipProjectId?: string): string {
  const { content } = core.getSnapshot();
  const [contentId, projectId] =
    !!clipContentId && !!clipProjectId ? [clipContentId, clipProjectId] : [content!.id, content!.project_id];
  if (content!.media_source_type === 'UPLOAD') {
    return `${getEnvConfig('CUSTOM_UPLOAD_URL')}${projectId}/${contentId}/local.m3u8`;
  }
  return `${getEnvConfig('VOD_URL')}${projectId}/${contentId}/hls/6000.m3u8`;
}
