import React, { useCallback, useEffect } from 'react';
import { AssetRating, TypeEnum } from '@goldcast/api/content';
import Icon from '@/components/atoms/Icon';
import { classnames } from '@/libs/utils';
import useAssetRating from '@/hooks/useAssetRating';
import TooltipV2 from '@/components/TooltipV2';

interface AssetRatingButtonsProps {
  assetId: string;
  rating: AssetRating | null;
  assetType: TypeEnum;
}

interface ThumbConfig {
  isUp: boolean;
  filledIcon: any;
  outlineIcon: string;
  tooltipMessage: string;
}

const thumbConfigs: ThumbConfig[] = [
  {
    isUp: true,
    filledIcon: 'IconThumbUpFilled',
    outlineIcon: 'IconThumbUp',
    tooltipMessage: 'I like this autogenerated'
  },
  {
    isUp: false,
    filledIcon: 'IconThumbDownFilled',
    outlineIcon: 'IconThumbDown',
    tooltipMessage: 'I do not like this autogenerated'
  }
];

const AssetRatingButtons: React.FC<AssetRatingButtonsProps> = ({ assetId, rating, assetType }) => {
  const { getAssetRating, setAssetRating, handleThumbClick } = useAssetRating();
  const { thumbsUp } = getAssetRating(assetId);

  useEffect(() => {
    setAssetRating(assetId, rating);
  }, []);

  const getIconName = useCallback(
    (config: ThumbConfig) => {
      if (config.isUp) {
        return thumbsUp ? config.filledIcon : config.outlineIcon;
      } else {
        return thumbsUp === false ? config.filledIcon : config.outlineIcon;
      }
    },
    [thumbsUp]
  );

  return (
    <>
      {thumbConfigs.map(config => {
        const iconName = getIconName(config);

        return (
          <button
            key={config.isUp ? 'up' : 'down'}
            data-testid={`asset-rating-${config.isUp ? 'up' : 'down'}`}
            className="p-1 hover:text-black"
            onClick={() => handleThumbClick(assetId, config.isUp)}
          >
            <TooltipV2
              tooltipId={`asset-rating-${config.isUp ? 'up' : 'down'}`}
              tooltipContent={`${config.tooltipMessage} ${assetType.toLowerCase()}`}
              tooltipElement={
                <Icon
                  name={iconName}
                  className={classnames('h-4 w-4', {
                    'text-deep-orange-600': (config.isUp && thumbsUp === true) || (!config.isUp && thumbsUp === false)
                  })}
                />
              }
            />
          </button>
        );
      })}
    </>
  );
};

export default AssetRatingButtons;
