import { Combobox, Transition } from '@headlessui/react';
import { IconCheck, IconPlus, IconTrash } from '@tabler/icons-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { classnames } from '@/libs/utils';

export default function TagsInput({
  existingTags,
  preSelectedTags = [],
  onChange
}: {
  existingTags: string[];
  preSelectedTags?: string[];
  onChange: (tags: string[]) => void;
}) {
  const [tagsList, setTagsList] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState(preSelectedTags);
  const [query, setQuery] = useState('');
  const tagsInput = useRef<HTMLInputElement>(null);

  const filteredTags =
    query === ''
      ? tagsList
      : tagsList.filter(tag => tag.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));

  function onSelect(values: string[]) {
    setSelectedTags(values);
    onChange(values);
  }

  function addTag() {
    onSelect([...selectedTags, query]);
    setTagsList(tags => [...tags, query]);
    tagsInput.current!.value = '';
    setQuery('');
  }

  useEffect(() => {
    setTagsList(tags => [...tags, ...existingTags]);
  }, [existingTags]);

  return (
    <Combobox value={selectedTags} onChange={onSelect} multiple={true as any}>
      <div className="relative mb-4 mt-1 rounded-md border border-solid p-px">
        <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-deep-orange-600 sm:text-sm">
          <Combobox.Button className="w-full" data-testid="template-tags-trigger-button">
            <div className="no-scrollbar flex w-full space-x-1 overflow-scroll rounded-md px-2 py-2 text-sm focus-within:border-deep-orange">
              {selectedTags.length > 0 &&
                selectedTags.map(tag => (
                  <div key={tag} className="shrink-0 rounded bg-slate-100 px-2 text-sm">
                    {tag}
                  </div>
                ))}
              <Combobox.Input
                data-testid="template-tags-input"
                className="outline-none ring-0"
                onChange={event => setQuery(event.target.value)}
                ref={tagsInput}
              />
            </div>
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute bottom-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {filteredTags.length === 0 && query !== '' ? (
              <div
                className="relative flex cursor-pointer select-none flex-row items-center px-4 py-2 text-slate-600 hover:bg-slate-100"
                onClick={addTag}
              >
                <IconPlus size={16} />
                <span className="pl-3">{query}</span>
              </div>
            ) : (
              filteredTags.map(tag => (
                <Combobox.Option
                  key={tag}
                  className={({ active }) =>
                    `group relative cursor-pointer select-none py-2 pl-10 pr-4 text-slate-600 ${
                      active ? 'bg-slate-100' : ''
                    }`
                  }
                  value={tag}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate`}>{tag}</span>
                      {selected ? (
                        <>
                          <span
                            className={classnames(
                              'absolute inset-y-0 left-0 flex items-center pl-3',
                              active ? 'text-white' : 'text-deep-orange-600'
                            )}
                          >
                            <IconCheck className="h-5 w-5 text-deep-orange-600" size={16} />
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                            <IconTrash
                              className="hidden text-slate-600 hover:text-red-500 group-hover:block"
                              size={16}
                            />
                          </span>
                        </>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
