import { BackgroundTransitionTemplateMock } from './types';
import { getEnvConfig } from '@/constants';

export const transitionTemplateMocks: BackgroundTransitionTemplateMock[] = [
  {
    id: 'classic-widescreen-wonder',
    name: 'Classic Widescreen Wonder',
    type: 'DEFAULT',
    timeline: {
      width: 1920,
      height: 1080,
      aspectRatioClass: 'aspect-[16/9]',
      background: {
        type: 'color',
        value: '#000000'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 1920,
              height: 1080,
              x: 0,
              y: 0,
              cropWidth: 1920,
              cropHeight: 1080,
              cropX: 0,
              cropY: 0,
              rotation: 0
            }
          ]
        }
      ]
    }
  },
  {
    id: 'vertical-duo-delight',
    name: 'Vertical Duo Delight',
    type: 'GRID',
    timeline: {
      width: 1080,
      height: 1920,
      aspectRatioClass: 'aspect-[9/16]',
      background: {
        type: 'color',
        value: '#000000'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 3668,
              height: 2067,
              x: -436,
              y: -554,
              cropWidth: 1080,
              cropHeight: 960,
              cropX: 436,
              cropY: 554,
              rotation: 0,
              speakerBoundingBox: {
                x: 661,
                y: 592,
                width: 550,
                height: 690
              }
            },
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 3668,
              height: 2067,
              x: -2300,
              y: 406,
              cropWidth: 1080,
              cropHeight: 960,
              cropX: 2300,
              cropY: 554,
              rotation: 0,
              speakerBoundingBox: {
                x: 2550,
                y: 548,
                width: 550,
                height: 690
              }
            },
            {
              type: 'CAPTIONS',
              x: 0,
              y: 860,
              rotation: 0,
              width: 1080,
              height: 200,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 56,
              alignment: 'justify-center',
              textColor: '#ffffff',
              highlightColor: '#5449D9',
              style: 'Outline',
              animation: 'highlight'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'square-speaker-spotlight',
    name: 'Square Speaker Spotlight',
    type: 'AUDIOGRAM',
    timeline: {
      width: 1080,
      height: 1080,
      aspectRatioClass: 'aspect-[1/1]',
      background: {
        type: 'color',
        value: '#5449D9'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'AUDIO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp3',
              start: 0,
              end: 10
            },
            {
              type: 'IMAGE',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/devin-reed.png',
              width: 1080 - 180,
              height: 1080 - 150,
              rotation: 0,
              x: -0,
              y: 0,
              cropHeight: 1080 - 180,
              cropWidth: 1080 - 150,
              cropX: 0,
              cropY: 0
            },
            {
              type: 'CAPTIONS',
              x: 40,
              y: 900,
              rotation: 0,
              width: 880,
              height: 180,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 32,
              alignment: 'justify-start',
              textColor: '#fff',
              highlightColor: '#000000',
              style: 'Base',
              animation: 'box'
            },
            {
              type: 'IMAGE',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/waveform.svg',
              width: 100,
              height: 100,
              x: 930,
              y: 930,
              cropWidth: 100,
              cropHeight: 100,
              cropX: 0,
              cropY: 0,
              rotation: 0
            },
            {
              type: 'TEXT',
              x: 1040,
              y: 80,
              rotation: 90,
              width: 1080,
              height: 1080,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 38,
              alignment: 'justify-start',
              textColor: '#fff',
              content: 'Devin Reed'
            },
            {
              type: 'TEXT',
              x: 990,
              y: 80,
              rotation: 90,
              width: 1080,
              height: 1080,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 32,
              alignment: 'justify-start',
              textColor: '#fff',
              content: 'Founder of The Reeder'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'vibrant-square-showcase',
    name: 'Vibrant Square Showcase',
    type: 'ACTIVE',
    timeline: {
      width: 1080,
      height: 1080,
      aspectRatioClass: 'aspect-[1/1]',
      background: {
        type: 'image',
        value: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/bg_leo.jpg'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 1080 - 100,
              height: 607 - 100,
              borderRadius: 40,
              x: 50,
              y: 236 + 50,
              cropWidth: 1080,
              cropHeight: 607,
              cropX: 0,
              cropY: 0,
              rotation: 0
            }
          ]
        }
      ]
    }
  },
  {
    id: 'dynamic-vertical-duo',
    name: 'Dynamic Vertical Duo',
    type: 'ACTIVE',
    timeline: {
      width: 1080,
      height: 1920,
      aspectRatioClass: 'aspect-[9/16]',
      background: {
        type: 'color',
        value: '#5449D9'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 3668,
              height: 2067,
              x: -436 + 40,
              y: -554 + 40,
              cropWidth: 1080 - 80,
              cropHeight: 960 - 40,
              borderRadius: 40,
              cropX: 436,
              cropY: 554,
              rotation: 0,
              speakerBoundingBox: {
                x: 661,
                y: 592,
                width: 550,
                height: 690
              }
            },
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 3668,
              height: 2067,
              x: -2300 + 40,
              y: 406 + 40,
              cropWidth: 1080 - 80,
              cropHeight: 960 - 80,
              borderRadius: 40,
              cropX: 2300,
              cropY: 554,
              rotation: 0,
              speakerBoundingBox: {
                x: 2550,
                y: 548,
                width: 550,
                height: 690
              }
            },
            {
              type: 'CAPTIONS',
              x: 0,
              y: 900,
              rotation: 0,
              width: 1080,
              height: 180,
              fontFamily: 'Inter',
              letterCase: 'uppercase',
              fontWeight: 800,
              fontSize: 48,
              alignment: 'justify-center',
              textColor: '#ffffff',
              highlightColor: '#5449D9',
              style: 'Background',
              animation: 'highlight'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'widescreen-wisdom-sharer',
    name: 'Widescreen Wisdom Sharer',
    type: 'AUDIOGRAM',
    timeline: {
      width: 1920,
      height: 1080,
      aspectRatioClass: 'aspect-[16/9]',
      background: {
        type: 'color',
        value: '#5449D9'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'AUDIO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp3',
              start: 0,
              end: 10
            },
            {
              type: 'IMAGE',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/devin-reed.png',
              width: 1920 / 2,
              height: 1080,
              rotation: 0,
              x: 0,
              y: 0,
              cropHeight: 1080,
              cropWidth: 1920 / 2,
              cropX: 0,
              cropY: 0
            },
            {
              type: 'CAPTIONS',
              x: 1920 / 2 + 40,
              y: 80,
              rotation: 0,
              width: 1920 / 2 - 80,
              height: 325,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 48,
              alignment: 'justify-start',
              verticalAlignment: 'items-start',
              textColor: '#fff',
              highlightColor: '#000000',
              style: 'Base',
              animation: 'box'
            },
            {
              type: 'IMAGE',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/waveform.svg',
              width: 100,
              height: 100,
              x: 1920 - 180,
              y: 900,
              cropWidth: 100,
              cropHeight: 100,
              cropX: 0,
              cropY: 0,
              rotation: 0
            },
            {
              type: 'TEXT',
              x: 1920 / 2 + 50,
              y: 890,
              rotation: 0,
              width: 500,
              height: 100,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 48,
              alignment: 'justify-start',
              textColor: '#fff',
              content: 'Devin Reed'
            },
            {
              type: 'TEXT',
              x: 1920 / 2 + 50,
              y: 960,
              rotation: 0,
              width: 800,
              height: 100,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 38,
              alignment: 'justify-start',
              textColor: '#fff',
              content: 'Founder of The Reeder'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'playful-vertical-fun',
    name: 'Playful Vertical Fun',
    type: 'ACTIVE',
    timeline: {
      width: 1080,
      height: 1080,
      aspectRatioClass: 'aspect-[1/1]',
      background: {
        type: 'image',
        value: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/bg_content-lab.jpg'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 4375 - 300 - 250,
              height: 2460 - 300 + 100,
              x: -610 + 180 - 50 - 200,
              y: -700 + 180 - 50,
              cropWidth: 720 - 250,
              cropHeight: 720 + 100,
              cropX: 610 - 50 + 170,
              cropY: 700 - 50,
              borderRadius: 40,
              rotation: 0
            },
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 4375 - 300 - 250,
              height: 2460 - 300 + 100,
              x: -610 + 180 - 50 - 170 - 1970 + 230 + 300 - 25,
              y: -700 + 180 - 50,
              cropWidth: 720 - 250,
              cropHeight: 720 + 100,
              cropX: 610 - 50 + 170 + 1970 + 280 - 300,
              cropY: 700 - 50,
              borderRadius: 40,
              rotation: 0
            },
            {
              type: 'CAPTIONS',
              x: 0,
              y: 900,
              rotation: 0,
              width: 1080,
              height: 180,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 34,
              alignment: 'justify-center',
              textColor: '#ffffff',
              highlightColor: '#5449D9',
              style: 'Base',
              animation: 'highlight'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'artsy-square-storyteller',
    name: 'Artsy Square Storyteller',
    type: 'GRID',
    timeline: {
      width: 1920,
      height: 1080,
      aspectRatioClass: 'aspect-[16/9]',
      background: {
        type: 'color',
        value: '#5449D9'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 3668,
              height: 2067,
              x: -436 + 50,
              y: -554 + 50,
              cropWidth: 1920 / 2 - 75,
              cropHeight: 1080 - 100,
              cropX: 436,
              cropY: 554,
              rotation: 0,
              borderRadius: 40,
              speakerBoundingBox: {
                x: 661,
                y: 592,
                width: 550,
                height: 690
              }
            },
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 3668,
              height: 2067,
              x: -2300 + 1920 / 2 + 25,
              y: -555 + 50,
              cropWidth: 1920 / 2 - 75,
              cropHeight: 1080 - 100,
              cropX: 2300,
              cropY: 554,
              rotation: 0,
              borderRadius: 40,
              speakerBoundingBox: {
                x: 2550,
                y: 548,
                width: 550,
                height: 690
              }
            },
            {
              type: 'CAPTIONS',
              x: 0,
              y: 860,
              rotation: 0,
              width: 1920,
              height: 200,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 56,
              alignment: 'justify-center',
              textColor: '#ffffff',
              highlightColor: '#5449D9',
              style: 'Outline',
              animation: 'highlight'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'full-screen-cinematic-experience',
    name: 'Full Screen Cinematic Experience',
    type: 'ACTIVE',
    timeline: {
      width: 1080,
      height: 1920,
      aspectRatioClass: 'aspect-[9/16]',
      background: {
        type: 'image',
        value: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/bg_brush.jpg'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 1080 - 100,
              height: 607 - 100,
              borderRadius: 40,
              x: 50,
              y: 400,
              cropWidth: 1080,
              cropHeight: 607,
              cropX: 0,
              cropY: 0,
              rotation: 0
            },
            {
              type: 'CAPTIONS',
              x: 40,
              y: 1250,
              rotation: 0,
              width: 1000,
              height: 180,
              fontFamily: 'Inter',
              verticalAlignment: 'items-center',
              fontWeight: 800,
              fontSize: 56,
              alignment: 'justify-center',
              textColor: '#ffffff',
              highlightColor: '#5449D9',
              style: 'Base',
              animation: 'highlight'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'vertical-double-feature',
    name: 'Vertical Double Feature',
    type: 'DEFAULT',
    timeline: {
      width: 1080,
      height: 1080,
      aspectRatioClass: 'aspect-[1/1]',
      background: {
        type: 'image',
        value: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/bg_sine.png'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 1080 - 100,
              height: 607 - 100,
              borderRadius: 40,
              x: 50,
              y: 236 + 50,
              cropWidth: 1080,
              cropHeight: 607,
              cropX: 0,
              cropY: 0,
              rotation: 0
            },
            {
              type: 'CAPTIONS',
              x: 40,
              y: 830,
              rotation: 0,
              width: 1000,
              height: 180,
              fontFamily: 'Inter',
              verticalAlignment: 'items-center',
              fontWeight: 800,
              fontSize: 42,
              alignment: 'justify-center',
              textColor: '#ffffff',
              highlightColor: '#5449D9',
              style: 'Basic',
              animation: 'highlight'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'square-audio-extravaganza',
    name: 'Square Audio Extravaganza',
    type: 'DEFAULT',
    timeline: {
      width: 1920,
      height: 1080,
      aspectRatioClass: 'aspect-[16/9]',
      background: {
        type: 'color',
        value: '#000000'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'VIDEO',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1.mp4',
              poster: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/clip-1-poster.jpg',
              start: 0,
              loop: false,
              end: 10,
              width: 1920,
              height: 1080,
              x: 0,
              y: 0,
              cropWidth: 1920,
              cropHeight: 1080,
              cropX: 0,
              cropY: 0,
              rotation: 0
            },
            {
              type: 'CAPTIONS',
              x: 40,
              y: 830,
              rotation: 0,
              width: 1920,
              height: 180,
              fontFamily: 'Inter',
              verticalAlignment: 'items-center',
              fontWeight: 800,
              fontSize: 42,
              alignment: 'justify-center',
              textColor: '#ffffff',
              highlightColor: '#5449D9',
              style: 'Outline',
              animation: 'highlight'
            }
          ]
        }
      ]
    }
  },
  {
    id: 'audiogram-portrait',
    name: 'Audiogram Portrait',
    type: 'AUDIOGRAM',
    timeline: {
      width: 1080,
      height: 1920,
      aspectRatioClass: 'aspect-[9/16]',
      background: {
        type: 'color',
        value: '#5449D9'
      },
      segments: [
        {
          id: 'segment-1',
          start: 0,
          end: 10,
          elements: [
            {
              type: 'IMAGE',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/devin-reed.png',
              width: 1080,
              height: 1330,
              rotation: 0,
              x: 0,
              y: 0,
              cropHeight: 1330,
              cropWidth: 1080,
              cropX: 0,
              cropY: 0
            },
            {
              type: 'CAPTIONS',
              x: 40,
              y: 1390,
              rotation: 0,
              width: 1000,
              height: 180,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 48,
              alignment: 'justify-start',
              textColor: '#fff',
              highlightColor: '#000000',
              style: 'Base',
              animation: 'box',
              content: 'Maybe something has caught your'
            },
            {
              type: 'TEXT',
              x: 40,
              y: 1700 + 40,
              rotation: 0,
              width: 1000,
              height: 100,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 48,
              alignment: 'justify-start',
              verticalAlignment: 'items-start',
              textColor: '#fff',
              content: 'Devin Reed'
            },
            {
              type: 'TEXT',
              x: 40,
              y: 1780 + 30,
              rotation: 0,
              width: 1000,
              height: 100,
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: 38,
              alignment: 'justify-start',
              textColor: '#fff',
              content: 'Founder of The Reeder'
            },
            {
              type: 'IMAGE',
              src: getEnvConfig('STATIC_ASSETS_CDN') + '/content-lab/ftux_assets/waveform.svg',
              width: 100,
              height: 100,
              x: 930,
              y: 1750,
              cropWidth: 100,
              cropHeight: 100,
              cropX: 0,
              cropY: 0,
              rotation: 0
            }
          ]
        }
      ]
    }
  }
];

export const CAPTION_LOOP_DURATION = 8;

export const transitionTemplateMockCaptions = [
  {
    start: 0.08,
    end: 1.28,
    text: 'Maybe something has caught',
    words: [
      {
        word: 'maybe',
        start: 0.08,
        end: 0.39999998,
        confidence: 0.93873286,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'Maybe'
      },
      {
        word: 'something',
        start: 0.39999998,
        end: 0.79999995,
        confidence: 0.9972844,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'something'
      },
      {
        word: 'has',
        start: 0.79999995,
        end: 1.04,
        confidence: 0.9996364,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'has'
      },
      {
        word: 'caught',
        start: 1.04,
        end: 1.28,
        confidence: 0.99902034,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'caught'
      }
    ]
  },
  {
    start: 1.28,
    end: 2.48,
    text: "your attention you're curious",
    words: [
      {
        word: 'your',
        start: 1.28,
        end: 1.4399999,
        confidence: 0.9995956,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'your'
      },
      {
        word: 'attention',
        start: 1.4399999,
        end: 1.8399999,
        confidence: 0.7072878,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'attention,'
      },
      {
        word: "you're",
        start: 1.8399999,
        end: 2.08,
        confidence: 0.9017898,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: "you're"
      },
      {
        word: 'curious',
        start: 2.08,
        end: 2.48,
        confidence: 0.99809664,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'curious'
      }
    ]
  },
  {
    start: 2.48,
    end: 3.52,
    text: "about it you're gonna",
    words: [
      {
        word: 'about',
        start: 2.48,
        end: 2.72,
        confidence: 0.9997365,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'about'
      },
      {
        word: 'it',
        start: 2.72,
        end: 3.12,
        confidence: 0.8121054,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'it.'
      },
      {
        word: "you're",
        start: 3.12,
        end: 3.28,
        confidence: 0.998134,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: "You're"
      },
      {
        word: 'gonna',
        start: 3.28,
        end: 3.52,
        confidence: 0.92420155,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'gonna'
      }
    ]
  },
  {
    start: 3.52,
    end: 4.56,
    text: 'have the opportunity to',
    words: [
      {
        word: 'have',
        start: 3.52,
        end: 3.6799998,
        confidence: 0.9973309,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'have'
      },
      {
        word: 'the',
        start: 3.6799998,
        end: 3.84,
        confidence: 0.98504144,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'the'
      },
      {
        word: 'opportunity',
        start: 3.84,
        end: 4.3199997,
        confidence: 0.9997837,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'opportunity'
      },
      {
        word: 'to',
        start: 4.3199997,
        end: 4.56,
        confidence: 0.99930716,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'to'
      }
    ]
  },
  {
    start: 4.56,
    end: 5.68,
    text: 'share those and carly',
    words: [
      {
        word: 'share',
        start: 4.56,
        end: 4.7999997,
        confidence: 0.9324763,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'share'
      },
      {
        word: 'those',
        start: 4.7999997,
        end: 5.04,
        confidence: 0.9099659,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'those,'
      },
      {
        word: 'and',
        start: 5.04,
        end: 5.2799997,
        confidence: 0.99965215,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'and'
      },
      {
        word: 'carly',
        start: 5.2799997,
        end: 5.68,
        confidence: 0.9235404,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'Carly'
      }
    ]
  },
  {
    start: 5.68,
    end: 6.7999997,
    text: 'is gonna walk us through',
    words: [
      {
        word: 'is',
        start: 5.68,
        end: 5.7599998,
        confidence: 0.54096335,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'is'
      },
      {
        word: 'gonna',
        start: 5.7599998,
        end: 6.08,
        confidence: 0.97886115,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'gonna'
      },
      {
        word: 'walk',
        start: 6.08,
        end: 6.24,
        confidence: 0.9979054,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'walk'
      },
      {
        word: 'us',
        start: 6.24,
        end: 6.48,
        confidence: 0.99978346,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'us'
      },
      {
        word: 'through',
        start: 6.48,
        end: 6.7999997,
        confidence: 0.99987316,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'through'
      }
    ]
  },
  {
    start: 6.7999997,
    end: 8.18,
    text: 'some live job posts',
    words: [
      {
        word: 'some',
        start: 6.7999997,
        end: 7.12,
        confidence: 0.9995764,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'some'
      },
      {
        word: 'live',
        start: 7.12,
        end: 7.44,
        confidence: 0.9985833,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'live'
      },
      {
        word: 'job',
        start: 7.44,
        end: 7.68,
        confidence: 0.9971072,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'job'
      },
      {
        word: 'posts',
        start: 7.68,
        end: 8.18,
        confidence: 0.9297867,
        speaker: 0,
        speaker_confidence: 1,
        punctuated_word: 'posts.'
      }
    ]
  }
];
