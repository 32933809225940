import { SizeConfig } from './SizeConfig';
import { CaptionAttributes, PillBoxSizes } from './types';
import { getCanvasTextDimensions } from './util';
import { PORTRAIT_DEFAULT_LAYOUT_VIDEO_BOTTOM_PADDING } from './constants';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { LayoutType, SizeType } from '@/domains/asset';
import { getCurrentClipFontName } from '@/stores/brandKit';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getFontByName } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { loadFont } from '@/libs/fonts';

export class PortraitSizeConfig extends SizeConfig {
  protected type = 'PORTRAIT' as SizeType;

  protected VIDEO_SURROUND_PADDING: number = 20;
  protected VIDEO_BOTTOM_PADDING: number = 380;

  protected CAPTIONS_LEFT_MARGIN = 20;
  protected CAPTIONS_RIGHT_MARGIN = 20;
  protected CAPTIONS_BOTTOM_MARGIN = 102;
  protected CAPTIONS_FONT_SIZE = 44;

  protected AUDIOGRAM_DIMENSIONS = {
    IMG_DIMENSIONS_RATIO: {
      width: 1,
      height: 0.695
    },
    WAVEFORM_HEIGHT_MULTIPLIER: 0.055,
    WAVEFORM_POSITION: {
      x: 0.475,
      y: 0.915
    },
    SPEAKER_LABEL_WIDTH_MULTIPLIER: 0.8
  };

  constructor(clipId: string, clipLayout: LayoutType, devicePixelRatio?: number) {
    super(1920, 1080, clipId, clipLayout, devicePixelRatio);
    if (clipLayout === 'DEFAULT') {
      this.CAPTIONS_BOTTOM_MARGIN = 312;
    }
  }

  getSizeGridPositions(
    totalVideos: number,
    currentVideoNumber: number,
    target_crop_x: number,
    target_crop_y: number,
    target_crop_width: number,
    target_crop_height: number
  ) {
    const videoSurroundPadding = this.getVideoSurroundPadding();

    if (totalVideos === 2) {
      target_crop_height = (target_crop_height - videoSurroundPadding) / 2;

      target_crop_x = videoSurroundPadding;
      target_crop_y = currentVideoNumber * videoSurroundPadding + target_crop_height * (currentVideoNumber - 1);
    } else if (totalVideos === 3) {
      target_crop_height = (target_crop_height - 2 * videoSurroundPadding) / 3;

      target_crop_x = videoSurroundPadding;
      target_crop_y = currentVideoNumber * videoSurroundPadding + target_crop_height * (currentVideoNumber - 1);
    } else if (totalVideos === 4) {
      target_crop_height = (target_crop_height - videoSurroundPadding) / 2;
      target_crop_width = (target_crop_width - videoSurroundPadding) / 2;

      if (currentVideoNumber % 2 === 0) {
        target_crop_x = target_crop_width + videoSurroundPadding * 2;
      }
      if (currentVideoNumber > 2) {
        target_crop_y = target_crop_height + videoSurroundPadding * 2;
      }
    }

    return { target_crop_x, target_crop_y, target_crop_width, target_crop_height };
  }

  getVideoBottomPadding(options?: { shouldUseDefaultLayoutValues: boolean }): number {
    const { shouldUseDefaultLayoutValues } = options || {};

    if (this.clipLayout === 'DEFAULT' || shouldUseDefaultLayoutValues) {
      return PORTRAIT_DEFAULT_LAYOUT_VIDEO_BOTTOM_PADDING * this.devicePixelRatio;
    }

    return this.VIDEO_BOTTOM_PADDING * this.devicePixelRatio;
  }

  getPillMaxWidth(totalNumber: number) {
    return (
      ((this.getWidth() - this.getVideoSurroundPadding() * 2) * this.PILL_MAX_WIDTH_RATIO) / (totalNumber === 4 ? 2 : 1)
    );
  }

  getPillBoxSizes(
    currentVideoNumber: number,
    totalNumber: number,
    canvasPillWidth: number,
    isTruncated: boolean
  ): PillBoxSizes {
    let newPillX = 0;
    let newPillHeight = 0;
    if (totalNumber === 1 && currentVideoNumber === 1) {
      newPillX = 30;
      newPillHeight = 150;
    } else if (totalNumber === 2) {
      newPillX = 20;
      newPillHeight = 120;
    } else if (totalNumber === 3) {
      newPillX = 16;
      newPillHeight = 110;
    } else if (totalNumber === 4) {
      newPillX = 18;
      newPillHeight = 110;
    }

    const pillHeight = newPillHeight * this.devicePixelRatio;

    return {
      pillX: newPillX * this.devicePixelRatio,
      pillWidth: canvasPillWidth + pillHeight / (isTruncated ? 1.5 : 1),
      pillHeight
    };
  }

  getSpeakerNameTitlePillTextSizes(
    totalNumber: number,
    correctionFactor: number = 1
  ): {
    nameSize: number;
    titleSize: number;
  } {
    let nameSize = 40;
    let titleSize = 32;
    const ratio = nameSize / titleSize;

    if (totalNumber >= 2) {
      nameSize = 32;
    }

    titleSize = nameSize / ratio;

    return {
      nameSize: nameSize * this.devicePixelRatio * correctionFactor,
      titleSize: titleSize * this.devicePixelRatio * correctionFactor
    };
  }

  getAudiogramWaveformAttributes({ height }) {
    return {
      top: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.y * height,
      left: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.x * height,
      waveFormHeight: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_HEIGHT_MULTIPLIER * height
    };
  }

  drawAudiogramSpeakerLabel(
    canvasContext: CanvasRenderingContext2D,
    speaker: SpeakerWithDetails,
    textColor: string
  ): void {
    const { name, title } = getSpeakerNameAndTitle(speaker);

    // Return and do not draw in case none of the fields are available
    if (!name && !title) return;

    const labelWidth = this.getWidth() * this.AUDIOGRAM_DIMENSIONS.SPEAKER_LABEL_WIDTH_MULTIPLIER;

    const fontName = getCurrentClipFontName(this.clipId);

    const nameSize = 92;
    const titleSize = 84;

    const {
      newTitle,
      newName,
      width: maxWidth
    } = getCanvasTextDimensions({
      maxWidth: labelWidth,
      name,
      title,
      nameFont: `${nameSize}px ${fontName || 'Inter'}`,
      titleFont: `${titleSize}px ${fontName || 'Inter'}`,
      canvasHeight: this.getHeight(),
      canvasWidth: this.getWidth()
    });

    const x = 0.05 * this.getWidth();
    const nameY = this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.y * this.getHeight() + nameSize;
    const titleY = nameY + titleSize * 1.2;

    const fontItem = getFontByName(fontName as string);
    const drawContent = () => {
      this.drawText(canvasContext, newName, x, nameY, maxWidth, `${nameSize}px ${fontName || 'Inter'}`, textColor, 1);

      this.drawText(
        canvasContext,
        newTitle,
        x,
        titleY,
        maxWidth,
        `${titleSize}px ${fontName || 'Inter'}`,
        textColor,
        0.6
      );
    };
    if (fontItem) {
      loadFont(fontItem.url, fontItem.name).then(() => {
        drawContent();
      });
    } else {
      drawContent();
    }
  }

  getCaptionsFontSize(): number {
    if (this.clipLayout === 'AUDIOGRAM') {
      return this.CAPTIONS_FONT_SIZE * 1.05;
    }
    return this.CAPTIONS_FONT_SIZE;
  }

  getCaptionAttributes({
    containerHeight,
    captionFontSize
  }: {
    containerHeight: number;
    containerWidth: number;
    captionFontSize: number;
  }): CaptionAttributes {
    const top = this.AUDIOGRAM_DIMENSIONS.IMG_DIMENSIONS_RATIO.height * containerHeight + 0.02 * containerHeight;
    const left = 0.02 * containerHeight;
    const right = 0.02 * containerHeight;
    const fontSize = `${captionFontSize * 1}px`;
    const lineHeight = `${(9 * captionFontSize) / 8}px`;

    return { top, left, right, fontSize, lineHeight, rotation: 0, wrap_style: 1, alignment: 7 };
  }
}
