import { Popover } from '@headlessui/react';
import { memo, useCallback } from 'react';
import CaptionPreviewStyleOption from '../../CanvasPlayer/CanvasPlayerDraggableCaptions/CaptionsMenu/CaptionPreviewStyleOption';
import { CaptionStyleOption } from '../../CanvasPlayer/CanvasPlayerDraggableCaptions/CaptionsMenu/types';
import { getFontByName } from '../../SideBar/FontSelector/constants';
import { CAPTIONS_POPUP_OPTIONS } from './constants';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import {
  toggleCaptions,
  updateCaptionStyles,
  updateFontLocation,
  updateHighlightColor,
  updateHighlightType,
  updateKeyInMagicLayout,
  updateTextHighlightColor
} from '@/stores/clip';
import IconButton from '@/components/atoms/Button/IconButton';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

function ClipCustomizerCaptionsPopup({ onEditCaptions }: { onEditCaptions: () => void }) {
  const { clipId, clipData } = useClipsContext();

  const applyOption = useCallback((val: CaptionStyleOption) => {
    if (!clipData.asset_metadata.subtitle) {
      toggleCaptions(clipId, true);
    }
    updateHighlightType(clipId, val.highlight);
    updateCaptionStyles(clipId, { animation: val.style });
    if (val.textColor) updateKeyInMagicLayout(clipId, 'textColor', val.textColor);
    if (val.wordHighlightColor) updateHighlightColor(clipId, val.wordHighlightColor);
    if (val.textHighlightColor) updateTextHighlightColor(clipId, val.textHighlightColor);
    if (val.fontFamily) {
      const fontItem = getFontByName(val.fontFamily);
      if (fontItem) updateFontLocation(clipId, fontItem.url);
    }
    if (val.backgroundStyleColor) {
      updateCaptionStyles(clipId, { background_style_color: val.backgroundStyleColor });
    }
  }, []);

  function triggerEditCaptions() {
    EventBus.dispatch(CustomEvents.OpenEditCaptions);
    onEditCaptions();
    EventBus.dispatch(CustomEvents.OpenCaptionsMenu);
  }

  return (
    <Popover className="absolute right-20 flex max-h-[54vh] w-[23rem] flex-col overflow-y-auto rounded-lg border bg-white shadow">
      <div className="sticky top-0 z-10 flex items-center justify-between bg-white px-4 py-3">
        <div className="text-lg font-medium">Captions</div>
        <IconButton
          icon="IconPencil"
          size="small"
          onClick={triggerEditCaptions}
          trackingId="captions-menu-edit-captions"
          content="Edit Captions"
        />
      </div>
      <div className="flex flex-col">
        {CAPTIONS_POPUP_OPTIONS.map((option, index) => (
          <div
            key={index}
            className="mx-2.5 my-[3px] cursor-pointer rounded-xl bg-slate-200 hover:bg-slate-300"
            onClick={() => applyOption(option)}
          >
            <div className="px-6 py-4">
              <CaptionPreviewStyleOption
                fontSize={18}
                hideDescription={true}
                clipMetadata={clipData.asset_metadata}
                styleOption={option}
              />
            </div>
          </div>
        ))}
      </div>
    </Popover>
  );
}

export default memo(ClipCustomizerCaptionsPopup);
