import React, { useCallback, useState } from 'react';
import { IconBrandVimeo, IconBrandYoutube, IconBrandZoom, IconLink } from '@tabler/icons-react';
import { useMutation } from 'react-query';
import { v6 as uuidv6 } from 'uuid';
import { assetsContentUploadUrlImportCreate } from '@goldcast/api/content';
import { getFilesValidationErrors } from './util';
import { CONTENT_FORMATS, REGEX_URL } from './constants';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import FilesInput from '@/components/atoms/inputs/FilesInput/FilesInput';
import Button from '@/components/atoms/Button/Button';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';
import Loader from '@/components/atoms/Loader';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { currentUser } from '@/stores/user';
import { loadOrgUsageSummary } from '@/stores/orgUsageSummary';
import { ReactComponent as WistiaIcon } from '@/assets/icons/wistia.svg';
import { ContentStatesEnum } from '@/Pages/GenerateContent/constants';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import { getFileNameTitleCase, isVideoFile } from '@/libs/file';
import { getTotalVideoDuration } from '@/libs/getVideoDuration';
import { roundToNDecimalPlaces } from '@/libs/utils';
import useAnalytics from '@/hooks/useAnalytics';

export default function NewContentModal({
  title = 'Drop the video you want to repurpose!',
  description = 'Try uploading webinars, product demos, video podcasts, or any other long-form video content.'
}: {
  title?: string;
  description?: string;
}) {
  const { checkVideoUploadLimitReached, notifyUserToUpgrade } = useFreeTrialHook();
  const { adminAppStore, logger } = useAppContext();

  const { handleImportUrlContent, setUploadContent } = useContentUploadHook();
  const { trackVideoUpload } = useAnalytics();

  async function onFilesUpload(files: File[]) {
    const { value, reason } = await checkVideoUploadLimitReached(files);
    if (value) {
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Disabled',
        url: inputValue,
        failureReason: reason
      });
      return;
    }
    const isFileInvalid = handleFilesValidation(files);
    if (!isFileInvalid) {
      handleContentUpload(files);
    }
  }

  const handleFilesValidation = useCallback((files: File[]) => {
    const fileName = files[0].name;
    const { files: fileError, global } = getFilesValidationErrors(files);
    const error = fileError[fileName] || (global && global[0]);
    if (error) {
      showErrorToast(error);
      trackVideoUpload({
        uploadType: 'Upload',
        status: 'Disabled',
        url: inputValue,
        failureReason: `${error}`
      });
      return true;
    }
    return false;
  }, []);

  const handleContentUpload = async (filesToUpload: File[]) => {
    const duration = await getTotalVideoDuration(filesToUpload);
    const contentDetails = {
      projectId: uuidv6({ msecs: new Date().getTime() }),
      contentId: uuidv6({ msecs: new Date().getTime() }),
      duration: roundToNDecimalPlaces(duration, 3),
      file: filesToUpload[0],
      title: getFileNameTitleCase(filesToUpload[0].name),
      av_type: isVideoFile(filesToUpload[0]) ? 'VIDEO' : 'AUDIO',
      contentState: ContentStatesEnum.Uploading
    };
    setUploadContent(contentDetails);
    trackVideoUpload({
      uploadType: 'Upload',
      status: 'Started'
    });
    adminAppStore.commit?.('contentStudio/closeNewContentDialog');
    EventBus.dispatch(CustomEvents.UploadContentStarted, {
      projectId: contentDetails.projectId,
      contentId: contentDetails.contentId
    });
  };

  const [inputValue, setInputValue] = useState('');
  const [isValidLink, setIsValidLink] = useState(true);

  function handleInputChange(event: React.ChangeEvent) {
    const value = ((event.target as HTMLInputElement).value || '').trim();
    setInputValue(value);
  }

  const { mutate: importFromURL, isLoading: isImporting } = useMutation(assetsContentUploadUrlImportCreate, {
    onError: showImportError,
    onSuccess: data => {
      trackVideoUpload({
        uploadType: 'Import',
        status: 'Completed',
        url: inputValue
      });
      handleImportUrlContent({
        projectId: data.project_id ?? '',
        contentId: data.id,
        contentState: ContentStatesEnum.Processing,
        contentUrl: data?.import_url,
        title: data.title ?? '',
        av_type: data.av_type ?? ''
      });
      EventBus.dispatch(CustomEvents.UploadContentStarted, {
        projectId: data.project_id,
        contentId: data.id
      });
      loadOrgUsageSummary(currentUser.getSnapshot()?.organization as string);
      adminAppStore.commit?.('contentStudio/closeNewContentDialog');
      return;
    }
  });

  function showImportError(err) {
    trackVideoUpload({
      uploadType: 'Import',
      status: 'Failed',
      url: inputValue,
      failureReason: err
    });
    try {
      const parsed = JSON.parse(err as string);
      if (parsed && Array.isArray(parsed) && parsed.length > 0 && !!parsed[0]) {
        if (parsed[0].includes('Total content duration usage exceeded')) {
          notifyUserToUpgrade();
          return;
        }
        showErrorToast(parsed[0]);
      }
    } catch (e) {
      logger.error('Failed parsing import error: ', e);
      showErrorToast(
        `We can't import this video due to issues like an incorrect URL, privacy settings, multiple streams, or technical limitations. Please check and try again.`
      );
    }
  }

  const startImport = useCallback(() => {
    const isValid = !!inputValue.match(REGEX_URL);
    setIsValidLink(isValid);
    if (isValid) {
      importFromURL({
        body: {
          url: inputValue
        }
      });
    }
  }, [inputValue]);

  return (
    <div className="content-lab-root tw-h-full">
      <div className="h-full overflow-y-auto">
        <div className="flex h-full w-full flex-col px-8">
          <div className="mb-4">
            <div className="text-2xl font-semibold">{title}</div>
          </div>
          <p className="mb-5 text-sm text-slate-600">{description}</p>
          <div className="relative flex w-full items-center text-slate-700 focus-within:text-deep-orange-600">
            <IconLink className="absolute left-3" size={20} />
            <input
              type="text"
              data-testid="import-url-input"
              onChange={handleInputChange}
              className="w-full rounded-lg border py-3.5 pl-10 pr-28 text-sm text-black focus:ring-deep-orange-600"
              placeholder="Drop a video link…"
            />
            <Button
              className="absolute right-1 m-1"
              variation="filled"
              trackingId="import-video-button"
              disabled={isImporting || !inputValue}
              onClick={startImport}
            >
              {isImporting && <Loader size="xs" />}
              Import Video
            </Button>
          </div>
          <div className="mt-1.5 flex text-xs text-slate-500">
            {isValidLink ? (
              <div className="flex whitespace-nowrap">
                Use a
                <div className="ml-1 flex space-x-1 whitespace-nowrap">
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <IconBrandYoutube size={16} /> YouTube,
                  </span>
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <IconBrandZoom size={16} /> Zoom,
                  </span>
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <WistiaIcon className="h-3.5 w-3 stroke-slate-500" />
                    Wistia, or
                  </span>
                  <span className="inline-flex gap-1 whitespace-nowrap">
                    <IconBrandVimeo size={16} /> Vimeo or direct link to a video file.
                  </span>
                </div>
              </div>
            ) : (
              <span className="text-deep-orange-600">
                Oops! It looks like the URL you entered isn't quite right. Please double-check it and try again.
              </span>
            )}
          </div>
          <div className="mb-1.5 mt-4 flex w-full items-center">
            <div className="h-[1px] grow bg-slate-300"></div>{' '}
            <div className="shrink-0 px-4 text-xs text-slate-500">OR</div>
            <div className="h-[1px] grow bg-slate-300"></div>
          </div>
          <div className="h-full pb-8 pt-3">
            <FilesInput onFilesUpload={onFilesUpload} accept={CONTENT_FORMATS} title="Upload a file" multiple={false} />
          </div>
        </div>
      </div>
    </div>
  );
}
