import { memo, useCallback, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import IntroOutroPopover from './IntroOutroPopover/IntroOutroPopover';
import Icon from '@/components/atoms/Icon';

function AddNewSection({
  position,
  index,
  onSectionClick,
  onSectionClose
}: {
  position: 'left' | 'right';
  index: number;
  onSectionClick: (_index) => void;
  onSectionClose: (_index: number) => void;
}) {
  useEffect(() => {
    return () => {
      onSectionClose(index);
    };
  }, [index, onSectionClose]);

  const onButtonClick = useCallback(() => {
    onSectionClick(index);
  }, [index, onSectionClick]);

  return (
    <Popover>
      <Popover.Button
        className="group flex aspect-square h-20 w-20 cursor-pointer items-center justify-center !rounded-xl bg-slate-100 hover:bg-slate-200"
        onClick={onButtonClick}
      >
        <Icon name="IconPlus" className="text-slate-600 group-hover:text-slate-800" size={24} />
      </Popover.Button>
      <Transition
        appear
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100 z-10 absolute"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100 z-10 absolute"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          className={
            'absolute bottom-24 w-[24rem] translate-y-2 rounded-[0.75rem] border bg-white shadow-lg ' +
            (position === 'right' ? 'right-0' : 'left-0')
          }
        >
          <IntroOutroPopover />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default memo(AddNewSection);
