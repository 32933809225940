import { useCallback, useSyncExternalStore } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssetList } from '@goldcast/api/content';
import { Clip } from '@/domains/asset';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';

export default function useClipNavigate() {
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isEasyClipCustomizerEnabled = featureFlags[FeatureFlagKeys.Use_CL_Easy_Clip_Customizer];

  const navigate = useNavigate();

  const navigateToClip = useCallback(
    (clipData: Clip | AssetList, { downloadIntent }: { downloadIntent: boolean }) => {
      if (clipData.saved_search) {
        navigate(`/search/${clipData.saved_search.id}/clips?activeClipId=${clipData.id}`, {
          state: { downloadIntent }
        });
        return;
      }
      isEasyClipCustomizerEnabled
        ? navigate(`/${clipData.content!.project.id}/${clipData.content!.id}/clips?activeClipId=${clipData.id}`, {
            state: { downloadIntent }
          })
        : navigate(`/${clipData.content!.project.id}/${clipData.content!.id}/clips/${clipData.id}`);
    },
    [isEasyClipCustomizerEnabled, navigate]
  );

  // Naviate to clips list if users try to enter edit page by URL
  // Cleanup when Easy Clip Customizer FF is removed
  const navigateToEasyClipEditor = useCallback(
    (projectId: string, contentId: string, clipId: string) => {
      if (isEasyClipCustomizerEnabled) navigate(`/${projectId}/${contentId}/clips?activeClipId=${clipId}`);
    },
    [isEasyClipCustomizerEnabled, navigate]
  );

  return {
    navigateToClip,
    navigateToEasyClipEditor
  };
}
