export interface PusherContextState {
  isPusherConnected: boolean;
}

export const defaultState: PusherContextState = {
  isPusherConnected: false
};

export enum PUSHER_STATES {
  TRANSCRIPTION_DONE = 'TRANSCRIPTION_UPDATED:DONE',
  TRANSCRIPTION_FAILED = 'TRANSCRIPTION_UPDATED:FAILED',
  TRANSCRIPTION_INVALID_AUDIO = 'TRANSCRIPTION_UPDATED:INVALID_AUDIO',
  TRANSCRIPTION_NO_AUDIOSTREAM = 'TRANSCRIPTION_UPDATED:NO_AUDIOSTREAM',
  TRANSCRIPTION_PROCESSING = 'TRANSCRIPTION_UPDATED:PROCESSING',
  TRANSCRIPTION_UNAVAILABLE = 'TRANSCRIPTION_UPDATED:UNAVAILABLE',
  CLIP_GENERATION_DONE = 'CLIP_GENERATION_STATUS:DONE',
  CLIP_GENERATION_FAILED = 'CLIP_GENERATION_STATUS:FAILED',
  CLIP_GENERATION_LIMIT_REACHED = 'CLIP_GENERATION_STATUS:LIMIT_REACHED',
  DOWNLOAD_DONE = 'DOWNLOAD_UPDATED:DONE',
  DOWNLOAD_FAILED = 'DOWNLOAD_UPDATED:FAILED',
  MULTIMODAL_DOWNLOAD_DONE = 'MULTIMODAL_DOWNLOAD_UPDATED:DONE',
  MULTIMODAL_DOWNLOAD_FAILED = 'MULTIMODAL_DOWNLOAD_UPDATED:FAILED',
  SOCIAL_POST_GENERATION_DONE = 'SOCIAL_POST_GENERATION_STATUS:DONE',
  BLOG_POST_GENERATION_DONE = 'BLOG_POST_GENERATION_STATUS:DONE',
  EMAIL_POST_GENERATION_DONE = 'EMAIL_POST_GENERATION_STATUS:DONE',
  TAKEAWAY_POST_GENERATION_DONE = 'TAKEAWAY_POST_GENERATION_STATUS:DONE',
  FACIAL_RECOGNITION_FAST_PROCESSING = 'FACIAL_RECOGNITION_FAST:PROCESSING',
  FACIAL_RECOGNITION_FAST_DONE = 'FACIAL_RECOGNITION_FAST:DONE',
  FACIAL_RECOGNITION_FAST_FAILED = 'FACIAL_RECOGNITION_FAST:FAILED',
  FACIAL_RECOGNITION_ACCURATE_PROCESSING = 'FACIAL_RECOGNITION_ACCURATE:PROCESSING',
  FACIAL_RECOGNITION_ACCURATE_DONE = 'FACIAL_RECOGNITION_ACCURATE:DONE',
  FACIAL_RECOGNITION_ACCURATE_FAILED = 'FACIAL_RECOGNITION_ACCURATE:FAILED',
  FTUX_CLIPS_FACIAL_RECOGNITION_DONE = 'FTUX_CLIPS_FACIAL_RECOGNITION:DONE'
}

export const PUSHER_STATE_MESSAGES: Record<PUSHER_STATES, string> = {
  [PUSHER_STATES.TRANSCRIPTION_DONE]:
    'Video Processing Completed! Fresh assets have been generated from your uploaded video.',
  [PUSHER_STATES.TRANSCRIPTION_FAILED]: 'Video Processing Failed: We do not support the language used in this video.',
  [PUSHER_STATES.TRANSCRIPTION_INVALID_AUDIO]:
    'Video Processing Failed: The audio is unclear in this video. Please upload a file with clearly audible speech.',
  [PUSHER_STATES.TRANSCRIPTION_NO_AUDIOSTREAM]:
    'Video Processing Failed: This video lacks audio. Please upload a file with sound.',
  [PUSHER_STATES.TRANSCRIPTION_PROCESSING]: 'Video processing: We are currently processing your video',
  [PUSHER_STATES.TRANSCRIPTION_UNAVAILABLE]:
    'Video Processing Failed: There was an issue processing your video. Please contact our support team for assistance.',
  [PUSHER_STATES.CLIP_GENERATION_DONE]:
    'Clip Generation Completed! Fresh assets have been generated from your uploaded video.',
  [PUSHER_STATES.CLIP_GENERATION_FAILED]: 'Sorry, we encountered an issue generating clips for the uploaded video.',
  [PUSHER_STATES.CLIP_GENERATION_LIMIT_REACHED]:
    'Clip Generation Failed: You have reached the limit for generating clips from this video.',
  [PUSHER_STATES.DOWNLOAD_DONE]: 'Your clip is ready!',
  [PUSHER_STATES.DOWNLOAD_FAILED]: 'This video failed to download, please try again.',
  [PUSHER_STATES.MULTIMODAL_DOWNLOAD_DONE]: 'Your post is ready!',
  [PUSHER_STATES.MULTIMODAL_DOWNLOAD_FAILED]: 'This post failed to download, please try again.',
  [PUSHER_STATES.SOCIAL_POST_GENERATION_DONE]: 'Social Post Generation Completed!',
  [PUSHER_STATES.BLOG_POST_GENERATION_DONE]: 'Blog Post Generation Completed!',
  [PUSHER_STATES.EMAIL_POST_GENERATION_DONE]: 'Email Post Generation Completed!',
  [PUSHER_STATES.TAKEAWAY_POST_GENERATION_DONE]: 'TakeAway Post Generation Completed!',
  [PUSHER_STATES.FACIAL_RECOGNITION_FAST_PROCESSING]: '',
  [PUSHER_STATES.FACIAL_RECOGNITION_FAST_DONE]: '',
  [PUSHER_STATES.FACIAL_RECOGNITION_FAST_FAILED]:
    'Failed to process clip. Please try moving to a different layout or template.',
  [PUSHER_STATES.FACIAL_RECOGNITION_ACCURATE_PROCESSING]: '',
  [PUSHER_STATES.FACIAL_RECOGNITION_ACCURATE_DONE]: '',
  [PUSHER_STATES.FACIAL_RECOGNITION_ACCURATE_FAILED]:
    'Failed to process clip. Please try moving to a different layout or template',
  [PUSHER_STATES.FTUX_CLIPS_FACIAL_RECOGNITION_DONE]:
    'Clip Generation Completed! Fresh assets have been generated from your uploaded video.'
};
