import { BrandGuidelineConfig } from './types';

export const CONTENT_FORMATS = '.pdf';

export const BRAND_GUIDELINE_CONFIG: Record<string, BrandGuidelineConfig> = {
  brandGuidelines: {
    icon: 'IconFileTypePdf',
    hoverIcon: 'IconPlus',
    label: 'Brand Guidelines',
    description: 'Upload your brand guidelines including a description of your voice and tone',
    key: 'brandGuidelines'
  },
  sampleCopy: {
    icon: 'IconFileText',
    hoverIcon: 'IconPlus',
    label: 'Example Copy',
    description: 'Paste sample copy like an article or blog post that represents your voice and tone',
    key: 'sampleCopy'
  }
};

export const UPLOAD_CONFIG = {
  retry: 3,
  timeout: 60000
};

export const BRAND_TONE_FILE_LIMIT = 30;
export const BRAND_TONE_GUIDELINE_FILE_SIZE = BRAND_TONE_FILE_LIMIT * 1024 * 1024; // 30MB
export const MAX_SAMPLE_COPY_LENGTH = 100000;
export const MIN_SAMPLE_COPY_LENGTH = 500;
export const MAX_PROFILE_NAME_LENGTH = 255;

export enum VoiceProfileSteps {
  CreateVoiceProfile = 1,
  TuneVoiceProfile = 2,
  SaveVoiceProfile = 3
}
