import { Icon, IconProps } from '@tabler/icons-react';
import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { classnames } from '@/libs/utils';

export type NavItemType = {
  icon: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  filledIcon: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
  label: string;
  href: string;
  visible: boolean;
  unseenCount?: number;
};

export default function NavItem({ navItem }: { navItem: NavItemType }) {
  const location = useLocation();

  if (!navItem.visible) {
    return null;
  }

  const isActive = location.pathname.startsWith(navItem.href);

  return (
    <Link
      to={navItem.href}
      state={{
        previousPathname: location.pathname
      }}
    >
      <li className="group relative flex cursor-pointer flex-col items-center space-y-0.5">
        {navItem.unseenCount && !isActive ? (
          <div className="absolute right-1 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-deep-orange text-2xs font-semibold text-white">
            {navItem.unseenCount}
          </div>
        ) : null}
        <div
          className={classnames('flex h-9 w-9 items-center justify-center rounded-[0.75rem]', {
            'bg-deep-orange': isActive,
            'group-hover:bg-slate-200': !isActive
          })}
        >
          {isActive ? (
            <navItem.filledIcon className="h-[18px] w-[18px] text-white" />
          ) : (
            <navItem.icon className="h-5 w-5 text-slate-600 group-hover:bg-slate-200 group-hover:text-black" />
          )}
        </div>
        <span
          className={classnames('text-xs', {
            'text-black': isActive,
            'text-slate-600 group-hover:text-black': !isActive
          })}
        >
          {navItem.label}
        </span>
      </li>
    </Link>
  );
}
