import React, { useEffect, useRef, useState, useSyncExternalStore } from 'react';
import PlayerControls from './PlayerControls';
import Player from '../SessionDetail/Player';
import SessionTitlePopup from '../SessionDetail/SessionTitlePopup';
import FullTranscript from '@/components/molecules/Transcript/FullTranscript';
import { Word } from '@/domains/transcript';
import type { ClipMetadata, SourceType } from '@/domains/asset';
import { isAudioContent, core } from '@/stores/core';
import { createContentClip } from '@/libs/clipContentUtil';
import useFreeTrialHook from '@/hooks/useFreeTrialHook';
import { player } from '@/stores/player';
import { showErrorToast } from '@/libs/toast/toast';
import { prependAllClips, setClipGeneratingCount } from '@/stores/clip';
import { addUnseenClipIds } from '@/stores/unseenClips';
import { classnames } from '@/libs/utils';
import FreeTrialUpgradeNag from '@/components/molecules/FreeTrial/FreeTrialUpgradeNag';
import useAnalytics from '@/hooks/useAnalytics';

export default function TranscriptPage() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const playerStore = useSyncExternalStore(player.subscribe, player.getSnapshot);

  const containerRef = useRef<HTMLDivElement>(null);
  const { incrementClipsUsed } = useFreeTrialHook();
  const [isSampleVideoPlayedEventTriggered, setIsSampleVideoPlayedEventTriggered] = useState<boolean>(false);
  const { trackSampleAssetPlayed, trackContentCreated } = useAnalytics();

  useEffect(() => {
    const mainVideoPlayer = document.getElementById('mainVideoPlayer') as HTMLVideoElement;
    if (mainVideoPlayer) {
      mainVideoPlayer.currentTime = 0;
    }
  }, []);

  async function saveClip({ start, end }: Pick<ClipMetadata, 'start' | 'end'>, source: SourceType) {
    try {
      const newClip = await createContentClip(start, end, source);
      prependAllClips([{ ...newClip, isRemoved: false } as any]);
      addUnseenClipIds([newClip.id]);
      if (!coreStore.content?.is_sample_upload) {
        incrementClipsUsed(1);
      }
      trackContentCreated({
        asset: newClip,
        state: 'Created',
        source: 'Manual'
      });
      return newClip;
    } catch (err: any) {
      trackContentCreated({
        source: 'Manual',
        state: 'Failed',
        failureReason: err
      });
      showErrorToast("Creating the clip didn't quite work out. Double-check the time range and give it another shot.");
    }
  }

  const createClip = (words: Word[]) => {
    setClipGeneratingCount(1);
    const { start_time } = words[0];
    const { end_time = 0 } = words.at(-1) ?? {};
    return saveClip(
      {
        start: start_time,
        end: end_time
      },
      'USER'
    ).then(_newClip => {
      setClipGeneratingCount(0);
    });
  };
  const isAudio = isAudioContent();

  useEffect(() => {
    if (!isSampleVideoPlayedEventTriggered && !playerStore.paused) {
      trackSampleAssetPlayed();
      setIsSampleVideoPlayedEventTriggered(true);
    }
  }, [playerStore.paused, coreStore, isSampleVideoPlayedEventTriggered]);

  return (
    <div className="grid h-full w-full grid-cols-[1fr] grid-rows-[auto,max-content] pb-4 pr-4">
      <div className="grid w-full grid-cols-[max-content,1fr] grid-rows-[1fr] gap-4" ref={containerRef}>
        <FullTranscript onCreateClip={createClip} />
        <div
          className={classnames('flex grow flex-col transition-all duration-[400ms] ease-in-out', {
            hidden: isAudio
          })}
        >
          <div className="flex w-full items-center justify-between py-4">
            <SessionTitlePopup />
            <FreeTrialUpgradeNag />
          </div>
          <Player hidden={isAudio} />
        </div>
      </div>
      <PlayerControls clips={[]} />
    </div>
  );
}
