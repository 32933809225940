import React, { memo, useEffect, useState } from 'react';
import { CAPTION_LOOP_DURATION, transitionTemplateMocks } from './constants';
import MockTemplateBackgroundTransitionItem from './MockTemplateBackgroundTransitionItem';
import { classnames } from '@/libs/utils';
import styles from './MockTemplateBackgroundTransition.module.scss';

function MockTemplateBackgroundTransition() {
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(prev => {
        if (prev >= CAPTION_LOOP_DURATION) {
          return 0;
        } else {
          return prev + 0.01;
        }
      });
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="absolute top-0 flex h-full w-full grow flex-col space-x-2 blur-[6px]">
      <div className="flex grow flex-col transition-all duration-[400ms] ease-in-out">
        <div className={classnames(styles['scroll'], 'top-0 w-full columns-5 gap-8 px-8 transition-all duration-500')}>
          {transitionTemplateMocks.map(item => (
            <div
              key={item.id}
              className={classnames(
                item.timeline.aspectRatioClass,
                'mt-10 flex w-full items-center justify-center rounded-md bg-slate-100 first:mt-0'
              )}
            >
              <MockTemplateBackgroundTransitionItem templateMock={item} currentTime={currentTime} />
            </div>
          ))}
          {transitionTemplateMocks.map(item => (
            <div
              key={item.id + '2'}
              className={classnames(
                item.timeline.aspectRatioClass,
                'mt-10 flex w-full items-center justify-center rounded-md bg-slate-100 first:mt-0'
              )}
            >
              <MockTemplateBackgroundTransitionItem templateMock={item} currentTime={currentTime} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(MockTemplateBackgroundTransition);
