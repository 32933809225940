import { ContentUpload } from '@goldcast/api/content';
import moment from 'moment';
import LabeledInput from '@/components/atoms/LabeledInput';

export default function RecordingDetailsStep({
  recordingDetails,
  isInvalid = false,
  onRecordingUpdate
}: {
  recordingDetails: Partial<ContentUpload>;
  isInvalid?: boolean;
  onRecordingUpdate: (field: string, value: string) => void;
}) {
  const maxDate = moment().format('YYYY-MM-DD');
  return (
    <div>
      <LabeledInput
        classNames="pb-3"
        label="Title"
        isInvalid={isInvalid && !recordingDetails.title}
        isRequired
        data-testid="title-input"
        value={recordingDetails.title}
        onChange={value => onRecordingUpdate('title', value)}
      />
      <LabeledInput
        classNames="pb-3"
        label="Recording Date"
        data-testid="recording-date-input"
        type="date"
        isRequired
        isInvalid={isInvalid && !recordingDetails.recording_date}
        max={maxDate}
        value={recordingDetails.recording_date}
        onChange={value => onRecordingUpdate('recording_date', value)}
      />
    </div>
  );
}
