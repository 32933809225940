import { AiClipPromptOption } from './types';

export const SEARCH_OPTIONS: AiClipPromptOption[] = [
  {
    icon: 'IconPlayerPlay',
    label: 'Find new clips about …'
  }
];

export enum AI_SEARCH_ERROR_CODES {
  CLIP_NOT_EXIST_FOR_SEARCH_TERM = 'CLIP_NOT_EXIST_FOR_SEARCH_TERM'
}
