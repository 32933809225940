import { useCallback, useMemo, useSyncExternalStore } from 'react';
import {
  assetsAssetRatingCreate,
  assetsAssetRatingPartialUpdate,
  assetsAssetRatingUpdate
} from '@goldcast/api/content';
import { assetRatingStore, AssetRatingState, AssetRatingStore } from '@/stores/assetRating';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';

const defaultRating: AssetRatingState = {
  ratingId: '',
  thumbsUp: null,
  feedbackMessage: ''
};

export default function useAssetRating(): {
  assetRating: { [assetId: string]: AssetRatingState };
  isAssetRatingDialogOpen: boolean;
  openAssetRatingDialog: (assetId: string) => void;
  closeAssetRatingDialog: () => void;
  handleThumbClick: (assetId: string, isThumbsUp: boolean) => void;
  handleSubmit: (feedbackMessage: string) => Promise<void>;
  getAssetRating: (assetId: string) => AssetRatingState;
  setAssetRating: (assetId: string, rating) => void;
  handleAssetFeedback: (assetId: string) => void;
  resetAllAssetRatings: () => void;
} {
  const assetRatingState = useSyncExternalStore(assetRatingStore.subscribe, assetRatingStore.getSnapshot);
  const assetRating = useMemo(() => assetRatingState.assetRating, [assetRatingState.assetRating]);

  const openAssetRatingDialog = useCallback(() => {
    assetRatingStore.update((state: AssetRatingStore) => {
      state.isAssetRatingDialogOpen = true;
    });
  }, []);

  const closeAssetRatingDialog = useCallback(() => {
    assetRatingStore.update((state: AssetRatingStore) => {
      state.isAssetRatingDialogOpen = false;
    });
  }, []);

  const getAssetRating = useCallback(
    (assetId: string) => {
      return assetRating[assetId] || defaultRating;
    },
    [assetRating]
  );

  const updateThumbsUp = useCallback((assetId: string, isThumbsUp: boolean) => {
    assetRatingStore.update((state: AssetRatingStore) => {
      state.assetRating[assetId] = {
        ...state.assetRating[assetId],
        thumbsUp: isThumbsUp
      };
      state.selectedAssetId = assetId;
    });
  }, []);

  const handleThumbClick = useCallback(
    async (assetId: string, isThumbsUp: boolean) => {
      const currentRating = assetRating[assetId];
      if (currentRating?.ratingId && currentRating?.feedbackMessage !== '') {
        try {
          await assetsAssetRatingPartialUpdate({
            id: currentRating.ratingId,
            body: { thumbs_up: isThumbsUp }
          });
          updateThumbsUp(assetId, isThumbsUp);
          showSuccessToast('Thank you for your feedback.');
        } catch (error) {
          showErrorToast('Failed to submit feedback.');
        }
      } else {
        openAssetRatingDialog();
        updateThumbsUp(assetId, isThumbsUp);
      }
    },
    [assetRating]
  );

  const handleAssetFeedback = useCallback(
    async (assetId: string) => {
      assetRatingStore.update((state: AssetRatingStore) => {
        state.selectedAssetId = assetId;
        state.assetRating[assetId] = {
          ...state.assetRating[assetId]
        };
      });
      openAssetRatingDialog();
    },
    [openAssetRatingDialog]
  );

  const handleSubmit = useCallback(
    async feedbackMessage => {
      const { selectedAssetId } = assetRatingState;
      const currentRating = assetRating[selectedAssetId];

      if (!currentRating || (!feedbackMessage && currentRating?.thumbsUp === null)) {
        closeAssetRatingDialog();
        return;
      }

      const body = {
        asset: selectedAssetId,
        thumbs_up: currentRating.thumbsUp,
        feedback_message: feedbackMessage
      } as any;

      try {
        const updatedRating = currentRating.ratingId
          ? await assetsAssetRatingUpdate({ id: currentRating.ratingId, body })
          : await assetsAssetRatingCreate({ body });

        assetRatingStore.update((state: AssetRatingStore) => {
          state.assetRating[selectedAssetId] = {
            ratingId: updatedRating.id,
            thumbsUp: updatedRating?.thumbs_up ?? null,
            feedbackMessage: updatedRating?.feedback_message ?? ''
          };
        });
        showSuccessToast('Thank you for your feedback.');
      } catch (error) {
        showErrorToast('Failed to submit feedback.');
      } finally {
        closeAssetRatingDialog();
      }
    },
    [assetRatingState.selectedAssetId, assetRating]
  );

  const setAssetRating = useCallback((assetId: string, rating) => {
    const updatedRating = rating
      ? {
          ratingId: rating.id,
          thumbsUp: rating.thumbs_up,
          feedbackMessage: rating.feedback_message
        }
      : defaultRating;

    assetRatingStore.update((state: AssetRatingStore) => {
      state.assetRating[assetId] = updatedRating;
    });
  }, []);

  const resetAllAssetRatings = useCallback(() => {
    assetRatingStore.update((state: AssetRatingStore) => {
      state.assetRating = {};
      state.selectedAssetId = '';
      state.isAssetRatingDialogOpen = false;
    });
  }, []);

  return {
    assetRating,
    isAssetRatingDialogOpen: assetRatingState.isAssetRatingDialogOpen,
    openAssetRatingDialog,
    closeAssetRatingDialog,
    handleThumbClick,
    handleSubmit,
    getAssetRating,
    setAssetRating,
    handleAssetFeedback,
    resetAllAssetRatings
  };
}
