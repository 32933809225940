import React, { useEffect, useMemo, useSyncExternalStore } from 'react';
import { IconRewindForward10, IconRewindBackward10, IconSlash } from '@tabler/icons-react';
import { PLAYER_SKIP_DURATION } from './constants';
import useTranscriptPlayPause from './useTranscriptPlayPause';
import PlaybackRateButton from '../Clip/ClipPlayer/PlaybackRateButton';
import PlayPause from '@/components/molecules/PlayPause';
import Timeline from '@/components/molecules/Timeline';
import { player, updatePlayerTime } from '@/stores/player';
import { formatDuration } from '@/libs/core';
import { userPreferencesStore, toggleTimelineCollapse } from '@/stores/userPreferencesStore';
import IconButton from '@/components/atoms/Button/IconButton';

export default function PlayerControls({ clips }) {
  const playerStore = useSyncExternalStore(player.subscribe, player.getSnapshot);
  const { isTimelineCollapsed } = useSyncExternalStore(
    userPreferencesStore.subscribe,
    userPreferencesStore.getSnapshot
  );

  const duration = useMemo(() => formatDuration(playerStore.duration), [playerStore.duration]);
  const currentTime = useMemo(() => formatDuration(playerStore.currentTime), [playerStore.currentTime]);

  const { play, pause } = useTranscriptPlayPause();

  useEffect(() => {
    return () => {
      player.update(data => ({ ...data, currentTime: 0, paused: true }));
    };
  }, []);

  function skipBack() {
    const newTime = playerStore.currentTime - PLAYER_SKIP_DURATION;
    updatePlayerTime(Math.max(newTime, 0));
  }

  function skipForward() {
    const newTime = playerStore.currentTime + PLAYER_SKIP_DURATION;
    updatePlayerTime(Math.min(newTime, playerStore.duration));
  }

  return (
    <div className="w-full rounded-2xl bg-white p-3 shadow-lg">
      <div className="flex justify-between px-4">
        <div className="flex w-full items-center justify-center">
          <div className="flex h-9 w-16 cursor-pointer items-center justify-center rounded-sm">
            <IconRewindBackward10 className="h-5 w-5 text-slate-500" onClick={skipBack} />
          </div>
          <div className="mx-4 flex items-center gap-2">
            <PlaybackRateButton />
            <PlayPause paused={playerStore.paused} onPlay={play} onPause={pause} />
            <div className="ml-2.5 mt-[1px] text-sm tabular-nums text-slate-600">
              {currentTime}
              <span className="text-slate-400">
                <IconSlash size={20} stroke={1.5} className="ml-1 inline-flex -translate-y-[1.5px]" /> {duration}
              </span>
            </div>
          </div>
          <div className="flex h-9 w-16 cursor-pointer items-center justify-center rounded-sm">
            <IconRewindForward10 className="h-5 w-5 text-slate-500" onClick={skipForward} />
          </div>
        </div>
        <IconButton
          icon={isTimelineCollapsed ? 'IconArrowBarUp' : 'IconArrowBarDown'}
          title={isTimelineCollapsed ? 'Expand' : 'Collapse'}
          variation="text"
          trackingId="timeline-collapse-button"
          onClick={toggleTimelineCollapse}
        />
      </div>
      <Timeline clips={clips} visible={!isTimelineCollapsed} />
    </div>
  );
}
