import { Popover, Transition } from '@headlessui/react';
import { IconFileDescription, IconSparkles } from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';
import { memo } from 'react';
import IconButton from '@/components/atoms/Button/IconButton';

export default memo(function CreateClipsPopover({
  generateClips,
  canGenerate
}: {
  generateClips: () => Promise<void>;
  canGenerate: boolean;
}) {
  const { eventId, broadcastId } = useParams<{ eventId: string; broadcastId: string }>();
  const navigate = useNavigate();

  const openTranscriptPage = () => {
    navigate(`/${eventId}/${broadcastId}/transcript`);
  };

  return (
    <Popover className="relative z-40">
      <Popover.Button as="div">
        <IconButton
          icon="IconPlus"
          content="Create Clips"
          variation="filled"
          size="full"
          trackingId="open-create-clips-popover-button"
        />
      </Popover.Button>
      <Transition
        appear
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0 origin-bottom-right"
        enterTo="transform scale-100 opacity-100 origin-bottom-right"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100 origin-bottom-right"
        leaveTo="transform scale-95 opacity-0 origin-bottom-right"
      >
        <Popover.Panel className="absolute bottom-16 right-0 w-full translate-y-2 rounded-xl border bg-white shadow-lg">
          {({ close }: { close: any }) => (
            <div className="w-full space-y-1 py-2">
              <button
                onClick={() => {
                  generateClips();
                  close();
                }}
                disabled={!canGenerate}
                data-testid="generate-clips-button"
                title={!canGenerate ? 'No more clips available to generate' : ''}
                className="flex w-full items-center space-x-3 px-6 py-3 text-left hover:bg-slate-50 disabled:cursor-not-allowed disabled:opacity-50"
              >
                <div className="rounded-xl bg-slate-200 p-2.5">
                  <IconSparkles className="h-5 w-5 text-slate-800" />
                </div>
                <div className="leading-tight">
                  <div className="text-sm font-medium">AI Generation</div>
                  <div className="text-xs text-slate-600">Identifies the most engaging parts</div>
                </div>
              </button>
              <button
                onClick={openTranscriptPage}
                data-testid="open-transcript-page-button"
                className="flex w-full items-center space-x-3 px-6 py-3 text-left hover:bg-slate-50"
              >
                <div className="rounded-xl bg-slate-200 p-2.5">
                  <IconFileDescription className="h-5 w-5 text-slate-800" />
                </div>
                <div className="leading-tight">
                  <div className="text-sm font-medium">Transcript</div>
                  <div className="text-xs text-slate-600">Highlight portions of the transcript</div>
                </div>
              </button>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
});
