import { memo, useCallback, useEffect, useState } from 'react';
import Icon, { IconName } from '@/components/atoms/Icon';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';

type PlayRate = {
  value: number;
  icon: IconName;
};

const PLAY_RATES: PlayRate[] = [
  { value: 0.5, icon: 'IconMultiplier05x' },
  { value: 1, icon: 'IconMultiplier1x' },
  { value: 1.5, icon: 'IconMultiplier15x' },
  { value: 2, icon: 'IconMultiplier2x' }
];

const PlaybackRateButton = () => {
  const [currentPlayRate, setCurrentPlayRate] = useState(PLAY_RATES[1]);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.PlaybackRateChange, (playRate: PlayRate) => {
      setCurrentPlayRate(playRate);
      document.querySelectorAll('video').forEach(videoElement => {
        videoElement.playbackRate = playRate.value;
      });
    });

    return () => {
      EventBus.off(CustomEvents.PlaybackRateChange, eventListener);
    };
  }, []);

  const handlePlayRateMultiplierClick = useCallback(() => {
    const currentIndex = PLAY_RATES.indexOf(currentPlayRate);
    const nextIndex = (currentIndex + 1) % PLAY_RATES.length;
    EventBus.dispatch(CustomEvents.PlaybackRateChange, PLAY_RATES[nextIndex]);
  }, [currentPlayRate]);

  return (
    <button
      className="flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-md text-slate-700 hover:bg-slate-100 hover:text-deep-orange"
      title="Adjust playback speed"
      data-testid="play-rate-multiplier-button"
      onClick={handlePlayRateMultiplierClick}
    >
      <Icon name={currentPlayRate.icon} className="h-7 w-7" />
    </button>
  );
};

export default memo(PlaybackRateButton);
