import { LabelElement } from '@goldcast/api/video/schemas/LabelElement';
import { ImageElement } from '@goldcast/api/video/schemas/ImageElement';
import { getCommonBits, getFontProperties, getIntroVideo, getOutroVideo } from './common';
import { roundToNDecimalPlaces } from '../utils';
import { getCanvasTextDimensions } from './sizeConfig/util';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { SharedAPIStore } from '@/stores/sharedAPI/sharedAPI';
import { SpeakerSegmentContextType } from '@/context/SpeakerSegmentContext/SpeakerSegmentContextTypes';
import { getVideoManifestUrl } from '@/stores/core';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getCentreCropValues } from '@/Pages/Clip/CanvasPlayer/CanvasPlayerUtils';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { getCurrentClipFontName } from '@/stores/brandKit';
import { Clip } from '@/domains/asset';

export function getAudiogramLayoutCanvasData({
  clip,
  sharedAPIStoreForClip
}: {
  clip: Clip;
  sharedAPIStoreForClip: SharedAPIStore;
}) {
  const sizeConfig = getSizeConfig(clip.asset_metadata.size, clip.id, clip.asset_metadata.layout, 1);
  const speakerSegmentStore = sharedAPIStoreForClip.speakerSegmentStore as SpeakerSegmentContextType;

  const {
    introVideoSource,
    outroVideoSource,
    clipDuration,
    introVideoDuration,
    outroVideoDuration,
    modifiedExcludes,
    captions,
    canvas_height,
    canvas_width
  } = getCommonBits(sizeConfig, clip, sharedAPIStoreForClip);

  const text: LabelElement[] = [];

  const { end: introEnd, introVideo } = getIntroVideo(
    introVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    introVideoSource
  );

  const speakerWithStartAndEndTimes: { speaker: SpeakerWithDetails; start: number; end: number }[] = [];
  let currentSpeaker = speakerSegmentStore.segmentSpeaker[speakerSegmentStore.captions[0].srt_index];
  let speakerStart = introEnd;
  let previousProcessedEndTime = 0;

  [...speakerSegmentStore.captions].forEach((caption, index) => {
    const srtIndex = caption.srt_index;
    const speakerInSegment = speakerSegmentStore.segmentSpeaker[srtIndex];
    if (speakerInSegment.id !== currentSpeaker?.id || speakerSegmentStore.captions.length - 1 === index) {
      const end = roundToNDecimalPlaces(speakerStart + caption.processed_end_time! - previousProcessedEndTime, 3);
      speakerWithStartAndEndTimes.push({ speaker: currentSpeaker, start: speakerStart, end });

      currentSpeaker = { ...speakerInSegment };
      speakerStart = end;
      previousProcessedEndTime = caption.processed_end_time!;
    }
  });

  const images: ImageElement[] = [];

  const audiogramImageDimensionsRatio = sizeConfig.getAudiogramImageDimensionsRatio();

  const canvasWidth = sizeConfig.getWidth();
  const canvasHeight = sizeConfig.getHeight();

  const targetWidth = canvasWidth * audiogramImageDimensionsRatio.width;
  const targetHeight = canvasHeight * audiogramImageDimensionsRatio.height;

  const {
    left: waveform_x,
    top: waveform_y,
    waveFormHeight
  } = sizeConfig.getAudiogramWaveformAttributes({
    height: canvasHeight,
    width: canvasWidth
  });

  speakerWithStartAndEndTimes.forEach(entry => {
    const { speaker, start, end } = entry;

    const { name, title } = getSpeakerNameAndTitle(speaker);
    const fontName = getCurrentClipFontName(clip.id);

    const { nameSize, titleSize } = sizeConfig.getSpeakerNameTitlePillTextSizes(1, 1.4);

    const labelWidth = sizeConfig.getWidth() * sizeConfig.getAudiogramDimensions().SPEAKER_LABEL_WIDTH_MULTIPLIER;

    const { newTitle, newName } = getCanvasTextDimensions({
      maxWidth: labelWidth,
      name,
      title,
      nameFont: `${nameSize}px ${fontName || 'Inter'}`,
      titleFont: `${titleSize}px ${fontName || 'Inter'}`,
      canvasHeight: sizeConfig.getHeight(),
      canvasWidth: sizeConfig.getWidth()
    });

    let nameX = 0,
      titleX = 0,
      nameY = 0,
      titleY = 0,
      rotation = 0;

    if (clip.asset_metadata.size === 'PORTRAIT') {
      nameX = 0.02 * sizeConfig.getHeight();
      nameY = waveform_y;
      titleY = nameY + titleSize * 1.2;
    } else if (clip.asset_metadata.size === 'SQUARE') {
      nameX = 0.96 * sizeConfig.getWidth();
      titleX = 0.92 * sizeConfig.getWidth();
      nameY = 0.02 * sizeConfig.getHeight();
      titleY = nameY;
      rotation = 270;
    } else {
      nameX = 0.5 * sizeConfig.getWidth() + 0.05 * sizeConfig.getHeight();
      nameY = waveform_y;
      titleY = nameY + titleSize * 1.2;
    }

    text.push(
      ...[
        {
          style: {
            regular_style: {
              font_color: clip.asset_metadata.magicLayout?.textColor,
              border_style: 1,
              outline: 0,
              shadow: 0,
              font_size: Math.round(nameSize),
              alignment: 7,
              ...getFontProperties(clip.asset_metadata, false)
            }
          },
          ...(rotation
            ? {
                position: {
                  x: nameX,
                  y: nameY
                }
              }
            : {
                left_margin: nameX,
                bottom_margin: nameY
              }),
          rotation,
          words: {
            content: newName,
            processed_start_time: start,
            processed_end_time: end
          }
        },
        {
          style: {
            regular_style: {
              font_color: clip.asset_metadata.magicLayout?.textColor,
              border_style: 1,
              outline: 0,
              shadow: 0,
              font_size: Math.round(titleSize),
              alignment: 7,
              font_opacity: '4D',
              ...getFontProperties(clip.asset_metadata)
            }
          },
          ...(rotation
            ? {
                position: {
                  x: titleX,
                  y: titleY
                }
              }
            : {
                left_margin: nameX,
                bottom_margin: titleY
              }),
          rotation,
          words: {
            content: newTitle,
            processed_start_time: start,
            processed_end_time: end
          }
        }
      ]
    );

    if (!speaker.profile_picture_url) return;
    // START: Image crop and set logic
    const imageRefValue = sharedAPIStoreForClip.speakerImageMap?.[speaker.key]?.imageRef;
    if (!imageRefValue) return;
    const { naturalWidth, naturalHeight } = imageRefValue;
    const { crop_x, crop_y, crop_width, crop_height } = getCentreCropValues(
      naturalWidth,
      naturalHeight,
      Math.round(targetWidth),
      Math.round(targetHeight)
    );

    const image = {
      start,
      end,
      height: Math.round(targetHeight),
      width: Math.round(targetWidth),
      x: 0,
      y: 0,
      meta: {
        source: speaker.profile_picture_url,
        crop_x,
        crop_y,
        crop_width,
        crop_height
      }
    };

    images.push(image);
    // END: Image crop and set logic
  });

  const background_image = clip.asset_metadata.magicLayout?.backgroundImage;

  const mainVideo = {
    start: introEnd,
    end: roundToNDecimalPlaces(introEnd + clipDuration, 3),
    x: 0,
    y: 0,
    z: 0,
    bounding_width: 2,
    bounding_height: 2,
    radius: sizeConfig.getBorderVideoRadius(),
    meta: {
      excludes: modifiedExcludes,
      crop_x: 0,
      crop_y: 0,
      crop_width: 2,
      crop_height: 2,
      source: getVideoManifestUrl(),
      in_time: clip.asset_metadata.start
    }
  };

  const { end: outroEnd, outroVideo } = getOutroVideo(
    mainVideo.end,
    outroVideoDuration,
    sizeConfig,
    sharedAPIStoreForClip,
    outroVideoSource
  );

  const waveforms = [
    {
      start: introEnd,
      end: roundToNDecimalPlaces(introEnd + clipDuration, 3),
      x: Math.round(waveform_x),
      y: Math.round(waveform_y),
      z: 0,
      width: Math.round(waveFormHeight),
      height: Math.round(waveFormHeight),
      color: clip.asset_metadata.magicLayout?.textColor || '#FFFFFF'
    }
  ];

  return {
    canvas_width,
    canvas_height: canvas_height,
    duration: outroEnd,
    background_color: clip.asset_metadata.magicLayout?.backgroundColor || '#000000',
    ...(background_image && { background_image }),
    filename: clip.title,
    videos: [...introVideo, mainVideo, ...outroVideo],
    captions,
    waveforms,
    text,
    images
  };
}
