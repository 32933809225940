import React, { memo } from 'react';
import { Tooltip, ITooltip } from 'react-tooltip';
import { ExternalTooltipV2Props } from './atoms/TooltipV2/TooltipV2Types';

const TooltipV2 = ({
  tooltipElement,
  tooltipContent,
  tooltipId,
  ...tooltipProps
}: ITooltip &
  ExternalTooltipV2Props & {
    tooltipElement: React.ReactNode;
  }) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <div data-tooltip-id={tooltipId}>{tooltipElement}</div>

      <Tooltip id={tooltipId} {...tooltipProps} className="bg-black p-1 text-2xs leading-tight text-white">
        {tooltipContent}
      </Tooltip>
    </div>
  );
};

export default memo(TooltipV2);
