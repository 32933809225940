import React, { useMemo, useState, useSyncExternalStore } from 'react';
import { useCaptionContainerRef } from '../ClipPlayer/useCaptionContainerRef';
import StaticCanvas from './StaticCanvas';
import VideoCanvas from './VideoCanvas';
import CanvasPlayerCaptions from './CanvasPlayerCaptions';
import CanvasPlayerDraggableCaptions from './CanvasPlayerDraggableCaptions/CanvasPlayerDraggableCaptions';
import { CAPTION_HORIZONTAL_SPACE_FACTOR } from './constants';
import CanvasAudiogramCaptions from './CanvasAudiogramCaptions';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getFontSize } from '@/libs/sharedAPI/common';

export default function CanvasPlayer({
  mainPlayer,
  secondaryPlayers,
  currentSpeaker,
  loading,
  inlineEditEnabled
}: {
  mainPlayer: React.MutableRefObject<HTMLVideoElement | null>;
  secondaryPlayers: React.RefObject<HTMLVideoElement>[];
  currentSpeaker: SpeakerWithDetails;
  loading: boolean;
  inlineEditEnabled: boolean;
}) {
  const { clipId, clipData } = useClipsContext();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isCaptionsOverlayDisabled = featureFlags[FeatureFlagKeys.Use_CL_Captions_Overlay] === false;

  const { clipSize, showCaption } = useMemo(() => {
    const clipSize = clipData.asset_metadata.size;
    const showCaption = !!clipData.asset_metadata.subtitle;
    return { clipSize, showCaption };
  }, [clipData.asset_metadata.size, clipData.asset_metadata.subtitle]);

  const clipLayout = useMemo(() => clipData.asset_metadata.layout, [clipData.asset_metadata.layout]);

  const visibleSecondaryPlayers = useMemo(
    () => secondaryPlayers.filter(player => player.current).sort((a, b) => a.current!.id.localeCompare(b.current!.id)),
    [secondaryPlayers]
  );

  // config used for video canvas
  const videoSizeConfig = useMemo(
    () => getSizeConfig(clipSize, clipId, clipLayout, 0.5),
    [clipSize, clipId, clipLayout]
  );

  const [captionContainerSizes, setCaptionContainerSizes] = useState({
    width: 0,
    height: 0
  });
  const { captionContainerRef } = useCaptionContainerRef(setCaptionContainerSizes, clipData.asset_metadata);

  const [captionFontSize, captionHorizontalSpace, captionsHeight] = useMemo(() => {
    const scale = clipData.asset_metadata.caption_styles?.scale || 1;
    const fontSize = getFontSize({ playerHeight: captionContainerSizes.height, scale, clipSize });

    return [
      fontSize,
      fontSize * CAPTION_HORIZONTAL_SPACE_FACTOR,
      ((videoSizeConfig.getVideoBottomPadding() - videoSizeConfig.getVideoSurroundPadding()) /
        videoSizeConfig.getHeight()) *
        captionContainerSizes.height
    ];
  }, [videoSizeConfig, captionContainerSizes, clipData.asset_metadata.caption_styles?.scale]);

  const backgroundStyle = useMemo(() => {
    if (!clipData.asset_metadata.magicLayout?.backgroundImage)
      return {
        backgroundColor: clipData.asset_metadata.magicLayout?.backgroundColor
      };
    return { backgroundImage: `url(${clipData.asset_metadata.magicLayout?.backgroundImage})` };
  }, [clipData.asset_metadata.magicLayout?.backgroundImage, clipData.asset_metadata.magicLayout?.backgroundColor]);

  return (
    <div
      className="absolute top-0 h-full w-full overflow-hidden rounded-md bg-cover bg-center"
      style={{
        ...backgroundStyle
      }}
      id="player-container"
    >
      <div className="relative grid h-full items-start " data-testid="composite-player" ref={captionContainerRef}>
        {/* Canvas for rendering the video elements on screen */}
        <VideoCanvas
          mainPlayer={mainPlayer}
          secondaryPlayers={visibleSecondaryPlayers}
          currentSpeaker={currentSpeaker}
          loading={loading}
          clipLayout={clipLayout}
        />

        {/* canvas for rendering elements that are more or less static throughout, eg: speaker labels */}
        <StaticCanvas
          mainPlayer={mainPlayer}
          secondaryPlayers={visibleSecondaryPlayers}
          currentSpeaker={currentSpeaker}
          key={currentSpeaker?.id}
          captionContainerSizes={captionContainerSizes}
          clipLayout={clipLayout}
        />

        {!isCaptionsOverlayDisabled && !!captionsHeight && (
          <CanvasPlayerDraggableCaptions
            clipMetadata={clipData.asset_metadata}
            clipId={clipId}
            captionFontSize={captionFontSize}
            isDisabled={!inlineEditEnabled || !!clipData.locked}
          />
        )}

        {showCaption &&
          captionHorizontalSpace >= 0 &&
          clipData.asset_metadata.layout !== 'AUDIOGRAM' &&
          isCaptionsOverlayDisabled && (
            <div className="absolute bottom-0 w-full">
              <div
                className="mx-auto flex h-full w-full max-w-[90%] items-center justify-center"
                style={{
                  fontSize: `${captionFontSize}px`,
                  height: `${captionsHeight}px`
                }}
              >
                <div
                  className="flex flex-wrap justify-center whitespace-normal text-center"
                  style={{ lineHeight: `${(5 * captionFontSize) / 4}px` }}
                >
                  <CanvasPlayerCaptions captionHorizontalSpace={captionHorizontalSpace} />
                </div>
              </div>
            </div>
          )}

        {clipData.asset_metadata.layout === 'AUDIOGRAM' && (
          <CanvasAudiogramCaptions
            videoSizeConfig={videoSizeConfig}
            captionContainerSizes={captionContainerSizes}
            captionFontSize={captionFontSize}
          />
        )}
      </div>
    </div>
  );
}
