import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClipLayout from './ClipLayout';
import ClipPlayerWrapper from './ClipPlayer/ClipPlayerWrapper';
import { Error } from '@/domains/global';
import { ClipsContextError } from '@/context/ClipsContext/ClipsContext';
import ErrorContainer, { ErrorContainerProps } from '@/App/ErrorBoundary/ErrorContainer';
import useClipNavigate from '@/hooks/useClipNavigate';

/**
 * Clip Page component that loads the sidebar along with the integrated player
 */
export default function ClipPage() {
  const { clipId, broadcastId, eventId } = useParams<{ broadcastId: string; eventId: string; clipId: string }>();

  const { navigateToEasyClipEditor } = useClipNavigate();

  const navigate = useNavigate();

  const handleTranscriptError = useCallback((err: Error) => {
    navigate(`/error/${err?.statusCode}`, { replace: true });
  }, []);

  useEffect(() => {
    navigateToEasyClipEditor(eventId!, broadcastId!, clipId!);
  }, [navigateToEasyClipEditor]);

  const renderErrorComponent = useCallback((error: ClipsContextError, clipId: string) => {
    let newErrorObject: ErrorContainerProps = {
      errorMessage: error.message,
      errorDisplayCode: error.statusCode
    };

    if (error?.statusCode === 404 || error?.statusCode === 401) {
      newErrorObject = { errorMessage: `Clip with ID "${clipId}" not found.`, errorDisplayCode: 404 };
    } else {
      newErrorObject = { errorMessage: 'Something went wrong on our end.' };
    }

    return (
      <ErrorContainer errorMessage={newErrorObject.errorMessage} errorDisplayCode={newErrorObject.errorDisplayCode} />
    );
  }, []);

  return (
    <ClipPlayerWrapper
      clipId={clipId}
      handleTranscriptError={handleTranscriptError}
      showLoaderOnPage
      renderErrorComponent={renderErrorComponent}
    >
      <ClipLayout />
    </ClipPlayerWrapper>
  );
}
