import {
  VoiceProfile,
  voiceProfileList,
  voiceProfileDestroy,
  voiceProfilePartialUpdate,
  voiceProfileCreate,
  ApplyVoice,
  voiceProfileGetDefaultOutputTextRetrieve,
  voiceProfileAnalyseVoiceCreate
} from '@goldcast/api/content';
import createStore from './store';
import { currentUser } from './user';
import { getEnvConfig } from '@/constants';
import { VoiceProfileSteps } from '@/Pages/BrandKit/components/BrandTone/constants';

export interface VoiceProfileStore {
  voiceProfiles: VoiceProfile[];
  activeStep: number;
  selectedVoiceProfile: VoiceProfile | null;
}

export const voiceProfileStore = createStore<VoiceProfileStore>({
  voiceProfiles: [],
  activeStep: 0,
  selectedVoiceProfile: null
});

export const loadVoiceProfileList = (): Promise<void> => {
  const user = currentUser.getSnapshot();
  return voiceProfileList({ queryParams: { limit: 100, offset: 0, organization: user?.organization as string } })
    .then(voiceProfiles => {
      voiceProfileStore.update(state => {
        state.voiceProfiles = voiceProfiles.results || [];
      });
    })
    .catch(err => Promise.reject(err));
};

export const deleteVoiceProfile = (voiceProfileId: string): Promise<void> => {
  return voiceProfileDestroy({ id: voiceProfileId })
    .then(() => {
      voiceProfileStore.update(state => {
        state.voiceProfiles = state.voiceProfiles.filter(voiceProfile => voiceProfile.id !== voiceProfileId);
      });
    })
    .catch(err => Promise.reject(err));
};

export const updateVoiceProfile = (profile): Promise<void> => {
  return voiceProfilePartialUpdate({ id: profile.id, body: profile.payload })
    .then(voiceProfile => {
      voiceProfileStore.update(state => {
        state.voiceProfiles = state.voiceProfiles.map(vp => (vp.id === voiceProfile.id ? voiceProfile : vp));
        state.selectedVoiceProfile = voiceProfile;
      });
    })
    .catch(err => Promise.reject(err));
};

export const createVoiceProfile = (voiceProfile): Promise<void> => {
  return voiceProfileCreate({ body: voiceProfile })
    .then(voiceProfile => {
      voiceProfileStore.update(state => {
        state.voiceProfiles = [...state.voiceProfiles, voiceProfile];
        state.selectedVoiceProfile = voiceProfile;
      });
    })
    .catch(err => Promise.reject(err));
};

export const updateActiveStep = (activeStep: number) => {
  voiceProfileStore.update(state => {
    state.activeStep = activeStep;
  });
};

export const updateSelectedVoiceProfile = (voiceProfile: VoiceProfile | null) => {
  voiceProfileStore.update(state => {
    state.selectedVoiceProfile = voiceProfile;
  });
};

export const resetSelectedVoiceProfile = () => {
  voiceProfileStore.update(state => {
    state.selectedVoiceProfile = null;
  });
};

export const analyseVoiceProfile = requestBody => {
  return voiceProfileAnalyseVoiceCreate({ body: requestBody })
    .then(voiceProfile => {
      voiceProfileStore.update(state => {
        state.selectedVoiceProfile = voiceProfile;
        state.voiceProfiles = [...state.voiceProfiles, voiceProfile];
        state.activeStep = VoiceProfileSteps.TuneVoiceProfile;
      });
    })
    .catch(err => Promise.reject(err));
};

export const getDefaultVoiceOutput = () => {
  return voiceProfileGetDefaultOutputTextRetrieve({})
    .then((voiceOutput: any) => {
      voiceProfileStore.update(state => {
        state.selectedVoiceProfile = {
          ...(state.selectedVoiceProfile as VoiceProfile),
          voice_output: voiceOutput?.message as string
        };
      });
    })
    .catch(err => {
      console.error('Failed to retrieve default voice output:', err);
    });
};

export function applyVoice(
  voicePayload: ApplyVoice,
  signal: AbortSignal,
  callback: (_res?: BufferSource) => void
): Promise<void> {
  return fetch(`${getEnvConfig('API_URL')}/text/apply_voice/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.accessToken}`
    },
    body: JSON.stringify(voicePayload),
    signal
  })
    .then(async response => {
      if (response.status >= 400) throw new Error('Failed to apply voice.');
      if (!response.body) return;

      const reader = response.body.getReader();

      try {
        let readResult = await reader.read();

        while (!readResult.done) {
          callback(readResult.value);
          readResult = await reader.read();
        }

        callback();
      } catch (readError) {
        console.error('Error reading the response stream:', readError);
        throw readError;
      }
    })
    .catch(err => {
      if (!(err instanceof DOMException)) {
        console.error('Error applying voice:', err);
        throw err;
      }
    });
}
