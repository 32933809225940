import React, { useMemo, memo, useCallback } from 'react';
import NewVoiceProfile from './NewVoiceProfile/NewVoiceProfile';
import TuneVoiceProfile from './TuneVoiceProfile';
import SaveVoiceProfile from './SaveVoiceProfile';
import { VoiceProfileSteps } from './constants';
import useVoiceProfile from '@/hooks/useVoiceProfile';
import { updateActiveStep } from '@/stores/voiceProfile';

const VoiceProfileStepRenderer = () => {
  const { activeStep } = useVoiceProfile();

  const closeDialog = useCallback(() => {
    updateActiveStep(0);
  }, []);

  const stepComponents = useMemo(() => {
    return {
      [VoiceProfileSteps.CreateVoiceProfile]: <NewVoiceProfile isOpen={true} closeDialog={closeDialog} />,
      [VoiceProfileSteps.TuneVoiceProfile]: <TuneVoiceProfile isOpen={true} closeDialog={closeDialog} />,
      [VoiceProfileSteps.SaveVoiceProfile]: <SaveVoiceProfile isOpen={true} closeDialog={closeDialog} />
    };
  }, [closeDialog]);

  const ActiveStepComponent = useMemo(() => stepComponents[activeStep] || null, [activeStep, stepComponents]);

  return ActiveStepComponent;
};

export default memo(VoiceProfileStepRenderer);
