import moment from 'moment';
import {
  HighlightedSearchWord,
  SearchParagraph,
  SearchParagraphWord,
  SearchResult,
  SpeakerRowDetails,
  TranscriptParagraph,
  TranscriptWordBounds
} from './types';
import { CLIP_MAX_DURATION_ERROR, CLIP_MAX_DURATION, CLIP_MIN_DURATION, CLIP_MIN_DURATION_ERROR } from './constants';
import { WordType, type Word } from '@/domains/transcript';
import { formatTime } from '@/libs/core';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';

export function getSpeakerRowDetails(word: Word, speakers: Record<string, SpeakerWithDetails>): SpeakerRowDetails {
  return {
    speaker_label: word.speaker_label,
    speaker: speakers[word.speaker_label],
    start: formatTime(word.start_time),
    end: formatTime(speakers[word.speaker_label].speakingSlots[word.start_time])
  };
}

export function getSelectionDuration(words: Word[]): number | string {
  if (!words.length) return 0;
  const { start_time } = words[0];
  const { end_time = start_time } = words.at(-1) || {};
  const duration = end_time - start_time;
  if (duration < CLIP_MIN_DURATION) return CLIP_MIN_DURATION_ERROR;
  if (duration > CLIP_MAX_DURATION) return CLIP_MAX_DURATION_ERROR;
  if (duration < 60) return `${duration.toFixed(2)} sec`;
  if (duration < 3600) {
    return moment.utc(duration * 1000).format('mm [min] ss [sec]');
  }
  return moment.utc(duration * 1000).format('H [hr] mm [min] ss [sec]');
}

export function getWordIdFromElement(element: HTMLElement): number {
  return parseInt(element.dataset.wordId as string);
}

export function getWordStartTimeFromElement(element: HTMLElement): number | null {
  try {
    const wordStartTime = parseFloat(element.dataset.wordStartTime as string);
    return wordStartTime;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getSelectionHighlightSpecs(anchorNode: Node, focusNode: Node): TranscriptWordBounds | null {
  if (!anchorNode?.parentElement || !focusNode?.parentElement) return null;

  const start = anchorNode.parentElement.dataset.wordId;
  const end = focusNode.parentElement.dataset.wordId;

  if (start && end) {
    const [startIndex, endIndex] = [+start, +end].sort((a, b) => (a > b ? 1 : -1));
    return { startIndex, endIndex };
  }

  return null;
}

export function isWordFollowedByBreak(index: number, transcript: Word[]): boolean {
  return transcript[index + 1] ? isLineBreak(transcript[index + 1]) : false;
}

let sentenceCount = 2;
let rollingWordCount = 0;

function isLineBreak(word: Word): boolean {
  if (word.type === WordType.Punctuation && word.content.match(/[.?!]/g) && rollingWordCount > 5) {
    sentenceCount--;
  }
  if (sentenceCount === 0) {
    sentenceCount = 2;
    rollingWordCount = 0;
    return true;
  }
  rollingWordCount++;
  return false;
}

export function getParagraphIndexByTime(time: number, paragraphs: TranscriptParagraph[]): number {
  for (let i = 0; i < paragraphs.length; i++) {
    if (!paragraphs[i].words) continue;
    const wordIndex = paragraphs[i].words.findIndex(w => w.start_time === time);
    if (wordIndex >= 0) {
      return i;
    }
  }
  return -1;
}

export function getHighligtedWordContent(foundWord: HighlightedSearchWord, content: string): string {
  let highlightedPart = content.substring(foundWord.highlightStart, foundWord.highlightEnd);
  return content.replace(
    highlightedPart,
    `<span class="bg-deep-orange-600 text-white">${
      foundWord.highlightSpace ? highlightedPart + ' ' : highlightedPart
    }</span>`
  );
}

export function convertParagraphToSearchParagraph(words: Word[], index: number): SearchParagraph {
  let content = '';
  let finalWords: Array<SearchParagraphWord> = [];
  let cursor = 0;
  words.forEach((w: Word) => {
    content += w.content + ' ';
    finalWords.push({
      content: w.content,
      startIndex: cursor,
      endIndex: cursor + w.content.length,
      start_time: w.start_time
    });
    cursor += w.content.length + 1;
  });

  return { content, words: finalWords, index };
}

export function getParagraphSearchResults(paragraph: SearchParagraph, query: string): SearchResult[] {
  // replace regex special characters
  const regExpQuery = query.toLowerCase().replace(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, '\\$&');
  const matches = [...paragraph.content.toLowerCase().matchAll(new RegExp(regExpQuery, 'g'))];
  if (!matches.length) return [];
  return matches.map((match: RegExpMatchArray) => {
    return {
      index: paragraph.index,
      start: match.index,
      end: (match.index || 0) + query.length,
      paragraph: paragraph.content,
      words: paragraph.words
    };
  }) as SearchResult[];
}

export function parseTranscriptToParagraphs(
  transcript: Word[],
  speakersWithDetails: Record<string, SpeakerWithDetails>
): TranscriptParagraph[] {
  const allParagraphs: TranscriptParagraph[] = [];
  let currentParagraphWords: Word[] = [];
  let currentSpeaker: SpeakerRowDetails;
  transcript.forEach((word, index) => {
    // Add new speaker row
    if (index === 0 || word.speaker_label !== currentSpeaker?.speaker_label) {
      // Next speaker, save current paragraph
      if (currentParagraphWords.length) {
        allParagraphs.push({ words: currentParagraphWords });
        currentParagraphWords = [];
      }
      currentSpeaker = getSpeakerRowDetails(word, speakersWithDetails);
      allParagraphs.push({ speakerDetails: currentSpeaker, words: [] });
    }
    // Add words to the current paragraph
    const isBreakFollowing = isWordFollowedByBreak(index, transcript);
    if (word.type !== WordType.Punctuation) {
      currentParagraphWords.push({ ...parseWord(transcript, word, index), index });
      if (isBreakFollowing || index === transcript.length - 1) {
        allParagraphs.push({ words: currentParagraphWords });
        currentParagraphWords = [];
      }
    }
  });
  // If there is remaining paragraph that is not ending with punctuation
  if (currentParagraphWords.length) {
    allParagraphs.push({ words: currentParagraphWords });
  }
  return allParagraphs;
}

function parseWord(transcript: Word[], word: Word, index: number): Word {
  let { content } = word;
  if (transcript[index + 1]?.type === WordType.Punctuation) {
    content += transcript[index + 1].content;
  }
  return {
    ...word,
    content
  };
}
