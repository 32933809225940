import isequal from 'lodash.isequal';
import { RecordingDetails, SetupContentStep } from '../types';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getSpeakerImage } from '@/libs/utils';

export function getSpeakerInfoBackgroundImage(speakerInfo: SpeakerWithDetails): { backgroundImage?: string } {
  if (speakerInfo.profile_picture_url) {
    return {
      backgroundImage: `url(${
        speakerInfo.id ? getSpeakerImage(speakerInfo.profile_picture_url) : speakerInfo.profile_picture_url
      })`
    };
  }
  return {};
}

export function isStepInvalid(speakerValue: SpeakerWithDetails | undefined): boolean {
  if (!speakerValue) return false;
  return !speakerValue?.first_name?.trim();
}

export function areRecordingDetailsInvalid(recordingDetails: RecordingDetails): boolean {
  return !recordingDetails.title?.trim() || !recordingDetails.recording_date;
}

export function isActiveStep(currentStep: SetupContentStep, renderingStep: SetupContentStep, title: string): boolean {
  return currentStep.title === title && renderingStep.title === currentStep.title;
}

export function getLongestSpeakerSlotStart(speaker: SpeakerWithDetails): string {
  const startTimes = Object.keys(speaker.speakingSlots);
  if (!startTimes.length) return '0';
  const firstSpeakingSlot = startTimes[0];
  let longest = speaker.speakingSlots[firstSpeakingSlot] - parseFloat(firstSpeakingSlot);
  let start = firstSpeakingSlot;
  for (let startTime in speaker.speakingSlots) {
    const duration = speaker.speakingSlots[startTime] - parseFloat(startTime);
    if (duration > longest) {
      longest = duration;
      start = startTime;
    }
  }
  return start;
}

export function getInitialSpeakerValues(steps: SetupContentStep[]): Record<string, SpeakerWithDetails> {
  return steps.reduce((acc, step) => {
    if (step.speakerData) {
      acc[step.key] = step.speakerData;
    }
    return acc;
  }, {});
}

export function areSpeakerDetailsChanged(
  currentSpeakerValues: Record<string, SpeakerWithDetails>,
  initialSpeakerValues: Record<string, SpeakerWithDetails>
): boolean {
  const speakerValues = Object.values(currentSpeakerValues);
  for (let speaker of speakerValues) {
    if (!isequal(speaker, initialSpeakerValues[speaker.key])) {
      return true;
    }
  }
  return false;
}
