import { useState } from 'react';
import ClipCarousel from './ClipCarousel';
import ActionButtons, { ActionButton } from '@/components/atoms/ActionButtons/ActionButtons';
import useElementHoveredHook from '@/hooks/useElementHoveredHook';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import useDownloadAsset from '@/hooks/useDownloadAsset';
import useAnalytics from '@/hooks/useAnalytics';
import ClipPlayer from '@/Pages/Clip/ClipPlayer/ClipPlayer';
import { handleClipLockedError } from '@/Pages/Clip/utils';
import useClipNavigate from '@/hooks/useClipNavigate';

export default function PostClipWrapper({
  openReplaceClipDialog,
  onRemoveClip,
  onRegenerateClip,
  activeClip,
  clipAssetIds,
  handleActiveClipChange
}: {
  openReplaceClipDialog: () => void;
  onRegenerateClip: () => Promise<void>;
  onRemoveClip: () => Promise<void>;
  activeClip: number;
  clipAssetIds: string[];
  handleActiveClipChange: (index: number) => void;
}) {
  const { isHovered, handleOnMouseEnter, handleOnMouseLeave } = useElementHoveredHook();

  const { clipData } = useClipsContext();

  const { onDownloadClip } = useDownloadAsset({ type: 'CLIP', clip: clipData.asset_metadata ? clipData : undefined });

  const { trackContentLabUsage } = useAnalytics();

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const { navigateToClip } = useClipNavigate();

  const buttons: ActionButton[] = [
    {
      action: () => {
        if (clipData?.locked) {
          handleClipLockedError(clipData);
          return;
        }
        trackContentLabUsage({
          asset: clipData
        });
        navigateToClip(clipData, { downloadIntent: false });
      },
      icon: 'IconPencil',
      label: 'Edit Clip',
      trackingId: 'edit-clip-button',
      disabled: buttonsDisabled
    },
    {
      action: () => {
        setButtonsDisabled(true);
        onDownloadClip().finally(() => {
          setButtonsDisabled(false);
        });
      },
      icon: 'IconDownload',
      label: 'Download',
      trackingId: 'download-clip-button',
      disabled: buttonsDisabled
    },
    {
      action: () => {
        openReplaceClipDialog();
      },
      icon: 'IconReplace',
      label: 'Replace Clip',
      trackingId: 'replace-clip-button',
      disabled: buttonsDisabled
    },
    {
      action: () => {
        setButtonsDisabled(true);
        onRegenerateClip().finally(() => {
          setButtonsDisabled(false);
        });
      },
      icon: 'IconSparkles',
      label: 'Regenerate',
      trackingId: 'regenerate-clip-button',
      disabled: buttonsDisabled
    },
    {
      action: () => {
        setButtonsDisabled(true);
        onRemoveClip().finally(() => {
          setButtonsDisabled(false);
        });
      },
      icon: 'IconTrash',
      label: 'Remove',
      trackingId: 'remove-clip-button',
      disabled: buttonsDisabled
    }
  ];

  return (
    <div className="relative" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <div className="h-[550px] min-h-[550px] w-full">
        {clipAssetIds.length > 1 && (
          <ClipCarousel
            clips={clipAssetIds}
            activeClipIndex={activeClip}
            handleActiveClipChange={handleActiveClipChange}
          />
        )}
        <div className="absolute -right-16 z-30 flex h-full items-center">
          <ActionButtons show={isHovered} buttons={buttons} labelPosition="left" size="base" />
        </div>
        <ClipPlayer />
      </div>
    </div>
  );
}
