import React, { useEffect, useState } from 'react';
import Hls from 'hls.js';
import Loader from '@/components/atoms/Loader';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function HlsPlayerV2({
  videoRef,
  url,
  trackSrc = '',
  id = '',
  videoProps,
  onMediaAttached,
  onLoaded,
  onError
}: {
  url: string;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  trackSrc?: string;
  id?: string;
  videoProps?: React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;
  onMediaAttached?: () => void;
  onLoaded?: () => void;
  onError?: () => void;
}) {
  const { logger } = useAppContext();

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    let hls: Hls;

    if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      // Handle iOS Playback. HLS is supported natively on iOS and HLS.js will not work
      videoRef.current.setAttribute('playsinline', '');
      videoRef.current.src = url;
      videoRef.current?.load();
      onMediaAttached?.();
      videoRef.current.addEventListener('error', () => {
        onError?.();
      });
      videoRef.current.textTracks[0].mode = 'showing';
    } else if (Hls.isSupported()) {
      hls = new Hls({
        maxBufferLength: 30,
        maxBufferSize: 5
      });
      hls.loadSource(url);
      hls.attachMedia(videoRef.current);
      hls.subtitleDisplay = false;
      hls.once(Hls.Events.MEDIA_ATTACHED, () => {
        onMediaAttached?.();
      });
      hls.on(Hls.Events.ERROR, (_, data) => {
        // don't error out for non-fatal error (like segment load)
        // If it's not fatal, hls would retry it
        // Check if manifest - don't block UI if frament load failed
        if (data.fatal && data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) onError?.();
        else if (data?.fatal && data?.details === Hls.ErrorDetails.FRAG_LOAD_ERROR) {
          showErrorToast(
            `We hit a snag loading part of your video. Let's try refreshing the page to get everything back in sync.`
          );
        }
        logger.warn('HLS Error', JSON.stringify(data));
      });
      videoRef.current.textTracks[0].mode = 'showing';
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isLoaded, setIsLoaded] = useState(false);

  function handleVideoLoaded() {
    setIsLoaded(true);
    onLoaded?.();
  }

  return (
    <>
      <video
        crossOrigin="anonymous"
        id={id}
        hidden={!isLoaded}
        ref={videoRef}
        {...videoProps}
        onCanPlayThrough={handleVideoLoaded}
      >
        <track default={true} kind="metadata" srcLang="en" src={trackSrc} />
      </video>
      {!isLoaded && (
        <div className="absolute inset-1/2">
          <Loader />
        </div>
      )}
    </>
  );
}
