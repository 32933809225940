import { PropsWithChildren, memo } from 'react';
import { ClipsContextError, ClipsContextProvider, useClipsContext } from '../../../context/ClipsContext/ClipsContext';
import { TranscriptContextProvider } from '../../../context/TranscriptContext/TranscriptContext';
import { SpeakerSegmentContextProvider } from '../../../context/SpeakerSegmentContext/SpeakerSegmentContext';
import { Error } from '@/domains/global';
import ErrorBoundaryForComponent from '@/App/ErrorBoundary/ErrorBoundaryForComponent';

interface ClipPlayerWrapperProps extends PropsWithChildren {
  clipId?: string;
  shared?: boolean;
  showLoaderOnPage?: boolean;
  handleTranscriptError: (_err: Error) => void;
  renderErrorComponent?: (error: ClipsContextError, clipId: string) => JSX.Element;
}

const ClipPlayerWrapper: React.FC<ClipPlayerWrapperProps> = memo(
  ({ clipId, shared, showLoaderOnPage, children, handleTranscriptError, renderErrorComponent }) => {
    return (
      <ErrorBoundaryForComponent customError="Failed to load clip! Please try again after some time.">
        <ClipsContextProvider
          clipId={clipId}
          shared={shared}
          showLoaderOnPage={showLoaderOnPage}
          renderErrorComponent={renderErrorComponent}
        >
          <ClipPlayerWrapperInternal handleTranscriptError={handleTranscriptError}>
            {children}
          </ClipPlayerWrapperInternal>
        </ClipsContextProvider>
      </ErrorBoundaryForComponent>
    );
  }
);

const ClipPlayerWrapperInternal: React.FC<
  Omit<ClipPlayerWrapperProps, 'clipId' | 'shared' | 'showLoaderOnPage' | 'renderErrorComponent'>
> = memo(({ children, handleTranscriptError }) => {
  const { clipData } = useClipsContext();

  return (
    <TranscriptContextProvider
      clip_start_time={clipData.asset_metadata.start}
      clip_end_time={clipData.asset_metadata.end}
      broadcastId={clipData.broadcast}
      eventId={clipData.event}
      onErrorCallback={handleTranscriptError}
    >
      <SpeakerSegmentContextProvider>{children}</SpeakerSegmentContextProvider>
    </TranscriptContextProvider>
  );
});

export default ClipPlayerWrapper;
