import { memo, useCallback } from 'react';
import { IconSparkles } from '@tabler/icons-react';
import VoiceProfilePreview from './VoiceProfilePreview';
import VoiceProfileStepRenderer from './VoiceProfileStepRenderer';
import { VoiceProfileSteps } from './constants';
import { classnames } from '@/libs/utils';
import useVoiceProfile from '@/hooks/useVoiceProfile';
import { updateActiveStep } from '@/stores/voiceProfile';
import styles from '../../BrandKit.module.scss';

function BrandTone(): JSX.Element {
  const { voiceProfiles } = useVoiceProfile();

  const onAddToneOfVoice = useCallback(() => {
    updateActiveStep(VoiceProfileSteps.CreateVoiceProfile);
  }, []);

  return (
    <div className="relative flex flex-wrap gap-3 py-2">
      <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center !text-slate-500">
        <div className="h-full w-full transition-all duration-300 ease-in-out">
          <div
            className={classnames(
              'relative flex h-full flex-col items-center justify-center !rounded-xl border border-slate-300 p-4 transition-all duration-150 ease-in-out hover:cursor-pointer hover:transition-all hover:duration-150 hover:ease-in-out',
              styles['brand-picker']
            )}
            onClick={onAddToneOfVoice}
          >
            <IconSparkles size={24} />
            <p className="mt-1 w-32 text-center text-xs">Add Tone of Voice</p>
          </div>
        </div>
      </div>
      <VoiceProfileStepRenderer />
      {voiceProfiles.map(voiceProfile => (
        <VoiceProfilePreview key={voiceProfile.id} voiceProfile={voiceProfile} />
      ))}
    </div>
  );
}

export default memo(BrandTone);
