import { useEffect, useState, useSyncExternalStore } from 'react';
import { brandTemplateCreate, brandTemplateTemplateTagsRetrieve, brandTemplateUpdate } from '@goldcast/api/content';
import TemplatePreview from '../Templates/TemplatePreview';
import { getSaveTemplatePayload, mapCurrentClipToTemplate } from '../Templates/utils';
import { PreviewTemplate } from '../types';
import TagsInput from '@/components/atoms/TagsInput';
import LabeledInput from '@/components/atoms/LabeledInput';
import Button from '@/components/atoms/Button/Button';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import Loader from '@/components/atoms/Loader';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function NewTemplateModal({
  templateToEdit = null,
  onSave
}: {
  onSave: () => void;
  templateToEdit?: PreviewTemplate | null;
}) {
  const { clipId } = useClipsContext();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isEasyClipCustomizerEnabled = featureFlags[FeatureFlagKeys.Use_CL_Easy_Clip_Customizer];

  const template = mapCurrentClipToTemplate(clipId);
  const [selectedTemplateTags, setSelectedTemplateTags] = useState<string[]>(templateToEdit?.tags || []);
  const [templateTitle, setTemplateTitle] = useState<string>(templateToEdit?.title || '');
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [existingTemplatesTags, setExistingTemplatesTags] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const { logger } = useAppContext();

  function onTagsChange(templateTags: string[]) {
    setSelectedTemplateTags(templateTags);
  }

  useEffect(() => {
    brandTemplateTemplateTagsRetrieve({}).then(res => {
      setExistingTemplatesTags(res.tags || []);
    });
  }, []);

  function save() {
    if (!templateTitle) {
      setIsNameInvalid(true);
      return;
    }
    setIsSaving(true);
    !!templateToEdit ? updateTemplate(templateToEdit) : storeTemplate();
  }

  function storeTemplate() {
    brandTemplateCreate({
      body: getSaveTemplatePayload({ ...template, title: templateTitle }, selectedTemplateTags)
    })
      .then(() => onSaveCompleted())
      .catch(err => {
        logger.error(err);
        showErrorToast('Uh-oh! We encountered an issue saving your template. Please check your inputs and try again.');
      });
  }

  function updateTemplate(templateToEdit: PreviewTemplate) {
    brandTemplateUpdate({
      id: templateToEdit.id as string,
      body: getSaveTemplatePayload({ ...templateToEdit, title: templateTitle }, selectedTemplateTags)
    })
      .then(() => onSaveCompleted())
      .catch(err => {
        logger.error(err);
        showErrorToast(
          'Looks like updating the template hit a snag. Double-check your changes and give it another whirl.'
        );
      });
  }

  function onSaveCompleted() {
    onSave();
    EventBus.dispatch(CustomEvents.ReloadTemplates);
  }

  return (
    <div className="h-full">
      <div className="h-full overflow-y-scroll">
        <div className="flex h-full flex-col">
          <div className="relative">
            <div className="mt-4 px-4">
              <TemplatePreview template={{ ...template }} canSelect={false} classNames="p-10" />
            </div>
            <div className="mt-4 px-4">
              <LabeledInput
                label="Template Name"
                classNames="w-full pb-2 p-px"
                value={templateTitle}
                onChange={setTemplateTitle}
                data-testid="template-first-name"
                isRequired={true}
                maxLength={100}
                isInvalid={isNameInvalid}
              />

              {!isEasyClipCustomizerEnabled && (
                <>
                  <div className="text-xs">Tags</div>
                  <TagsInput
                    existingTags={existingTemplatesTags}
                    preSelectedTags={selectedTemplateTags}
                    onChange={onTagsChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className="mt-auto flex flex-row justify-end border-t">
            <Button
              disabled={isSaving}
              onClick={save}
              variation="filled"
              className="m-2"
              trackingId="save-template-button"
            >
              {isSaving && <Loader size="xs" />}
              Save Template
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
