import { SizeConfig } from './SizeConfig';
import { CaptionAttributes, PillBoxSizes } from './types';
import { getCanvasTextDimensions } from './util';
import { SQUARE_DEFAULT_LAYOUT_VIDEO_BOTTOM_PADDING } from './constants';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { LayoutType, SizeType } from '@/domains/asset';
import { loadFont } from '@/libs/fonts';
import { getSpeakerNameAndTitle } from '@/Pages/Clip/CompositePlayer/SpeakerVideoUtils';
import { getFontByName } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { getCurrentClipFontName } from '@/stores/brandKit';

export class SquareSizeConfig extends SizeConfig {
  protected type = 'SQUARE' as SizeType;

  protected VIDEO_SURROUND_PADDING: number = 16;
  protected VIDEO_BOTTOM_PADDING: number = 210;

  protected CAPTIONS_LEFT_MARGIN = 20;
  protected CAPTIONS_RIGHT_MARGIN = 20;
  // This should be same as the LandscapeSizeConfig bottom margin
  protected CAPTIONS_BOTTOM_MARGIN = 70;
  protected CAPTIONS_FONT_SIZE = 29;

  protected AUDIOGRAM_DIMENSIONS = {
    IMG_DIMENSIONS_RATIO: {
      width: 0.87,
      height: 0.835
    },
    WAVEFORM_HEIGHT_MULTIPLIER: 0.075,
    WAVEFORM_POSITION: {
      x: 0.8925,
      y: 0.88
    },
    SPEAKER_LABEL_WIDTH_MULTIPLIER: 0.6
  };

  constructor(clipId, clipLayout: LayoutType, devicePixelRatio?: number) {
    super(1080, 1080, clipId, clipLayout, devicePixelRatio);
    if (clipLayout === 'DEFAULT') {
      this.CAPTIONS_BOTTOM_MARGIN = 132;
    }
  }

  getSizeGridPositions(
    totalVideos: number,
    currentVideoNumber: number,
    target_crop_x: number,
    target_crop_y: number,
    target_crop_width: number,
    target_crop_height: number
  ) {
    const videoSurroundPadding = this.getVideoSurroundPadding();
    target_crop_height = (target_crop_height - videoSurroundPadding) / 2;

    if (totalVideos > 2) {
      target_crop_width = (target_crop_width - videoSurroundPadding) / 2;
    }

    if (totalVideos === 2 && currentVideoNumber === 2) {
      target_crop_y = target_crop_height + videoSurroundPadding * 2;
    } else if (totalVideos === 3) {
      if (currentVideoNumber === 2) {
        target_crop_x = target_crop_width + videoSurroundPadding * 2;
      } else if (currentVideoNumber === 3) {
        target_crop_x = target_crop_width + videoSurroundPadding * 2 - target_crop_width / 2;
        target_crop_y = target_crop_height + videoSurroundPadding * 2;
      }
    } else if (totalVideos === 4) {
      if (currentVideoNumber % 2 === 0) {
        target_crop_x = target_crop_width + videoSurroundPadding * 2;
      }
      if (currentVideoNumber > 2) {
        target_crop_y = target_crop_height + videoSurroundPadding * 2;
      }
    }

    return { target_crop_x, target_crop_y, target_crop_width, target_crop_height };
  }

  getVideoBottomPadding(options?: { shouldUseDefaultLayoutValues: boolean }): number {
    const { shouldUseDefaultLayoutValues } = options || {};

    if (this.clipLayout === 'DEFAULT' || shouldUseDefaultLayoutValues) {
      return SQUARE_DEFAULT_LAYOUT_VIDEO_BOTTOM_PADDING * this.devicePixelRatio;
    }

    return this.VIDEO_BOTTOM_PADDING * this.devicePixelRatio;
  }

  getPillMaxWidth(totalNumber: number) {
    return (
      ((this.getWidth() - this.getVideoSurroundPadding() * 2) * this.PILL_MAX_WIDTH_RATIO) / (totalNumber > 2 ? 2 : 1)
    );
  }

  getPillBoxSizes(
    currentVideoNumber: number,
    totalNumber: number,
    canvasPillWidth: number,
    isTruncated: boolean
  ): PillBoxSizes {
    let newPillX = 0;
    let newPillHeight = 0;
    if (totalNumber === 1 && currentVideoNumber === 1) {
      newPillX = 16;
      newPillHeight = 100;
    } else if (totalNumber === 2) {
      newPillX = 10;
      newPillHeight = 76;
    } else if (totalNumber === 3) {
      newPillX = 12;
      newPillHeight = 76;
    } else if (totalNumber === 4) {
      newPillX = 12;
      newPillHeight = 76;
    }

    const pillHeight = newPillHeight * this.devicePixelRatio;

    return {
      pillX: newPillX * this.devicePixelRatio,
      pillWidth: canvasPillWidth + pillHeight / (isTruncated ? 1.5 : 1),
      pillHeight
    };
  }

  getSpeakerNameTitlePillTextSizes(
    totalNumber: number,
    correctionFactor: number = 1
  ): {
    nameSize: number;
    titleSize: number;
  } {
    let nameSize = 28;
    let titleSize = 22;

    if (totalNumber >= 2) {
      nameSize = 20;
      titleSize = 16;
    }

    return {
      nameSize: nameSize * this.devicePixelRatio * correctionFactor,
      titleSize: titleSize * this.devicePixelRatio * correctionFactor
    };
  }

  getAudiogramWaveformAttributes({ height, width }) {
    return {
      top: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.y * width,
      left: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_POSITION.x * width,
      waveFormHeight: this.AUDIOGRAM_DIMENSIONS.WAVEFORM_HEIGHT_MULTIPLIER * height
    };
  }

  drawAudiogramSpeakerLabel(
    canvasContext: CanvasRenderingContext2D,
    speaker: SpeakerWithDetails,
    textColor: string
  ): void {
    const { name, title } = getSpeakerNameAndTitle(speaker);

    // Return and do not draw in case none of the fields are available
    if (!name && !title) return;

    const labelWidth = this.getWidth() * this.AUDIOGRAM_DIMENSIONS.SPEAKER_LABEL_WIDTH_MULTIPLIER;

    const fontName = getCurrentClipFontName(this.clipId);

    const nameSize = 64;
    const titleSize = 56;

    const {
      newTitle,
      newName,
      width: maxWidth
    } = getCanvasTextDimensions({
      maxWidth: labelWidth,
      name,
      title,
      nameFont: `${nameSize}px ${fontName || 'Inter'}`,
      titleFont: `${titleSize}px ${fontName || 'Inter'}`,
      canvasHeight: this.getHeight(),
      canvasWidth: this.getWidth()
    });

    const nameX = 0.94 * this.getWidth();
    const titleX = 0.91 * this.getWidth();
    const nameY = 0.025 * this.getHeight();

    const fontItem = getFontByName(fontName as string);
    const drawContent = () => {
      canvasContext.save();
      canvasContext.translate(nameX, nameY);
      canvasContext.rotate((1 * Math.PI) / 2);

      this.drawText(canvasContext, newName, 0, 0, maxWidth, `${nameSize}px ${fontName || 'Inter'}`, textColor, 1);

      canvasContext.restore();

      canvasContext.save();
      canvasContext.translate(titleX, nameY);
      canvasContext.rotate((1 * Math.PI) / 2);

      this.drawText(canvasContext, newTitle, 0, 0, maxWidth, `${titleSize}px ${fontName || 'Inter'}`, textColor, 0.6);
      canvasContext.restore();
    };
    if (fontItem) {
      loadFont(fontItem.url, fontItem.name).then(() => {
        drawContent();
      });
    } else {
      drawContent();
    }
  }

  getCaptionsFontSize(): number {
    return this.CAPTIONS_FONT_SIZE;
  }

  getCaptionAttributes({
    containerHeight,
    containerWidth,
    captionFontSize
  }: {
    containerHeight: number;
    containerWidth: number;
    captionFontSize: number;
  }): CaptionAttributes {
    const top = this.AUDIOGRAM_DIMENSIONS.IMG_DIMENSIONS_RATIO.height * containerHeight + 0.03 * containerHeight;
    const left = 0.03 * containerHeight;
    const right = 0.03 * containerHeight + (1 - this.AUDIOGRAM_DIMENSIONS.IMG_DIMENSIONS_RATIO.width) * containerWidth;
    const fontSize = `${captionFontSize * 1}px`;
    const lineHeight = `${(9 * captionFontSize) / 8}px`;

    return { top, left, right, fontSize, lineHeight, rotation: 0, wrap_style: 1, alignment: 7 };
  }
}
