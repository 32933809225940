import { POST_TYPE_ICONS, POST_TYPE_LABELS } from '../constants';
import PostAssetGenerationEmptyState from './PostAssetGenerationEmptyState';
import Icon from '@/components/atoms/Icon';

export default function PostHelpText({
  postType,
  isGeneratingAssets
}: {
  postType: string;
  isGeneratingAssets: boolean;
}) {
  if (isGeneratingAssets) {
    return <PostAssetGenerationEmptyState postType={postType} />;
  }

  return (
    <div className="flex h-full grow items-center justify-center overflow-y-auto border-r border-r-slate-200 px-10 pb-16 pt-8">
      <div className="flex flex-col items-center">
        <div>
          <Icon name={POST_TYPE_ICONS[postType]} className="h-10 w-10 text-slate-400" />
        </div>
        <div className="text-center">
          <div className="mt-2 font-medium text-slate-400">Nothing here, yet.</div>
          <div className="mt-2 max-w-[11rem] text-sm leading-tight text-slate-400">
            Create your first {POST_TYPE_LABELS[postType]} post to see it here.
          </div>
        </div>
      </div>
    </div>
  );
}
