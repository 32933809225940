import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore
} from 'react';
import { Transition } from '@headlessui/react';
import { getSpeakerRowDetails, getWordIdFromElement } from '../utils';
import ClipTranscriptWord from './ClipTranscriptWord';
import { setupCopyListeners } from './ClipTranscriptUtils';
import useTrimTranscript from './useTrimTranscript';
import { SpeakerWithMonologue } from './ClipTranscriptTypes';
import { ClipTranscriptMode, TRIM_POSITION_OFFSET } from '../constants';
import SpeakerRow from '../SpeakerRow';
import { HighlightedSearchParagraph } from '../types';
import TranscriptSearch from '../TranscriptSearch';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { Word, WordType } from '@/domains/transcript';
import { TranscriptContextType } from '@/context/TranscriptContext/TranscriptContextTypes';
import { isEmptyObject } from '@/libs/utils';
import TranscriptEditPanel from '@/Pages/Clip/SideBar/TranscriptSideBar/TranscriptEditPanel';
import { useAppContext } from '@/context/AppContext/AppContext';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { SidebarContext } from '@/Pages/Clip/SideBar/SideBarUtils';
import { Clip } from '@/domains/asset';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { playerV2 } from '@/stores/playerV2';
import IconButton from '@/components/atoms/Button/IconButton';
import SideBarBreadcrumb from '@/Pages/Clip/SideBar/SideBarBreadcrumb';

export default function ClipTranscript({
  setSideBarContext,
  title,
  sideBarContext,
  clip,
  isEasyClipCustomizer = false
}: {
  setSideBarContext: (context: SidebarContext) => void;
  title: string;
  sideBarContext: SidebarContext;
  clip?: Clip;
  isEasyClipCustomizer?: boolean;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { clipData: contextClip } = useClipsContext();
  const clipData = useMemo(() => {
    return clip || contextClip;
  }, [clip, contextClip]);
  const wordsCountRef = useRef(0);
  // not using from clipsContext since we are using in edit transcript in clips list without context
  const playerStoreForAllClips = useSyncExternalStore(playerV2.subscribe, playerV2.getSnapshot);
  const playerStore = playerStoreForAllClips[clipData.id!];
  const [highlightedMonologue, setHighlightedMonologue] = useState<HighlightedSearchParagraph | null>(null);

  const transcriptStore = useTranscriptContext() as TranscriptContextType;

  const mode = useMemo(() => {
    return sideBarContext === SidebarContext.EditCaptions
      ? ClipTranscriptMode.EditCaptions
      : ClipTranscriptMode.EditDuration;
  }, [sideBarContext]);

  const transcript = useMemo(() => {
    const captions = clipData.asset_metadata.captions || [];
    if (transcriptStore.trimming) {
      const clipStartTime = clipData.asset_metadata.start;
      const clipEndTime = clipData.asset_metadata.end;

      const modifiedStartTime = clipStartTime - TRIM_POSITION_OFFSET;
      const modifiedEndTime = clipEndTime + TRIM_POSITION_OFFSET;

      const startedTranscript = transcriptStore.transcript.filter(word => {
        if (word.start_time >= modifiedStartTime && word.start_time < clipStartTime) return word;
      });

      const endedTranscript = transcriptStore.transcript.filter(word => {
        if (word.start_time > clipEndTime && word.start_time <= modifiedEndTime) return word;
      });

      return [...startedTranscript, ...captions, ...endedTranscript];
    }
    return captions;
  }, [clipData?.asset_metadata?.captions, transcriptStore.trimming, transcriptStore.transcript]);

  const speakerWithMonologue = useMemo(() => {
    if (isEmptyObject(transcriptStore?.speakersWithDetails || {})) return [];
    const finalData: SpeakerWithMonologue[] = [];
    wordsCountRef.current = 0;
    let currentSpeaker = '';
    let speakerIndex = -1;
    transcript.forEach(word => {
      if (currentSpeaker !== word.speaker_label) {
        speakerIndex++;
        currentSpeaker = word.speaker_label;
        finalData[speakerIndex] = {
          speakerDetails: getSpeakerRowDetails(word, transcriptStore?.speakersWithDetails),
          monologue: []
        };
      }

      if (word.type !== WordType.Punctuation) {
        finalData[speakerIndex].monologue.push(word);
        wordsCountRef.current++;
      }
    });

    return finalData.filter(data => !!data.monologue.length);
  }, [transcript, clipData, transcriptStore]);

  const handleEditSpeaker = useCallback(speakerId => {
    EventBus.dispatch(CustomEvents.OpenSessionConfig, { speakerId });
  }, []);

  const {
    transcriptTrimmingRef,
    startPositions,
    endPositions,
    onDragOver,
    onStartCursorDrag,
    onEndCursorDrag,
    onCursorDragEnd,
    highlightStartTime,
    highlightEndTime,
    onTrimToggle,
    saveTrimChanges
  } = useTrimTranscript(
    containerRef,
    speakerWithMonologue,
    clipData.asset_metadata.start,
    clipData.asset_metadata.end,
    clip
  );

  const isEditDurationMode = useMemo(() => {
    return mode === ClipTranscriptMode.EditDuration;
  }, [mode]);

  useEffect(() => {
    // Set trim active automatically if in editDuration mode
    if (isEditDurationMode && !transcriptStore.trimming) {
      onTrimToggle(true);
    } else if (!isEditDurationMode && isEasyClipCustomizer) {
      onTrimToggle(false);
    }
  }, [isEditDurationMode, transcriptStore.trimming]);

  // In case of duration, save changes, and keep trimming mode on
  function onDragEnd() {
    isEditDurationMode ? saveTrimChanges() : onCursorDragEnd();
  }

  const [firstClickedWord, setFirstClickedWord] = useState<Word | null>(null);
  const [selectedWords, setSelectedWords] = useState<Word[]>([]);
  const [isSelecting, setIsSelecting] = useState(false);

  const handlePointerUp = useCallback(() => {
    if (!isSelecting) {
      setSelectedWords([]);
      return;
    }

    setIsSelecting(false);
    if (transcriptTrimmingRef.current || !selectedWords.length) return;
    let nextWord = transcript[selectedWords.at(-1)?.index! + 1];
    if (nextWord?.type === WordType.Punctuation) {
      selectedWords.push(nextWord);
    }
    setSelectedWords(selectedWords);
  }, [isSelecting, transcriptTrimmingRef, selectedWords, transcript, clipData.asset_metadata]);

  const pointerDown: MouseEventHandler = e => {
    if (isEditDurationMode) return;
    const index = getWordIdFromElement(e.target as HTMLElement);
    if (Number.isNaN(index)) return;
    setSelectedWords([transcript[index]]);
    setFirstClickedWord(transcript[index]);
    setIsSelecting(true);
  };

  const selectClickedWords: MouseEventHandler = e => {
    if (!isSelecting || !selectedWords.length || !firstClickedWord) {
      return;
    }

    const index = getWordIdFromElement(e.target as HTMLElement);

    if (Number.isNaN(index)) {
      return;
    }

    const [startIndex, endIndex] = [firstClickedWord.index, transcript[index].index].sort((a, b) => a - b);
    setSelectedWords(transcript.slice(startIndex, endIndex + 1));
  };

  const selectedWordString = useMemo(
    () =>
      selectedWords
        ?.map(word => (word.type === WordType.Punctuation ? word.content : ` ${word.content}`))
        .join('')
        .trim(),
    [selectedWords]
  );

  const { logger } = useAppContext();
  useEffect(() => {
    document.addEventListener('pointerup', handlePointerUp);
    const removeListener = setupCopyListeners(selectedWordString, logger);

    return () => {
      document.removeEventListener('pointerup', handlePointerUp);
      removeListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWords, transcriptTrimmingRef, transcript, clipData.asset_metadata.edits, isSelecting]);

  useEffect(() => {
    setInputValue(selectedWordString);

    if (inputCorrectionRef.current) {
      inputCorrectionRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWordString]);

  const [inputValue, setInputValue] = useState<string>(selectedWordString || '');
  const inputCorrectionRef = useRef<HTMLInputElement>(null);

  const stopActionClickPropagation: MouseEventHandler = e => {
    e.stopPropagation();
  };

  const scrollToFoundWord = useCallback(({ words }) => {
    if (words?.length) {
      document.querySelector(`[data-word-start-time="${words[0].start_time}"]`)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, []);

  const monologueAsParagraphs = useMemo(() => {
    return speakerWithMonologue.map(monologue => {
      return {
        words: monologue.monologue,
        speakerDetails: monologue.speakerDetails
      };
    });
  }, [speakerWithMonologue]);

  return (
    <Transition
      appear
      show={true}
      as={'div'}
      className={'flex h-full flex-col overflow-hidden'}
      enter="transform duration-200 ease-out"
      enterFrom="translate-x-[-40rem]"
      enterTo="translate-x-0"
      leave="transform duration-150 ease-in"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-[-40rem]"
    >
      <div className="border-b px-4 py-3" onPointerUp={stopActionClickPropagation} onClick={stopActionClickPropagation}>
        <div className="flex justify-between">
          <SideBarBreadcrumb
            setSideBarContext={setSideBarContext}
            title={title}
            showCaptionsToggle={isEasyClipCustomizer}
          />
          <div className="flex flex-row items-center justify-center space-x-2">
            {isEasyClipCustomizer && sideBarContext && (
              <>
                <IconButton
                  icon={sideBarContext === SidebarContext.EditCaptions ? 'IconScissors' : 'IconPencil'}
                  content={sideBarContext === SidebarContext.EditCaptions ? 'Trim / Extend Clip' : 'Edit Captions'}
                  size="small"
                  trackingId="captions-sidebar-toggle-button"
                  onClick={() =>
                    setSideBarContext(
                      sideBarContext === SidebarContext.EditCaptions
                        ? SidebarContext.EditDuration
                        : SidebarContext.EditCaptions
                    )
                  }
                />
                <TranscriptSearch
                  paragraphs={monologueAsParagraphs}
                  onOccurrencesChange={scrollToFoundWord}
                  setHighlightedParagraph={setHighlightedMonologue}
                  size="small"
                />
              </>
            )}
          </div>
        </div>

        {isEditDurationMode ? (
          <></>
        ) : (
          <div className="z-20 mt-2 flex w-full items-center justify-between bg-white">
            <input
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
              }}
              placeholder="Select text to edit"
              disabled={selectedWords.length === 0 || transcriptStore.trimming}
              className="h-8 w-[13rem] rounded-lg border border-slate-300 px-2 text-sm outline-deep-orange ring-2 ring-transparent transition-all duration-150 ease-in-out focus:border-transparent focus:outline-none focus:ring-2 focus:ring-deep-orange"
              ref={inputCorrectionRef}
            />
            <TranscriptEditPanel
              selectedWords={selectedWords}
              setSelectedWords={setSelectedWords}
              correctInputValue={inputValue}
              selectedWordString={selectedWordString}
              wordsCount={wordsCountRef.current}
              clipData={clipData}
            />
          </div>
        )}
      </div>
      <div className="pointer-events-auto relative h-full grow overflow-scroll px-3 py-4" onDragOver={onDragOver}>
        <div
          className="relative w-full text-[0.975rem] font-light leading-[1.5rem] tracking-[0.005rem] text-slate-800 selection:bg-slate-200"
          ref={containerRef}
          onPointerMove={selectClickedWords}
          onPointerDown={pointerDown}
        >
          {transcriptStore.trimming && (
            <Fragment>
              {startPositions && (
                <div
                  data-testid="trim-start-cursor"
                  className="absolute z-10 inline-block h-[30px] w-[2px] cursor-grab bg-deep-orange-600 transition-all"
                  style={{ top: `${startPositions.top}px`, left: `${startPositions.left}px` }}
                  draggable="true"
                  onDragStart={onStartCursorDrag}
                  onDragEnd={onDragEnd}
                >
                  <div className="absolute -left-[3px] -top-[5px] h-2 w-2 rounded-full bg-deep-orange-600" />
                </div>
              )}
              {endPositions && (
                <div
                  data-testid="trim-end-cursor"
                  className="absolute z-10 inline-block h-7 w-[2px] cursor-grab bg-deep-orange-600 transition-all"
                  style={{ top: `${endPositions.top}px`, left: `${endPositions.left}px` }}
                  draggable="true"
                  onDragStart={onEndCursorDrag}
                  onDragEnd={onDragEnd}
                >
                  <div className="absolute -bottom-[5px] -right-[3px] h-2 w-2 rounded-full bg-deep-orange-600" />
                </div>
              )}
            </Fragment>
          )}

          {speakerWithMonologue.map((item, index) => {
            const { speakerDetails, monologue } = item;
            const speakerName = `${speakerDetails.speaker.first_name} ${speakerDetails.speaker.last_name}`;

            return (
              <Fragment key={speakerName + index}>
                {index !== 0 && <div className="h-3" />}
                <div className="flex w-full space-x-3">
                  <SpeakerRow
                    speakerDetails={item.speakerDetails}
                    index={index}
                    handleEditSpeaker={() => handleEditSpeaker(item.speakerDetails.speaker.id)}
                  />
                  <div className="w-full rounded-lg border border-slate-200 px-2.5 py-2">
                    {monologue?.map((word, i) => {
                      return (
                        <React.Fragment key={word.content + i}>
                          <ClipTranscriptWord
                            word={word}
                            index={word.index}
                            playerStore={playerStore}
                            selectedWords={selectedWords}
                            highlightStartTime={highlightStartTime}
                            highlightEndTime={highlightEndTime}
                            foundWords={highlightedMonologue?.index === index ? highlightedMonologue?.words : []}
                          />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </Transition>
  );
}
