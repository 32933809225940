import React, { useMemo, useSyncExternalStore } from 'react';
import { useSearchParams } from 'react-router-dom';
import ClipsListItem from './ClipsListItem';
import { MenuItemsLabelType, menuItems } from '../Sessions/AssetsTable/AssetsTableUtils';
import RowMenuItem from '../Sessions/uiComponents/RowMenuItem';
import useDownloadAsset from '@/hooks/useDownloadAsset';
import { Clip } from '@/domains/asset';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import useAssetRating from '@/hooks/useAssetRating';
import useClipNavigate from '@/hooks/useClipNavigate';
import useSavedSearch from '@/hooks/useSavedSearch';

export default function ClipsListItemWithActions({
  clip,
  isCollapsed,
  onDelete,
  onEditClip,
  onRenameClip,
  onGenerateSocialPost,
  onClipSelect
}: {
  clip: Clip;
  isCollapsed: boolean;
  onDelete: (id: string) => void;
  onEditClip: (clip: Clip) => void;
  onRenameClip: (clip: Clip) => void;
  onGenerateSocialPost: (clipId: string) => void;
  onClipSelect: (clip: Clip) => void;
}) {
  const [searchParams] = useSearchParams();
  const { getAssetRating } = useAssetRating();
  const activeClipId = useMemo(() => searchParams.get('activeClipId'), [searchParams]);
  const isClipActive = activeClipId === clip.id;
  const { feedbackMessage } = getAssetRating(clip.id);
  const { navigateToClip } = useClipNavigate();
  const { onDownloadClip } = useDownloadAsset({ type: 'CLIP', clip }, navigateToClip);

  const { isSavedSearchPage } = useSavedSearch();

  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isEasyClipCustomizerEnabled = featureFlags[FeatureFlagKeys.Use_CL_Easy_Clip_Customizer];

  const clipMenuItems = useMemo(() => {
    return menuItems['CLIP'].filter(item => {
      if (item.label === 'Edit' && isEasyClipCustomizerEnabled) {
        return false;
      } else if (item.label === 'Rename' && isSavedSearchPage) {
        return false;
        // TODO @dusangc: Remove this branch after AI Search Iteration 2
      } else if (item.label === 'Generate Social Post' && isSavedSearchPage) {
        return false;
      }

      return true;
    });
  }, [isEasyClipCustomizerEnabled, feedbackMessage, isSavedSearchPage]);

  const onAction = (actionType: MenuItemsLabelType) => {
    switch (actionType) {
      case 'Edit':
        onEditClip?.(clip);
        break;
      case 'Rename':
        onRenameClip?.(clip);
        break;
      case 'Download':
        onDownloadClip?.();
        break;
      case 'Delete':
        onDelete?.(clip.id);
        break;
      case 'Generate Social Post':
        onGenerateSocialPost?.(clip.asset_metadata.id);
    }
  };

  return (
    <ClipsListItem clip={clip} onClipSelect={onClipSelect} isCollapsed={isCollapsed}>
      <RowMenuItem content={clip.content} menuItems={clipMenuItems} onAction={onAction} forceShow={isClipActive} />
    </ClipsListItem>
  );
}
