import { Fragment, useEffect, useState, useSyncExternalStore } from 'react';
import { Transition } from '@headlessui/react';
import { assetsContentUploadPartialUpdate } from '@goldcast/api/content';
import SpeakersList from '../../atoms/SpeakersList/SpeakersList';
import SessionConfigActions from './SessionConfigActions';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import RecordingDetailsStep from '@/Pages/TranscriptPage/SetupContent/steps/RecordingDetailsStep';
import { core } from '@/stores/core';
import { RecordingDetails } from '@/Pages/TranscriptPage/types';
import { isEmptyObject } from '@/libs/utils';
import IdentifySpeakers from '@/Pages/TranscriptPage/IdentifySpeakers/IdentifySpeakers';
import { pause } from '@/stores/player';
import IconButton from '@/components/atoms/Button/IconButton';

export default function SessionConfig() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSpeakerId, setSelectedSpeakerId] = useState<string | null>(null);
  const [updatedRecordingDetails, setUpdatedRecordingDetails] = useState<RecordingDetails>({});

  const toggle = ({ speakerId }: { speakerId: string | null }) => {
    // Save details on close, if changed
    if (coreStore.uploadData && isOpen && !isEmptyObject(updatedRecordingDetails)) {
      const newUploadData = {
        ...coreStore.uploadData,
        title: updatedRecordingDetails.title?.trim().length ? updatedRecordingDetails.title : coreStore.content?.title,
        recording_date: updatedRecordingDetails.recording_date || coreStore.uploadData.recording_date
      };
      assetsContentUploadPartialUpdate({
        id: coreStore.uploadData.id,
        body: newUploadData
      }).then(() => {
        core.update(data => ({
          ...data,
          content: {
            ...data.content,
            title: newUploadData.title
          },
          uploadData: newUploadData
        }));
      });
    }

    if (speakerId) {
      setSelectedSpeakerId(speakerId);
    }

    setIsOpen(val => !val);
  };

  const handleCloseClick = () => {
    toggle({ speakerId: null });
  };

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.OpenSessionConfig, toggle);
    return () => {
      EventBus.off(CustomEvents.OpenSessionConfig, eventListener);
    };
  }, []);

  function onRecordingUpdate(field: string, newValue: string) {
    setUpdatedRecordingDetails(value => {
      return {
        ...value,
        [field]: newValue
      };
    });
  }

  function openIdentifySpeakersDialog() {
    pause();
    EventBus.dispatch(CustomEvents.OpenSpeakersIdentification);
  }

  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        enter="transition ease duration-500 transform"
        enterFrom="opacity-0 translate-x-20"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease duration-500 transform"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-20"
      >
        <div className="fixed inset-0 z-[999] overflow-hidden bg-white/90">
          <div className="fixed inset-0 m-2 ml-auto flex max-w-xl flex-col overflow-hidden rounded-md border bg-white shadow-[0_0_5px_5px_rgba(0,0,0,0.05)]">
            <div className="flex items-center justify-between border-b bg-white pb-2 pl-5 pr-1.5 pt-1.5">
              <p>Settings</p>
              <IconButton
                icon="IconX"
                variation="text"
                size="large"
                onClick={handleCloseClick}
                trackingId="close-button"
              />
            </div>
            <div className="overflow-x-hidden py-5">
              <div className="px-5">
                {coreStore.uploadData && (
                  <RecordingDetailsStep recordingDetails={coreStore.uploadData} onRecordingUpdate={onRecordingUpdate} />
                )}
                <SpeakersList speakerId={selectedSpeakerId} openIdentifySpeakersDialog={openIdentifySpeakersDialog} />
              </div>
              <SessionConfigActions />
            </div>
          </div>
        </div>
      </Transition>
      <IdentifySpeakers />
    </>
  );
}
