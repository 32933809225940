import React, { useMemo } from 'react';
import { ClipTranscriptWordProps } from '../types';
import { getHighligtedWordContent } from '../utils';
import { Word } from '@/domains/transcript';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { TranscriptContextType } from '@/context/TranscriptContext/TranscriptContextTypes';
import { classnames } from '@/libs/utils';

export default function ClipTranscriptWord({
  word,
  highlightStartTime = 0,
  highlightEndTime = 0,
  selectedWords = [] as Word[],
  playerStore,
  foundWords = {}
}: ClipTranscriptWordProps) {
  const transcriptStore = useTranscriptContext() as TranscriptContextType;
  const trimming = useMemo(() => transcriptStore.trimming, [transcriptStore.trimming]);

  const wordClassName = useMemo(() => {
    let className = '';

    if (selectedWords[0]?.index <= word.index && selectedWords[selectedWords.length - 1]?.index >= word.index) {
      className += ' !bg-deep-orange-200';
    }

    if (word.start_time < highlightStartTime || word.end_time > highlightEndTime) {
      className += ' opacity-30';
    } else if (trimming) {
      className += ' bg-deep-orange-200';
    }

    return className;
  }, [selectedWords, word, highlightStartTime, highlightEndTime, trimming]);

  const currentWordClassName = useMemo(() => {
    return word.start_time <= playerStore.currentTime && word.end_time >= playerStore.currentTime
      ? ' underline underline-offset-2 decoration-deep-orange-600 decoration-[3px]'
      : '';
  }, [playerStore.currentTime, word.end_time, word.start_time]);

  const parsedWord = useMemo(() => {
    let { content } = word;

    if (foundWords[word.start_time]) {
      content = getHighligtedWordContent(foundWords[word.start_time], content);
    }

    return {
      ...word,
      content: foundWords[word.start_time]?.highlightSpace ? content : content + ' '
    };
  }, [foundWords, word]);

  return (
    <div
      data-word-id={word.index}
      data-word-start-time={word.start_time}
      data-word-end-time={word.end_time}
      className={classnames(
        'inline-block h-[30px] select-none items-center justify-center whitespace-pre',
        wordClassName,
        currentWordClassName,
        {
          'cursor-text': !trimming,
          'opacity-30': word.hidden
        }
      )}
      dangerouslySetInnerHTML={{ __html: parsedWord.content }}
    ></div>
  );
}
