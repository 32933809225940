import React, { useCallback, useEffect, useSyncExternalStore } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { IconSettings } from '@tabler/icons-react';
import GenerateContent from '../GenerateContent/GenerateContent';
import NavItem, { NavItemType } from './components/NavItem';
import SessionDetailBackButton from './SessionDetailBackButton';
import SessionConfig from '@/components/molecules/SessionConfig';
import { clearUnseenClipIds } from '@/stores/unseenClips';
import Loader from '@/components/atoms/Loader';
import { FeatureFlagKeys } from '@/services/featureFlag';
import featureFlagStore from '@/stores/featureFlagStore';
import AIDebugTools from '@/components/molecules/AIDebugTools/AIDebugTools';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import useSavedSearch from '@/hooks/useSavedSearch';

export default function SessionDetailLayout({
  isContentUploading,
  isLoading,
  navMenu
}: {
  isContentUploading?: boolean | undefined;
  isLoading: boolean;
  navMenu: NavItemType[];
}) {
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const aiDebugToolsEnabled = featureFlags[FeatureFlagKeys.Use_CL_AI_Debug_Tools];
  const location = useLocation();
  const { isSavedSearchPage } = useSavedSearch();

  useEffect(() => {
    if (location.state?.previousPathname?.includes('/clips')) {
      clearUnseenClipIds();
    }
  }, [location.state?.previousPathname]);

  const openSettingsDrawer = useCallback(() => {
    EventBus.dispatch(CustomEvents.OpenSessionConfig, { speakerId: null });
  }, []);

  return (
    <div className="fixed inset-0 z-40 flex h-full flex-col bg-slate-50">
      <div className="flex h-full grow">
        {aiDebugToolsEnabled && <AIDebugTools />}
        <div className="z-10 flex w-20 shrink-0 select-none flex-col items-center justify-between overflow-y-auto bg-slate-50 pt-[1.2rem] transition-all duration-[400ms]">
          <div className="flex flex-col space-y-7">
            <SessionDetailBackButton />
            {navMenu.map(navItem => {
              return (
                <div className="mb-8" key={navItem.href}>
                  <NavItem navItem={navItem} key={navItem.label} />
                </div>
              );
            })}
          </div>
          {!isSavedSearchPage && (
            <div className="flex flex-col items-center border-t border-t-slate-300/70 pb-5 pt-[0.45rem]">
              <button
                onClick={openSettingsDrawer}
                data-testid="session-detail-settings-button"
                className="group flex flex-col items-center text-slate-600 hover:text-black"
              >
                <div className="flex h-9 w-9 items-center justify-center rounded-xl group-hover:bg-slate-200">
                  <IconSettings className="h-5 w-5" />
                </div>
                <div className="mt-0.5 text-xs">Settings</div>
              </button>
            </div>
          )}
        </div>
        <div className="h-full grow">
          {isLoading ? (
            <div className="flex h-full w-full items-center justify-center">
              <Loader />
            </div>
          ) : isContentUploading ? (
            <GenerateContent />
          ) : (
            <Outlet />
          )}
        </div>
      </div>
      {!isSavedSearchPage && <SessionConfig />}
    </div>
  );
}
