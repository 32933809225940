import { memo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { assetsAssetV2List } from '@goldcast/api/content';
import SavedSearchDetailLayout from './SavedSearchDetailLayout';
import { setAllClips } from '@/stores/clip';
import { initUnseenClipIds } from '@/stores/unseenClips';
import { Clip } from '@/domains/asset';

function SavedSearch() {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  function loadSavedSearchClips() {
    return assetsAssetV2List({
      queryParams: {
        saved_search: id,
        types: 'CLIP',
        limit: 1000,
        ordering: 'created_at'
      }
    }).then(({ results }) => results as unknown as Clip[]);
  }

  useQuery({
    queryKey: ['clips', id],
    queryFn: loadSavedSearchClips,
    initialData: [],
    onSuccess: clips => {
      setAllClips(clips);
      initUnseenClipIds(clips);
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  return <SavedSearchDetailLayout isLoading={isLoading} />;
}

export default memo(SavedSearch);
