import { useEffect, useState, useMemo, useCallback } from 'react';
import {
  tones,
  textLengthOptions,
  eventPrompts,
  contentPrompts,
  POST_TYPE_LABELS,
  LENGTH_HINTS,
  POST_TYPES
} from '../constants';
import { TextSelectionType, GeneratePostModalProps, SelectedVoiceProfile } from '../types';
import BrandVoiceTone from './BrandVoiceTone';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import Button from '@/components/atoms/Button/Button';
import { classnames } from '@/libs/utils';
import { isCustomUpload } from '@/libs/clipContentUtil';
import { updateActiveStep } from '@/stores/voiceProfile';
import { VoiceProfileSteps } from '@/Pages/BrandKit/components/BrandTone/constants';
import useVoiceProfile from '@/hooks/useVoiceProfile';

export default function GeneratePostModal({ isOpen, handleGenerateClick, onClose, postType }: GeneratePostModalProps) {
  const { voiceProfiles } = useVoiceProfile();

  const [selectedVoiceProfile, setSelectedVoiceProfile] = useState<SelectedVoiceProfile>({
    value: '',
    type: 'default'
  });
  const [selectedTextLength, setSelectedLength] = useState<string>(textLengthOptions[1].value);
  const [promptValue, setPromptValue] = useState<string>('');
  const isUpload = isCustomUpload();

  const lengthHint = useMemo(() => LENGTH_HINTS[postType], [postType]);

  useEffect(() => {
    if (!isOpen) return;
    initGenerateState();
  }, [isOpen]);

  const updateTextLength = (textLengthOption: TextSelectionType) => {
    setSelectedLength(textLengthOption.value);
  };

  const setActiveVoiceProfile = () => {
    const voiceProfile = voiceProfiles.find(voiceProfile => voiceProfile.default);
    if (voiceProfile) {
      setSelectedVoiceProfile({
        value: voiceProfile.id,
        type: 'custom'
      });
    } else {
      setSelectedVoiceProfile({
        value: tones[0].value,
        type: 'default'
      });
    }
  };

  const initGenerateState = () => {
    setActiveVoiceProfile();
    setSelectedLength(postType === POST_TYPES.SOCIAL ? textLengthOptions[0].value : textLengthOptions[1].value);
    setPromptValue(isUpload ? contentPrompts[postType] : eventPrompts[postType]);
  };
  const handleClose = () => {
    onClose();
  };

  const handlePrimaryClick = () => {
    const prompt = {
      prompt: promptValue,
      ...(selectedVoiceProfile.type === 'default' && { brand_tone: selectedVoiceProfile.value }),
      ...(selectedVoiceProfile.type === 'custom' && { voice_profile: selectedVoiceProfile.value }),
      length: selectedTextLength
    };
    handleGenerateClick(prompt);
  };

  const handleSelectedVoiceProfile = useCallback((profile: SelectedVoiceProfile) => {
    setSelectedVoiceProfile({ ...profile });
  }, []);

  const handleCreateVoiceProfile = useCallback(() => {
    handleClose();
    updateActiveStep(VoiceProfileSteps.CreateVoiceProfile);
  }, []);

  return (
    <ContentDialog
      isOpen={isOpen}
      title={`Generate ${POST_TYPE_LABELS[postType]} Post`}
      size="large"
      setIsOpen={handleClose}
      disableBackdropClose={true}
    >
      <div className="mt-5 px-5">
        <BrandVoiceTone
          selectedVoiceProfile={selectedVoiceProfile.value}
          onSelectedVoiceProfile={handleSelectedVoiceProfile}
          onCreateVoiceProfile={handleCreateVoiceProfile}
        />
        <div className="mt-8 text-lg font-medium">Text Length</div>
        <div className="text-sm text-slate-600">
          Short is {lengthHint['SHORT']}, Medium {lengthHint['MEDIUM']}, and long {lengthHint['LONG']} words.
        </div>
        <div className="mt-3 flex">
          {textLengthOptions.map((textLengthOption: TextSelectionType) => (
            <button
              key={textLengthOption.value}
              onClick={() => updateTextLength(textLengthOption)}
              className={classnames(
                'flex items-center space-x-2 border-y px-7 py-3 text-sm first:rounded-l-full first:border-l last:rounded-r-full last:border-r hover:bg-slate-100 [&:nth-child(2)]:border-x',
                {
                  'z-10 bg-slate-100 ring-2 ring-slate-950 focus:ring-2': textLengthOption.value === selectedTextLength
                }
              )}
            >
              <div className="mr-1 text-xl">{textLengthOption.icon}</div>
              <div>{textLengthOption.label}</div>
            </button>
          ))}
        </div>
        <div className="mt-8 text-lg font-medium">Prompt</div>
        <div className="text-sm text-slate-600">Fine-tune your post with a prompt to guide your writing</div>
        <div className="mt-3">
          <textarea
            rows={2}
            className="w-full rounded-lg border border-slate-200 p-3.5 text-sm focus:outline-none focus:ring-2 focus:ring-slate-950"
            id={'prompt'}
            value={promptValue}
            onChange={e => setPromptValue(e.target.value)}
          />
        </div>
      </div>
      <div className="sticky bottom-0 z-10 flex w-full items-center justify-end border-t bg-white p-2">
        <Button
          disabled={!promptValue || !promptValue.length}
          trackingId="alert-dialog-primary-action"
          variation="filled"
          className="ml-2"
          onClick={handlePrimaryClick}
        >
          Generate
        </Button>
      </div>
    </ContentDialog>
  );
}
