import { memo } from 'react';
import { IconSparkles, IconLoader2 } from '@tabler/icons-react';
import ContentDialog from '../../uiComponents/ContentDialog/ContentDialog';

function SavedSearchLoader({ isOpen, closeDialog }: { isOpen: boolean; closeDialog: () => void }): JSX.Element {
  return (
    <ContentDialog
      isOpen={isOpen}
      size="large"
      setIsOpen={closeDialog}
      disableBackdropClose={true}
      panelClassNames="text-md"
      hideCloseIcon={true}
    >
      <div className="flex flex-col items-center justify-center py-10 pb-14">
        <div className="relative flex items-center justify-center">
          <IconSparkles className="absolute" />
          <IconLoader2 stroke={0.5} className="h-20 w-20 animate-spin" />
        </div>
        <div className="mt-5 text-lg font-medium">Finding Clips</div>
        <div className="mt-2.5 w-80 text-center text-sm text-slate-600">
          Give us a moment while we find new clips from across your video library. This can take up to 1 minute.
        </div>
      </div>
    </ContentDialog>
  );
}

export default memo(SavedSearchLoader);
