import { useRef, useSyncExternalStore } from 'react';
import { Id, toast } from 'react-toastify';
import * as filestack from 'filestack-js';
import { brandKit, clipBrandKit, storeBrandKitChanges, updateIndividualBrandKitConfig } from '@/stores/brandKit';
import { showErrorToast, showSuccessToast, showWarningToast } from '@/libs/toast/toast';
import { getEnvConfig } from '@/constants';
import { currentUser } from '@/stores/user';
import { getFilesForUpload, getUploadConfig, verifyFontFile } from '@/Pages/BrandKit/components/utils';
import { BRAND_KIT_MAX_FILES, BRAND_KIT_MAX_FILE_SIZE, BRAND_KIT_PATH } from '@/Pages/BrandKit/constants';
import { useAppContext } from '@/context/AppContext/AppContext';

export default function useBrandKitComponent(key: string, title: string, clipId?: string) {
  const brandKitStore = useSyncExternalStore(brandKit.subscribe, brandKit.getSnapshot);
  const clipBrandKitStore = useSyncExternalStore(clipBrandKit.subscribe, clipBrandKit.getSnapshot);
  const activeStore = clipId ? clipBrandKitStore : brandKitStore;
  const { logger } = useAppContext();
  const uploadToastId = useRef<Id | undefined>();
  const baseFilePath = clipId
    ? `${currentUser.getSnapshot()?.organization}/clips/${clipId}`
    : `${currentUser.getSnapshot()?.organization}`;

  function onFilesUpload(files: File[]): Promise<any> {
    const totalSize = files.reduce((acc, f) => f.size + acc, 0);
    if (files.length > BRAND_KIT_MAX_FILES) {
      showErrorToast('Please select up to 10 files');
      return Promise.reject();
    }
    const filesToUpload = getFilesForUpload(key, files);
    if (filesToUpload.length < files.length) {
      showWarningToast(`Some files are ignored due to ${BRAND_KIT_MAX_FILE_SIZE}MB file size limit`);
    }
    if (!filesToUpload.length) return Promise.reject();
    return uploadBrandKitFiles(filesToUpload, getUploadConfig(uploadToastId, totalSize)).then(() => {
      toast.dismiss(uploadToastId.current);
      uploadToastId.current = undefined;
      return verifyUploadedFiles(filesToUpload)
        .then(() => {
          updateIndividualBrandKitConfig(key, [...activeStore[key], ...filesToUpload.map(f => f.name)], !!clipId);
          return storeBrandKitChanges(clipId)
            .then(() => {
              showSuccessToast(`${title} updated successfully`);
              return filesToUpload;
            })
            .catch(() => Promise.reject());
        })
        .catch(err => {
          logger.error(`Error updating brand kit: ${err}`);
          if (!!err) {
            showErrorToast(err);
          } else {
            return Promise.reject('Error storing file');
          }
        });
    });
  }

  function verifyUploadedFiles(filesToUpload: File[]): Promise<any> {
    if (key === 'fonts' && !!filesToUpload.length) {
      return verifyFontFile(filesToUpload[0].name);
    }
    return Promise.resolve();
  }

  function deleteItem(name: string) {
    updateIndividualBrandKitConfig(
      key,
      activeStore[key].filter(v => v !== name),
      !!clipId
    );
    storeBrandKitChanges(clipId);
  }

  function uploadBrandKitFiles(files: File[], uploadConfig: filestack.UploadOptions): Promise<any> {
    const filePath = BRAND_KIT_PATH;
    const storeConfig: filestack.StoreUploadOptions = {
      location: 's3',
      container: getEnvConfig('S3_STATIC_ASSETS_BUCKET'),
      path: `/${filePath}`,
      disableStorageKey: true,
      region: getEnvConfig('AWS_STORE_REGION'),
      filename: file => `${baseFilePath}/${file.name}`
    };
    const client = filestack.init(getEnvConfig('FILESTACK_API_KEY'));
    return client.multiupload(files, uploadConfig, storeConfig);
  }

  return {
    onFilesUpload,
    deleteItem,
    brandKitStore,
    clipBrandKitStore
  };
}
