import React, { useEffect, useMemo, useRef } from 'react';
import { getFacePositionsForGrid } from './CanvasPlayerUtils';
import { ClipMetadata } from '@/domains/asset';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function VideoCanvas({ mainPlayer, secondaryPlayers, currentSpeaker, loading, clipLayout }) {
  const videoCanvasRef = useRef<HTMLCanvasElement>(null);

  const { clipId, clipData, speakersAnalysis } = useClipsContext();
  const clipMetadata = clipData.asset_metadata as ClipMetadata;
  const showCaption = !!clipMetadata.subtitle;

  const clipSize = clipMetadata.size;

  // config used for video canvas
  const videoSizeConfig = useMemo(
    () => getSizeConfig(clipSize, clipId, clipLayout, 0.5),
    [clipSize, clipId, clipLayout]
  );

  useEffect(() => {
    const videoCanvas = videoCanvasRef.current;
    if (!videoCanvas) return;

    const { width, height } = videoSizeConfig.getHeightAndWidth();

    videoCanvas.width = width;
    videoCanvas.height = height;

    computeFrame();
  }, [videoSizeConfig]);

  useEffect(() => {
    computeFrame();
  }, [
    clipLayout,
    showCaption,
    loading,
    currentSpeaker,
    speakersAnalysis.visible_face_ids,
    clipMetadata.visible_speakers,
    clipMetadata.hide_borders,
    clipMetadata.caption_positions
  ]);

  function drawSingleVideoOnCanvas(
    video,
    videoWidth,
    videoHeight,
    source_crop_x_offset = 0,
    source_crop_y_offset = 0,
    shouldUseDefaultLayoutValues = false
  ) {
    const videoCanvasContext = videoCanvasRef.current?.getContext('2d');
    if (!videoCanvasContext) return;

    videoSizeConfig.drawVideo(videoCanvasContext, video, {
      videoWidth,
      videoHeight,
      showCaption,
      source_crop_x_offset,
      source_crop_y_offset,
      shouldUseDefaultLayoutValues
    });
  }

  function drawOnCanvasForDefault() {
    drawSingleVideoOnCanvas(
      mainPlayer.current!,
      mainPlayer.current!.videoWidth,
      mainPlayer.current!.videoHeight,
      0,
      0,
      true
    );
  }

  function drawOnCanvasForSpeakerRecording() {
    const videoRef = secondaryPlayers.find(player => player.current?.id === currentSpeaker?.key);
    if (!videoRef?.current) return;
    drawSingleVideoOnCanvas(videoRef.current!, videoRef.current.videoWidth, videoRef.current.videoHeight);
  }

  function drawOnCanvasForGridRecording(videoCanvasContext) {
    if (clipMetadata.visible_speakers && clipMetadata.visible_speakers?.length > 0) {
      clipMetadata.visible_speakers.forEach((speaker, index) => {
        const videoRef = secondaryPlayers.find(player => player.current?.id === speaker.key);
        if (!videoRef?.current) return;
        videoSizeConfig.drawVideo(videoCanvasContext, videoRef.current, {
          videoWidth: videoRef.current.videoWidth,
          videoHeight: videoRef.current.videoHeight,
          showCaption,
          totalVideos: clipMetadata.visible_speakers!.length,
          currentVideoNumber: index + 1
        });
      });
    }
  }

  function drawOnCanvasForUpload(clipLayout, videoCanvasContext, index) {
    let facePositions =
      clipLayout === 'SPEAKER'
        ? speakersAnalysis.time_analysis[index].face_positions
        : getFacePositionsForGrid(index, speakersAnalysis);

    if (facePositions.length === 0) {
      drawOnCanvasForDefault();
      return;
    }

    if (clipLayout === 'SPEAKER' && facePositions.length > 1) {
      facePositions = facePositions.filter(facePosition =>
        speakersAnalysis.speaker_mapping?.[currentSpeaker?.id]?.includes(facePosition.face_id)
      );
    }

    facePositions.forEach((facePosition, index) => {
      const cropPosition = facePosition.crop_position || facePosition.crop_position_face;
      if (!cropPosition) {
        return;
      }

      const originalVideoWidth = mainPlayer.current!.videoWidth;
      const originalVideoHeight = mainPlayer.current!.videoHeight;

      videoSizeConfig.drawVideo(videoCanvasContext, mainPlayer.current!, {
        videoWidth: originalVideoWidth * (cropPosition.bottom_right.x - cropPosition.top_left.x),
        videoHeight: originalVideoHeight * (cropPosition.bottom_right.y - cropPosition.top_left.y),
        showCaption,
        source_crop_x_offset: Math.round(originalVideoWidth * cropPosition.top_left.x),
        source_crop_y_offset: Math.round(originalVideoHeight * cropPosition.top_left.y),
        totalVideos: facePositions.length,
        currentVideoNumber: index + 1,
        optionsForNewSpeakerMapping: {
          allPositions: {
            crop_position: facePosition.crop_position,
            crop_position_face: facePosition.crop_position_face,
            crop_position_person: facePosition.crop_position_person
          },
          originalVideoWidth: originalVideoWidth,
          originalVideoHeight: originalVideoHeight
        }
      });
    });
  }

  function computeFrame() {
    if (loading || !currentSpeaker) return;

    const videoCanvasContext = videoCanvasRef.current?.getContext('2d');
    if (!videoCanvasContext) return;

    videoCanvasContext.clearRect(0, 0, videoSizeConfig.getWidth(), videoSizeConfig.getHeight());

    const currentTime = mainPlayer.current!.currentTime;
    const index = speakersAnalysis.times.findLastIndex(t => t <= currentTime);

    if (clipLayout === 'DEFAULT') {
      drawOnCanvasForDefault();
      return;
    }

    if (index === -1) {
      if (clipLayout === 'SPEAKER') {
        drawOnCanvasForSpeakerRecording();
      } else if (clipLayout === 'GRID') {
        drawOnCanvasForGridRecording(videoCanvasContext);
      }
      return;
    } else {
      if (clipLayout === 'SPEAKER' || clipLayout === 'GRID') {
        drawOnCanvasForUpload(clipLayout, videoCanvasContext, index);
      }
    }
  }

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.ComputeFrame, computeFrame);
    return () => {
      EventBus.off(CustomEvents.ComputeFrame, eventListener);
    };
  }, [clipLayout, currentSpeaker, speakersAnalysis, clipData, mainPlayer, secondaryPlayers, loading]);

  return <canvas id="video-canvas" ref={videoCanvasRef} className="absolute top-0 h-full w-full" />;
}
