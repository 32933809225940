import { Transition } from '@headlessui/react';
import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { classnames } from '@/libs/utils';
import IconButton from '@/components/atoms/Button/IconButton';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';

export type SharePopupTab = {
  key: string;
  title: string;
  tabComponent: JSX.Element;
};

interface SharePopupProps {
  currentTab: SharePopupTab;
  tabs: SharePopupTab[];
  beforeOpen: () => void;
  setCurrentTab: (tab: SharePopupTab) => void;
}

type SharePopupRef = ForwardedRef<{
  openSharePopup: () => void;
}>;

const SharePopup = forwardRef(
  ({ currentTab, tabs, beforeOpen, setCurrentTab }: SharePopupProps, ref: SharePopupRef) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSharePopup = useCallback(() => {
      setIsOpen(prev => !prev);
    }, []);

    const closeSharePopup = useCallback(() => {
      setIsOpen(false);
    }, []);

    const openSharePopup = useCallback(() => {
      setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => ({
      openSharePopup
    }));

    useEffect(() => {
      const eventListener = EventBus.on(CustomEvents.CloseSharePopup, closeSharePopup);
      return () => {
        EventBus.off(CustomEvents.CloseSharePopup, eventListener);
      };
    }, [closeSharePopup]);

    return (
      <div className="relative z-40">
        <IconButton
          icon="IconShare2"
          content="Share"
          variation="filled"
          trackingId="open-export-dialog-button"
          onClick={toggleSharePopup}
        />
        {isOpen && <div className="fixed inset-0" onClick={closeSharePopup} />}
        <Transition
          show={isOpen}
          beforeEnter={beforeOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0 origin-bottom-right"
          enterTo="transform scale-100 opacity-100 origin-bottom-right"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100 origin-bottom-right"
          leaveTo="transform scale-95 opacity-0 origin-bottom-right"
        >
          <div className="absolute right-0 w-[24rem] translate-y-2 rounded-[0.75rem] border bg-white shadow-lg">
            <div className="flex space-x-1.5 px-3 pt-2">
              {tabs.map(tab => (
                <button
                  key={tab.key}
                  onClick={() => setCurrentTab(tab)}
                  className={classnames('px-2 py-1 text-sm text-slate-600', {
                    'border-b !border-b-black !text-black': currentTab.key === tab.key
                  })}
                >
                  {tab.title}
                </button>
              ))}
            </div>
            {currentTab.tabComponent}
          </div>
        </Transition>
      </div>
    );
  }
);

export default SharePopup;
