import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Saturation, Hue } from 'react-color/lib/components/common';
import { ChromePointer } from 'react-color/lib/components/chrome/ChromePointer';
import { ChromePointerCircle } from 'react-color/lib/components/chrome/ChromePointerCircle';
import tinycolor2 from 'tinycolor2';
import { Swatch } from 'react-color/lib/components/common/Swatch';
import { IconColorSwatch } from '@tabler/icons-react';
import { ColorPickerFields } from './ColorPickerFields';
import { ColorResult } from './types';
import { classnames } from '@/libs/utils';

export const ColorPicker = ({
  hex,
  classNames = '',
  pickerClassNames = '',
  children = <></>,
  defaultColors = [],
  defaultColorsLabel = '',
  onChange,
  onClose
}: {
  hex: string;
  children?: React.ReactNode;
  pickerClassNames?: string;
  classNames?: string;
  defaultColors?: string[];
  defaultColorsLabel?: string;
  onChange: (hex: string) => void;
  onClose?: () => void;
}) => {
  const colorPicker = useRef<HTMLDivElement>(null);
  // Child #1 - Picker in closed state
  // Child #2 - default colors placeholder
  const childrenArray = React.Children.toArray(children);
  const [isOpened, setIsOpened] = useState(false);
  const [hsl, setHsl] = useState();
  const [hsv, setHsv] = useState();
  const [rgb, setRgb] = useState();
  const [pickerColor, setPickerColor] = useState('white');

  useEffect(() => {
    setHsv(tinycolor2(hex).toHsv());
    setHsl(tinycolor2(hex).toHsl());
    setRgb(tinycolor2(hex).toRgb());
    setPickerColor(!!hex && tinycolor2(hex).isDark() ? 'white' : 'black');
  }, [hex]);

  function colorChanged(val: ColorResult) {
    onChange(`#${tinycolor2(val).toHex()}`);
  }

  function hueChanged(val: ColorResult) {
    const hexColor = tinycolor2(val).toHex();
    if (hexColor === 'ffffff' || hexColor === '000000') {
      // Override color picker default behavior, since there is issue with Hue when color is black or white
      val.s = 0.5;
      val.l = 0.2;
    }
    onChange(`#${tinycolor2(val).toHex()}`);
  }

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('pointerup', pointerUp);
    }
  }, [isOpened]);

  function pointerUp(e) {
    if (!colorPicker.current?.contains(e.target)) {
      setIsOpened(false);
      onClose?.();
      document.removeEventListener('pointerup', pointerUp);
    }
  }

  const openPicker = useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);

  return (
    <>
      <div
        className={`h-full w-full cursor-pointer border border-slate-200 ${classNames}`}
        data-testid="color-picker"
        style={{ backgroundColor: hex, color: pickerColor }}
        onClick={openPicker}
      >
        {childrenArray?.[0]}
      </div>
      {isOpened ? (
        <React.Fragment>
          <div className={classnames('absolute z-50', pickerClassNames)} ref={colorPicker}>
            <div className="w-[225px] rounded-lg bg-white shadow-xl">
              <div className="relative h-[180px] w-full cursor-pointer overflow-hidden rounded-se-xl rounded-ss-xl pb-[55%]">
                <Saturation hsl={hsl} hsv={hsv} pointer={ChromePointerCircle} onChange={colorChanged} />
              </div>
              <div className="p-3">
                <div className="flex">
                  <div className="flex-1">
                    <div className="relative h-[10px] cursor-pointer">
                      <Hue hsl={hsl} pointer={ChromePointer} onChange={hueChanged} />
                    </div>
                  </div>
                </div>
                <ColorPickerFields rgb={rgb} hsl={hsl} hex={hex} onChange={colorChanged} />
                <div className="relative">
                  {defaultColorsLabel && (!!defaultColors.length || childrenArray.length > 1) && (
                    <>
                      <div className="relative top-3 mx-auto flex max-w-[70%] items-center justify-center space-x-0.5 rounded-full border bg-white px-3 py-1">
                        <IconColorSwatch size={16} stroke={2} />
                        <span className="text-xs">{defaultColorsLabel}</span>
                      </div>
                      <div className="border-t pt-5">
                        {defaultColors.length
                          ? defaultColors.map(c => (
                              <Swatch
                                key={c}
                                color={c}
                                style={{
                                  width: '28px',
                                  height: '28px',
                                  float: 'left',
                                  marginRight: '4px',
                                  borderRadius: '9999px',
                                  borderWidth: '1px',
                                  borderColor: '#e2e8f0'
                                }}
                                onClick={colorChanged}
                                focusStyle={{
                                  boxShadow: `0 0 4px ${c}`
                                }}
                              />
                            ))
                          : childrenArray?.[1]}
                      </div>
                    </>
                  )}
                  <div className="clear-both" />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <></>
      )}
    </>
  );
};
