import { memo, useEffect, useSyncExternalStore } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import SessionDetailBackButton from '../SessionDetail/SessionDetailBackButton';
import MockTemplateBackgroundTransition from './MockTemplateBackgroundTransition';
import { ContentStatesEnum } from '../GenerateContent/constants';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import { initContent } from '@/stores/core';
import { firstTimeUserStore } from '@/stores/firstTimeUserStore';
import { useAppContext } from '@/context/AppContext/AppContext';

function VideoImportPage() {
  const navigate = useNavigate();

  const { broadcastId, eventId } = useParams<{ eventId: string; broadcastId: string }>();
  const isFirstTimeUser = useSyncExternalStore(firstTimeUserStore.subscribe, firstTimeUserStore.getSnapshot);

  const { getContentUploadById, contentUploadList, initUploadedContent } = useContentUploadHook();
  const { logger } = useAppContext();
  const contentUpload = getContentUploadById(broadcastId);
  const { contentState } = contentUpload;

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.UploadContentStarted, ({ projectId, contentId }) => {
      navigate(`/import/${projectId}/${contentId}`, { replace: true });
    });
    return () => {
      EventBus.off(CustomEvents.UploadContentStarted, eventListener);
    };
  }, [navigate]);

  useEffect(() => {
    const invalid =
      !broadcastId ||
      !eventId ||
      !contentUpload.projectId ||
      !contentUpload.contentId ||
      contentUpload.projectId !== eventId ||
      contentUpload.contentId !== broadcastId;

    if (invalid) {
      return;
    }

    if (contentState === ContentStatesEnum.ContentReadyForPreview) {
      navigate(`/${eventId}/${broadcastId}/clips`, { replace: true });
    }
  }, [broadcastId, eventId, contentState, contentUpload, navigate]);

  useEffect(() => {
    if (!broadcastId || !eventId || contentUploadList[broadcastId]) return;

    initContent(broadcastId)
      .then(res => {
        const contentDetails = initUploadedContent(res);
        if (contentDetails.contentState === ContentStatesEnum.ContentReadyForPreview) {
          navigate(`/${eventId}/${broadcastId}/clips`, { replace: true });
        }
      })
      .catch(err => {
        logger.error('Failed to fetch uploaded content', err);
        navigate('/');
      });
    return;
  }, [broadcastId, eventId, contentUploadList, initUploadedContent]);

  return (
    <div className="absolute inset-0 h-full w-full overflow-hidden bg-slate-50">
      {!isFirstTimeUser && (
        <div className="absolute left-4 top-4 z-[100]">
          <SessionDetailBackButton />
        </div>
      )}
      <Outlet />
      <MockTemplateBackgroundTransition />
    </div>
  );
}

export default memo(VideoImportPage);
