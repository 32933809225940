import IconButton from '@/components/atoms/Button/IconButton';

export default function VideoImportProcessingDialog() {
  return (
    <div className="absolute bottom-0 z-20 flex w-full translate-y-0 justify-center transition-all duration-[750ms] ease-in-out">
      <div className="relative z-20 flex h-[33rem] w-[50rem] translate-y-2 flex-col rounded-2xl border border-slate-200 bg-[rgba(250,250,252,0.92)] p-6 pb-4 backdrop-blur-[20px] backdrop-saturate-[180%] transition-all duration-[750ms] ease-in-out">
        <div className="mb-4 flex items-center justify-between">
          <div className="flex w-1/3 items-center space-x-2">
            <h2 className="text-2xl font-semibold">That was easy!</h2>
          </div>
        </div>
        <p className="mb-5 text-sm text-slate-600"></p>
        <div className="grow overflow-hidden p-1">
          <div className="grid h-full grid-cols-1 gap-4">
            <div className="flex h-full flex-col items-center justify-center rounded-lg p-4">
              <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-full bg-green-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              </div>
              <p className="mb-2 text-center text-slate-600">We're just waiting for your video to be processed.</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-5">
          <div />
          <div className="flex space-x-2">
            <IconButton
              icon="IconLoader2"
              disabled={true}
              iconClassName="animate-spin"
              trackingId="processing-button"
              title="Processing"
              content="Processing"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
