import React, { memo, useMemo, useEffect, useCallback } from 'react';
import ClipPlayerWrapper from '@/Pages/Clip/ClipPlayer/ClipPlayerWrapper';
import PostClipWrapper from '@/Pages/PostsListPage/components/PostClipWrapper';
import { useAppContext } from '@/context/AppContext/AppContext';
import usePost from '@/hooks/usePost';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { createPostTextFromClipObject } from '@/hooks/utils/postHookUtil';
import { ClipAssetMetadata } from '@/stores/postStore';
import { createContentClip } from '@/libs/clipContentUtil';
import useAnalytics from '@/hooks/useAnalytics';
import ClipPlayer from '@/Pages/Clip/ClipPlayer/ClipPlayer';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';

function PostPlayerWrapper({
  blockId,
  postText,
  onRemoveClip,
  onUpdatedPostText
}: {
  blockId: string;
  postText: string;
  onRemoveClip: () => void;
  onUpdatedPostText: (updatedPostText: string) => void;
}) {
  const {
    openReplaceClipDialog,
    extractClipDetailsFromPostText,
    clips,
    onActiveClipChange,
    removeClipFromPost,
    updateClipDetails,
    getClipAssetIds,
    getSelectedClipId,
    isReplaceClipDialogOpen,
    selectedBlockId,
    selectedPost
  } = usePost();
  const { logger } = useAppContext();
  const { trackContentCreated, trackContentEdited } = useAnalytics();

  const clipDetails: ClipAssetMetadata = useMemo(() => clips[blockId] || null, [clips[blockId]]);
  // Check if the post is shared post or used with in the studio
  const isSharedPost =
    window.location.pathname.includes('/shared/post') || window.location.pathname.includes('/webinar');

  useEffect(() => {
    extractClipDetailsFromPostText(blockId, postText);
  }, [blockId]);

  const clipAssetIds = useMemo(() => getClipAssetIds(clipDetails), [clipDetails, getClipAssetIds]);

  const selectedClipId = useMemo(() => getSelectedClipId(clipDetails), [clipDetails, getSelectedClipId]);

  const handleRemoveClip = useCallback(() => {
    onRemoveClip();
    removeClipFromPost(blockId);
    return Promise.resolve();
  }, [onRemoveClip, removeClipFromPost, blockId]);

  const handleActiveClipChange = useCallback(
    (index: number) => {
      const updatedPostText = onActiveClipChange(blockId, index);
      onUpdatedPostText(updatedPostText);
    },
    [onActiveClipChange, blockId, onUpdatedPostText]
  );

  const onRegenerateClip = async () => {
    const { next_clip_info } = clipDetails;

    if (!selectedPost) return Promise.resolve();

    if (!next_clip_info.length) {
      showErrorToast('No more new clips found matching social post content.');
      return Promise.resolve();
    }

    try {
      trackContentCreated({
        asset: selectedPost,
        source: 'Regenerate',
        state: 'Started',
        isMultimodal: true
      });
      const clipStartEndTime = next_clip_info[0];
      const newClip = await createContentClip(clipStartEndTime[0], clipStartEndTime[1], 'AI');
      const updatedClipDetails = {
        ...clipDetails,
        clip_asset_ids: [...clipDetails.clip_asset_ids, newClip.id],
        next_clip_info: next_clip_info.slice(1),
        activeClipIndex: clipDetails?.activeClipIndex + 1,
        currentClipIndex: clipDetails?.activeClipIndex + 1
      };
      const updatedPostText = updateClipDetails(blockId, updatedClipDetails);
      onUpdatedPostText(updatedPostText);
      EventBus.dispatch(CustomEvents.ReloadAllClips);
      showSuccessToast('Clip regenerated successfully');
      trackContentCreated({
        asset: selectedPost,
        source: 'Regenerate',
        state: 'Created'
      });
      trackContentEdited({
        asset: selectedPost,
        isSuccess: true,
        updateType: 'RegenerateClip'
      });
      return Promise.resolve();
    } catch (error) {
      showErrorToast('Failed to regenerate clip');
      trackContentCreated({
        asset: selectedPost,
        source: 'Regenerate',
        state: 'Failed'
      });
      trackContentEdited({
        asset: selectedPost,
        isSuccess: false,
        updateType: 'RegenerateClip'
      });
      return Promise.resolve();
    }
  };

  useEffect(() => {
    if (!isReplaceClipDialogOpen && selectedBlockId === blockId) {
      const updatedPostText = createPostTextFromClipObject(clipDetails);
      onUpdatedPostText(updatedPostText);
    }
  }, [isReplaceClipDialogOpen, selectedBlockId, blockId, clipDetails]);

  const handleTranscriptError = useCallback(() => {
    logger.error('Something went wrong on our end');
  }, []);

  const replaceClip = () => {
    openReplaceClipDialog(blockId);
  };

  if (!selectedClipId) return null;

  return (
    <div className="relative h-full">
      <ClipPlayerWrapper clipId={selectedClipId} handleTranscriptError={handleTranscriptError} shared={isSharedPost}>
        {!isSharedPost ? (
          <PostClipWrapper
            openReplaceClipDialog={replaceClip}
            onRemoveClip={handleRemoveClip}
            onRegenerateClip={onRegenerateClip}
            activeClip={clipDetails?.currentClipIndex || 0}
            clipAssetIds={clipAssetIds}
            handleActiveClipChange={handleActiveClipChange}
          />
        ) : (
          <ClipPlayer />
        )}
      </ClipPlayerWrapper>
    </div>
  );
}

export default memo(PostPlayerWrapper);
