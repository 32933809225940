import { getEnvConfig } from '@/constants';

export type ClassType = string | string[] | Record<string, unknown>;

export function classnames(...classes: ClassType[]) {
  return classes
    .map(classType => {
      if (typeof classType === 'string') {
        return classType;
      }

      if (Array.isArray(classType)) return classType.filter(Boolean).join(' ');

      return Object.keys(classType)
        .map(key => (classType[key] ? key : false))
        .filter(Boolean)
        .join(' ');
    })
    .join(' ');
}

export function getColorForString(name: string) {
  const colors = [
    '#ef4444',
    '#dc2626',
    '#b91c1c',
    '#f97316',
    '#0c4a6e',
    '#3b82f6',
    '#2563eb',
    '#1d4ed8',
    '#1e40af',
    '#1e3a8a',
    '#6366f1',
    '#4f46e5',
    '#4338ca',
    '#3730a3',
    '#312e81',
    '#8b5cf6',
    '#7c3aed',
    '#6d28d9',
    '#5b21b6',
    '#4c1d95',
    '#a855f7',
    '#9333ea',
    '#7e22ce',
    '#6b21a8',
    '#581c87',
    '#15803d',
    '#166534',
    '#14532d',
    '#10b981',
    '#059669',
    '#047857',
    '#065f46',
    '#064e3b',
    '#14b8a6',
    '#0d9488',
    '#0f766e',
    '#115e59',
    '#134e4a',
    '#06b6d4',
    '#0891b2',
    '#ea580c',
    '#84cc16',
    '#65a30d',
    '#4d7c0f',
    '#3f6212',
    '#22c55e',
    '#0284c7',
    '#0369a1',
    '#075985',
    '#0e7490',
    '#155e75',
    '#164e63',
    '#0ea5e9',
    '#d946ef',
    '#c026d3',
    '#a21caf',
    '#86198f',
    '#701a75',
    '#ec4899',
    '#db2777',
    '#be185d',
    '#9d174d',
    '#831843',
    '#f43f5e',
    '#e11d48',
    '#be123c',
    '#9f1239',
    '#881337',
    '#16a34a'
  ];

  if (name.trim()) {
    return colors[Math.abs(name.charCodeAt(0) % colors.length)];
  }
  return '#6366f1';
}

export const CLOUDFRONT_ASSET_URL = (event: string, broadcast: string) => (suffix: string) =>
  `${getEnvConfig('CLOUDFRONT_ASSET_URL')}${event}/${broadcast}/recording/subtitles/${suffix}`;

export function getTrackSrc(event: string, broadcast: string) {
  return CLOUDFRONT_ASSET_URL(event, broadcast)('batch_transcript_en.vtt');
}

export function rgbaToHex(rgba: string, withAlpha = false): string {
  if (!rgba) {
    return '';
  }

  const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*(?:\.\d+)?(?:\.\d+)?)\)$/);
  if (!match) {
    console.error('Invalid rgba color', rgba);
    return rgba;
  }

  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);
  const a = Math.round(parseFloat(match[4]) * 255);

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');
  const hexA = a.toString(16).padStart(2, '0');

  if (withAlpha) {
    return `#${hexR}${hexG}${hexB}${hexA}`;
  }

  return `#${hexR}${hexG}${hexB}`;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export function getSpeakerImage(image?: string) {
  if (!image) return '';
  if (image.startsWith('http')) {
    return image;
  }
  return getEnvConfig('CLOUDFRONT_STATIC_ASSET_URL') + image;
}

export function isNil(value) {
  return value === null || value === undefined;
}

export function assignWith(target, ...sources) {
  if (target === null) {
    throw new TypeError('Cannot convert undefined or null to object');
  }

  for (const source of sources) {
    if (source !== null) {
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
  }

  return target;
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function toTitleCase(inputStr) {
  return inputStr.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const preventDefault = e => e.preventDefault();

export const toSnakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');

export function timeStringToSeconds(timeString): number {
  const components: number[] = timeString.split(':').map(parseFloat);
  const [hours, minutes, seconds] = components;
  return hours * 3600 + minutes * 60 + seconds;
}

export function isNumber(val: any): boolean {
  return typeof val === 'number' && !Number.isNaN(val);
}

export function roundToNDecimalPlaces(num: number, n: number) {
  return Math.floor(num * Math.pow(10, n)) / Math.pow(10, n);
}

export function imageSrcPath(str: string) {
  return `${getEnvConfig('VUE_APP_ASSETS_CDN')}/${str}`;
}

export function addDecimalIfNeeded(num) {
  // Convert number to string
  let numStr = num.toString();

  // Check if the string representation contains a decimal point
  if (numStr.indexOf('.') === -1) {
    // If no decimal point found, add ".0"
    numStr += '.0';
  }

  // Return the result as a number
  return parseFloat(numStr);
}

export function getCustomAssetsPath(): string {
  return `${getEnvConfig('STATIC_ASSETS_CDN')}${getEnvConfig('FILESTACK_BUCKET_PATH')}custom_assets/`;
}
