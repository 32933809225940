import {
  ClipAssetResponse,
  assetsAssetV2List,
  assetsClipCreate,
  assetsClipDestroy,
  assetsClipRetrieve,
  assetsClipUpdate
} from '@goldcast/api/content';
import { CaptionStyle, Clip, SourceType } from '@/domains/asset';
import { core, isAudioContent } from '@/stores/core';
import { getEnvConfig } from '@/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

const DEFAULT_AUDIO_CONTENT_MAGIC_LAYOUT = {
  // These should be default colors for custom upload audiogram
  backgroundColor: '#FF4200',
  backgroundImage: `${getEnvConfig(
    'CLOUDFRONT_STATIC_ASSET_URL'
  )}content-lab/filestack/brand_kit/templates/background/background-1.png`,
  textColor: '#ffffff'
};

const DEFAULT_MAGIC_LAYOUT = {
  backgroundColor: '#324bff',
  textColor: '#ffffff'
};

export function isCustomUpload(): boolean {
  const content = core.getSnapshot().content;
  return content?.media_source_type === 'UPLOAD';
}

export function createContentClip(start: number, end: number, source: SourceType): Promise<ClipAssetResponse> {
  const content = core.getSnapshot().content;
  const isAudio = isAudioContent();
  return assetsClipCreate({
    body: {
      content_id: content?.id as string,
      organization: content?.organization as string,
      asset_metadata: {
        start: +start,
        end: +end,
        size: 'LANDSCAPE',
        layout: isAudio ? 'AUDIOGRAM' : 'DEFAULT',
        source,
        highlight_type: 'text',
        subtitle: true,
        word_highlight_color: isAudio ? '#11184F' : '#330f80',
        magicLayout: isAudio ? DEFAULT_AUDIO_CONTENT_MAGIC_LAYOUT : DEFAULT_MAGIC_LAYOUT,
        is_edited: false,
        hide_borders: true,
        caption_styles: {
          scale: 1,
          animation: CaptionStyle.Outline
        }
      }
    }
  });
}

export function deleteContentClip(clipId: string): Promise<any> {
  return assetsClipDestroy({ id: clipId });
}

export function loadClip(id: string): Promise<any> {
  const featureFlags = featureFlagStore.getSnapshot();

  let queryParams = '?speakers=true';

  if (featureFlags[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB]) {
    queryParams += '&block_speaker_layouts=false';
  }

  return assetsClipRetrieve({ id: id + queryParams }).then((clip: ClipAssetResponse) => {
    const isAudio = isAudioContent();
    return {
      id: clip.id,
      isLoading: false,
      event: clip.content!.project.id,
      broadcast: clip.content!.id,
      organization: clip.asset_metadata.organization,
      title: clip.title,
      broadcast_data: {
        title: clip.content!.title
      },
      content: clip.content,
      asset_metadata: {
        ...(isCustomUpload() && { magicLayout: isAudio ? DEFAULT_AUDIO_CONTENT_MAGIC_LAYOUT : DEFAULT_MAGIC_LAYOUT }),
        ...clip.asset_metadata
      },
      rating: clip.rating,
      isRemoved: false,
      layout_status: clip.layout_status,
      shared: clip.shared,
      locked: clip?.locked,
      updated_at: clip.updated_at,
      first_frame_analysis: clip.first_frame_analysis,
      saved_search: clip.saved_search
    };
  });
}

export function updateClip(clip: Clip): Promise<any> {
  const modifiedClip = { ...clip, asset_metadata: { ...clip.asset_metadata } };
  modifiedClip.asset_metadata.captions = [];
  const isAudio = isAudioContent();

  return assetsClipUpdate({
    id: modifiedClip.id,
    body: modifiedClip
  }).then((clip: ClipAssetResponse) => {
    return {
      id: clip.id,
      event: clip.content!.project.id,
      broadcast: clip.content!.id,
      organization: clip.asset_metadata.organization,
      title: clip.title,
      broadcast_data: {
        title: clip.content!.title
      },
      asset_metadata: {
        ...(isCustomUpload() && { magicLayout: isAudio ? DEFAULT_AUDIO_CONTENT_MAGIC_LAYOUT : DEFAULT_MAGIC_LAYOUT }),
        ...clip.asset_metadata
      },
      rating: clip.rating,
      isRemoved: false,
      layout_status: clip.layout_status,
      shared: clip.shared
    };
  });
}

export function loadClips(broadcastId: string): Promise<Clip[]> {
  return assetsAssetV2List({
    queryParams: {
      contents: broadcastId,
      types: 'CLIP',
      limit: 1000,
      ordering: '-updated_at'
    }
  }).then(({ results }) => results as unknown as Clip[]);
}
