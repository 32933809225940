import { memo, useCallback, useState } from 'react';
import IconButton from '@/components/atoms/Button/IconButton';

function CreateAISearchClipsButton({ generateClips }: { generateClips: () => Promise<void> }) {
  const [isLoading, setIsLoading] = useState(false);

  const generateMore = useCallback(() => {
    setIsLoading(true);
    generateClips().finally(() => {
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <IconButton
      icon="IconLoader2"
      content=""
      variation="filled"
      size="full"
      disabled={true}
      iconClassName="animate-spin"
      trackingId="loading-more-ai-search-clips-button"
    />
  ) : (
    <IconButton
      icon="IconPlus"
      content="Create Clips"
      variation="filled"
      size="full"
      trackingId="genereate-more-ai-search-clips-button"
      onClick={generateMore}
    />
  );
}

export default memo(CreateAISearchClipsButton);
