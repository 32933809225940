import { useSyncExternalStore } from 'react';
import { useQuery } from 'react-query';
import BrandKitItem from './BrandKitItem';
import BrandKitSkeleton from './BrandKitSkeleton';
import { BRAND_KIT_DEFAULT_CONFIGURATION } from './constants';
import { loadBrandKitAndVoiceProfile } from '@/stores/brandKit';
import { showErrorToast } from '@/libs/toast/toast';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function BrandKit(): JSX.Element {
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isBrandToneEnabled = featureFlags[FeatureFlagKeys.Use_CL_Brand_Tone];

  const { isFetching: isLoading } = useQuery({
    queryKey: 'brand-kit',
    queryFn: () => loadBrandKitAndVoiceProfile(),
    onError: () =>
      showErrorToast(
        "Just a heads up, we're having a bit of trouble loading your brand kit data. Give it another moment, then try refreshing."
      )
  });

  return (
    <div className="min-h-[calc(100vh-4.25rem)] grow overflow-hidden bg-slate-50">
      <div className="flex w-full items-center justify-between pl-7 pr-4 pt-6">
        <h3 className="text-xl font-medium tracking-tight text-black lg:text-2xl">Brand Kit</h3>
      </div>
      <div className="mt-4 space-y-4 pb-8 pl-7 pr-4">
        {isLoading ? (
          <BrandKitSkeleton />
        ) : (
          Object.keys(BRAND_KIT_DEFAULT_CONFIGURATION).map(key => {
            if (key === 'brand_tone' && !isBrandToneEnabled) {
              return null;
            }
            return <BrandKitItem configurationItem={BRAND_KIT_DEFAULT_CONFIGURATION[key]} key={key} />;
          })
        )}
      </div>
    </div>
  );
}
