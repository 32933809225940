import { useCallback, useSyncExternalStore } from 'react';
import { AssetList, ClipAssetResponse, TextAssetResponse, TypeEnum } from '@goldcast/api/content';
import { Location } from 'react-router-dom';
import { useAppContext } from '@/context/AppContext/AppContext';
import { core } from '@/stores/core';
import { Clip } from '@/domains/asset';
import { orgUsageSummaryState } from '@/stores/orgUsageSummary';
import { getImportLinkType } from '@/Pages/GenerateContent/utils';
import { TextAsset } from '@/Pages/PostsListPage/types';

export default function useAnalytics() {
  const { analytics } = useAppContext();

  const isSharedPage = window.location.pathname.includes('shared');
  const isSocialPost = window.location.pathname.includes('social');
  const isBlogPost = window.location.pathname.includes('blog');

  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);

  return {
    trackRouteChange: useCallback(
      (previousLocation: Location | undefined, toLocation: Location) => {
        analytics.track('Route change', {
          from: previousLocation?.pathname,
          fromName: previousLocation?.pathname,
          to: toLocation.pathname,
          toName: toLocation.pathname
        });
      },
      [analytics]
    ),
    trackContentEdited: useCallback(
      ({
        asset,
        isSuccess,
        updateType
      }: {
        asset: TextAsset;
        isSuccess: boolean;
        updateType: 'TextChange' | 'RegenerateClip' | 'ReplaceClip';
      }) => {
        analytics.track('ContentLabEvent', {
          name: 'ContentEdited',
          title: asset.title,
          id: asset.id,
          isSuccess,
          updateType,
          contentType: asset.asset_metadata.type,
          isCustomUpload: coreStore.content?.media_source_type === 'UPLOAD',
          isSample: coreStore.content?.is_sample_upload
        });
      },
      [analytics, coreStore.content]
    ),
    trackContentCreated: useCallback(
      ({
        source,
        asset,
        state,
        count,
        assetType = 'CLIP',
        failureReason,
        isMultimodal = false,
        isPromptChanged,
        prompt
      }: {
        source: 'Autogenerate' | 'Manual' | 'Prompt' | 'Regenerate' | 'ClipSocialPost';
        asset?: AssetList | Clip | ClipAssetResponse | TextAsset;
        assetType?: string;
        state: 'Created' | 'Failed' | 'Started';
        count?: number;
        failureReason?: string;
        isMultimodal?: boolean;
        isPromptChanged?: boolean;
        prompt?: string;
      }) => {
        analytics.track('ContentLabEvent', {
          name: 'ContentCreated',
          source,
          state,
          isSuccess: state !== 'Failed',
          assetType: assetType,
          isCustomUpload: coreStore.content?.media_source_type === 'UPLOAD',
          failureReason,
          isPromptChanged,
          prompt,
          isMultimodal,
          ...(!!asset
            ? {
                contentId: asset.content?.id,
                contentTitle: asset.content?.title,
                id: asset.id,
                title: asset.title,
                projectId: asset.content?.project.id,
                isGeneratedFromSampleVideo: coreStore.content?.is_sample_upload
              }
            : {}),
          ...(!!count ? { count } : {})
        });
      },
      [analytics, coreStore.content]
    ),
    trackSpeakersIdentified: useCallback(() => {
      analytics.track('ContentLabEvent', {
        name: 'SpeakersIdentified',
        isCustomUpload: !!coreStore.uploadData?.id,
        uploadId: coreStore.uploadData?.id
      });
    }, [analytics, coreStore.uploadData]),
    trackTextFetched: useCallback(
      ({ count }: { count: number }) => {
        analytics.track('ContentLabEvent', {
          name: 'FetchedTexts',
          count
        });
      },
      [analytics]
    ),
    trackContentLabUsage: useCallback(
      ({
        asset,
        isPlayClip,
        mediaUploadedCount
      }: {
        asset?: AssetList | Clip;
        isPlayClip?: boolean;
        mediaUploadedCount?: number;
      } = {}) => {
        const orgUsageSummaryStateSnapshot = orgUsageSummaryState.getSnapshot();
        analytics.track('ContentLabEvent', {
          name: 'ContentLabUsage',
          isSample: coreStore.content?.is_sample_upload,
          totalClipsCount: orgUsageSummaryStateSnapshot.usage.clips.used,
          totalTextSummariesCount: orgUsageSummaryStateSnapshot.usage.text.used,
          assetId: asset?.id,
          assetTitle: asset?.title,
          assetType: (asset as AssetList)?.type !== undefined ? (asset as AssetList).type : 'CLIP',
          isPlayClip,
          isSharedPage,
          isSocialPost,
          isBlogPost,
          isOpened: !!asset,
          mediaUploadedCount
        });
      },
      [analytics, coreStore.content?.is_sample_upload, isSharedPage, isSocialPost, isBlogPost]
    ),
    trackFetchedClips: useCallback(
      ({ count }: { count: number }) => {
        analytics.track('ContentLabEvent', {
          name: 'FetchedClips',
          count
        });
      },
      [analytics]
    ),
    trackTextCopiedToClipboard: useCallback(
      (post: TextAsset) => {
        analytics.track('ContentLabEvent', {
          name: 'TextCopiedToClipboard',
          isCustomUpload: coreStore.content?.media_source_type === 'UPLOAD',
          contentType: post.asset_metadata.type,
          id: post.id
        });
      },
      [analytics, coreStore.content?.media_source_type]
    ),
    trackTextEmailed: useCallback(() => {
      analytics.track('ContentLabEvent', {
        name: 'TextEmailed',
        isCustomUpload: coreStore.content?.media_source_type === 'UPLOAD'
      });
    }, [analytics, coreStore.content]),
    trackSampleAssetPlayed: useCallback(() => {
      analytics.track('ContentLabEvent', {
        name: 'SampleAssetPlayed',
        assetId: coreStore.content?.id,
        isSampleAsset: coreStore.content?.is_sample_upload
      });
    }, [analytics, coreStore.content]),
    trackUsageCapLimitReached: useCallback(
      (entity: 'CLIPS' | 'VIDEO_UPLOAD') => {
        analytics.track('ContentLabEvent', {
          name: 'UsageCapLimitReached',
          entity,
          timestamp: new Date().toISOString()
        });
      },
      [analytics]
    ),
    trackDownloadContent: useCallback(
      ({
        clip,
        textAsset,
        assetType,
        state,
        isUpscaled = false
      }: {
        clip?;
        textAsset?: TextAsset | null;
        assetType: TypeEnum;
        state: 'Autodownload' | 'ButtonClick' | 'ToastButtonClick' | 'Failed';
        isUpscaled?: boolean;
      }) => {
        analytics.track('ContentLabEvent', {
          name: 'DownloadContent',
          assetType,
          isSuccessful: state !== 'Failed',
          isUpscaled,
          isSocialPost,
          isBlogPost,
          state,
          url: window.location.hostname + window.location.pathname,
          ...(!clip
            ? {}
            : {
                clipId: clip.id,
                layout: clip.asset_metadata.layout,
                title: clip.title,
                isCustomUpload: clip.content.media_source_type === 'UPLOAD',
                is_sample_upload: clip.content.is_sample_upload,
                generatedBy: clip.asset_metadata.source
              }),
          ...(!textAsset
            ? {}
            : {
                title: textAsset.title,
                isMultiModal: textAsset.content?.multi_modal_enabled,
                contentId: textAsset.content?.id,
                contentType: textAsset?.asset_metadata?.type
              })
        });
      },
      [analytics, isSocialPost, isBlogPost]
    ),
    trackVideoUpload: useCallback(
      ({
        uploadType,
        failureReason,
        url,
        status
      }: {
        uploadType: 'Upload' | 'Import';
        status: 'Completed' | 'Failed' | 'Started' | 'Disabled';
        failureReason?: string;
        url?: string;
      }) => {
        analytics.track('ContentLabEvent', {
          name: 'VideoUpload',
          uploaded: 1,
          status,
          count: 1,
          currentPage: window.location.pathname,
          uploadType,
          urlType: !!url ? getImportLinkType(url) : undefined,
          isSuccess: status === 'Completed',
          failureReason,
          url
        });
      },
      [analytics]
    ),
    trackContentShare: useCallback(
      ({
        asset,
        contentType,
        source,
        shareType,
        isSuccess = true
      }: {
        asset: TextAssetResponse | Clip | TextAsset | null;
        contentType: string;
        source: 'EditorPage' | 'SharedPage';
        shareType: 'CopyURL' | 'OpenPopup';
        isSuccess?: boolean;
      }) => {
        analytics.track('ContentLabEvent', {
          name: 'ContentShare',
          shareType,
          contentType,
          source,
          isSuccess,
          title: asset?.title,
          id: asset?.id
        });
      },
      [analytics]
    ),
    trackAiSearch: useCallback(
      ({ term, contentType }: { term: string; contentType: string }) => {
        analytics.track('ContentLabEvent', {
          name: 'AiSearchInitiated',
          term,
          contentType
        });
      },
      [analytics]
    )
  };
}
