import React, { useCallback, useEffect, useRef, useSyncExternalStore } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import RecentEventsSkeleton from './RecentEventsSkeleton';
import NoData from './NoData';
import SessionPreview from './SessionPreview';
import useMediaContent from '../useMediaContent';
import ErrorState from './ErrorState';
import ContentDialog from '../uiComponents/ContentDialog/ContentDialog';
import SessionsListModal from '../SessionsListModal/SessionsListModal';
import UploadFileButton from './UploadFileButton';
import SavedSearchInput from './AiClips/SavedSearchInput';
import { currentUser } from '@/stores/user';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import Button from '@/components/atoms/Button/Button';
import { useAppContext } from '@/context/AppContext/AppContext';
import useDialog from '@/components/organisms/useDialog';
import { usePusherContext } from '@/context/PusherContext/PusherContext';
import useContentUploadHook from '@/hooks/useContentUploadHook';
import { ContentStatesEnum } from '@/Pages/GenerateContent/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

export default function RecentEvents({ setIsOpen }) {
  const { mediaContent, showErrorState, fetchData } = useMediaContent();
  const { isPusherConnected } = usePusherContext();
  const { changeContentUploadStatus, contentUploadList } = useContentUploadHook();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const idsRef = useRef<string[]>([]);
  const navigate = useNavigate();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isAIClipsEnabled = featureFlags[FeatureFlagKeys.Use_CL_AI_Clips];

  const { isFetching: loading } = useQuery({
    queryKey: 'recent-events',
    queryFn: () => loadMediaContent()
  });

  const {
    isOpen: isSessionsListDialogOpen,
    openDialog: openSessionsListDialog,
    closeDialog: closeSessionsListDialog
  } = useDialog();

  function loadMediaContent(idsArray?: string[]) {
    return fetchData({
      organization: currentUser.getSnapshot()?.organization as string,
      limit: 5,
      ...(idsArray && { idsArray })
    });
  }

  const openSessionsDialog = useCallback(() => {
    if (featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]) {
      navigate('/import');
    } else {
      setIsOpen(true);
    }
  }, [setIsOpen, featureFlags]);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.UploadContentDone, loadMediaContent);

    return () => {
      EventBus.off(CustomEvents.UploadContentDone, eventListener);
    };
  }, []);

  const loadNewContent = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;

    loadMediaContent([...idsRef.current]);

    idsRef.current = [];
  };

  const handleContentStatusUpdate = (data, type: ContentStatesEnum) => {
    if (mediaContent.some(content => content.id === data.upload_id) && !idsRef.current.includes(data.upload_id)) {
      idsRef.current.push(data.upload_id);
    }
    if (contentUploadList[data.upload_id]) {
      changeContentUploadStatus(data, type, data.upload_id);
    }
    if (!timeoutRef.current && idsRef.current.length > 0) {
      timeoutRef.current = setTimeout(() => {
        loadNewContent();
      }, 5000);
    }
  };

  const pollForUpdatedState = () => {
    if (mediaContent.find(c => c.media_source_type === 'UPLOAD' && c.batch_status === 'PROCESSING')) {
      return setTimeout(() => {
        loadMediaContent();
      }, 30000);
    }
  };

  useEffect(() => {
    let contentProcessingListener;
    let clipGenerationListener;
    let pollTimeoutId;
    if (isPusherConnected) {
      contentProcessingListener = EventBus.on(CustomEvents.TranscriptionStatusUpdated, data =>
        handleContentStatusUpdate(data, ContentStatesEnum.Processing)
      );
      clipGenerationListener = EventBus.on(CustomEvents.ClipGenerationStatusUpdated, data =>
        handleContentStatusUpdate(data, ContentStatesEnum.GeneratingCompleted)
      );
    } else {
      pollTimeoutId = pollForUpdatedState();
    }

    return () => {
      if (pollTimeoutId) {
        clearTimeout(pollTimeoutId);
      }
      if (contentProcessingListener) {
        EventBus.off(CustomEvents.TranscriptionStatusUpdated, contentProcessingListener);
      }
      if (clipGenerationListener) {
        EventBus.off(CustomEvents.TranscriptionStatusUpdated, clipGenerationListener);
      }
    };
  }, [mediaContent, isPusherConnected]);

  useEffect(() => {
    if (featureFlags[FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]) {
      return;
    }

    const eventListener = EventBus.on(CustomEvents.UploadContentStarted, ({ projectId, contentId }) => {
      navigate(`/${projectId}/${contentId}/clips`);
    });
    return () => {
      EventBus.off(CustomEvents.UploadContentStarted, eventListener);
    };
  }, [navigate, featureFlags]);

  const goToEventsPage = useCallback(() => {
    window.location.replace('/events');
  }, []);

  const noData = !loading && mediaContent.length === 0;

  const {
    adminAppStore: { user: { is_content_lab_standalone } = { is_content_lab_standalone: false } }
  } = useAppContext();

  return (
    <div className="mb-8">
      <div className="mb-3 flex w-full items-center justify-between pl-7 pr-4 pt-6">
        <div className="text-2xl font-medium leading-tight tracking-tighter text-black">
          {is_content_lab_standalone ? 'Let’s unleash your content!' : 'All Recordings'}
        </div>
        {isAIClipsEnabled && <SavedSearchInput />}

        <div>
          <Button variation="list" onClick={openSessionsListDialog} disabled={noData} trackingId="show-all-button">
            Show all
          </Button>
        </div>
      </div>

      <div className="no-scrollbar flex w-full space-x-4 overflow-y-scroll px-7 pb-5 pr-4 pt-2">
        {loading ? (
          <RecentEventsSkeleton />
        ) : showErrorState ? (
          <ErrorState />
        ) : noData ? (
          <NoData>
            <button
              className="mt-3 rounded-lg border border-slate-200 px-3 py-2 text-xs font-medium"
              onClick={is_content_lab_standalone ? openSessionsDialog : goToEventsPage}
            >
              {is_content_lab_standalone ? 'Upload recording' : 'Go to events'}
            </button>
          </NoData>
        ) : (
          <React.Fragment>
            <UploadFileButton openSessionsDialog={openSessionsDialog} />
            {mediaContent.map(session => {
              return <SessionPreview session={session} key={session.id} onDeleteSession={loadMediaContent} />;
            })}
          </React.Fragment>
        )}
      </div>
      <ContentDialog
        isOpen={isSessionsListDialogOpen}
        setIsOpen={closeSessionsListDialog}
        title="All Recordings"
        size="xlarge"
      >
        <SessionsListModal />
      </ContentDialog>
    </div>
  );
}
