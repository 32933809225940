import { Component } from 'react';
import { useAppContext } from '@/context/AppContext/AppContext';

class ErrorBoundaryForComponentBase extends Component<
  {
    children: JSX.Element;
    customError?: string;
    logger: any;
  },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.logger.error('ErrorBoundaryForComponent caught an error:', {
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      return <h1>{this.props.customError || 'Something went wrong.'}</h1>;
    }

    return this.props.children;
  }
}

export default function ErrorBoundaryForComponent({
  children,
  customError
}: {
  children: JSX.Element;
  customError?: string;
}) {
  const { logger } = useAppContext();

  return (
    <ErrorBoundaryForComponentBase logger={logger} customError={customError}>
      {children}
    </ErrorBoundaryForComponentBase>
  );
}
