import { useSyncExternalStore } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { assetsContentUploadDestroy, userSpeakerResetCreate } from '@goldcast/api/content';
import { useMutation } from 'react-query';
import useDialog from '@/components/organisms/useDialog';
import { core } from '@/stores/core';
import Button from '@/components/atoms/Button/Button';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import Loader from '@/components/atoms/Loader';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { showErrorToast } from '@/libs/toast/toast';
import { useAppContext } from '@/context/AppContext/AppContext';
import { setNewCacheSuffix } from '@/context/TranscriptContext/TranscriptContextUtils';

export default function SessionConfigActions() {
  const transcriptStore = useTranscriptContext();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const { clipId } = useParams<{ clipId: string }>();
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  const {
    isOpen: isResetSpeakersConfirmationOpen,
    openDialog: openResetSpeakersConfirmationDialog,
    closeDialog: closeResetSpeakersConfirmationDialog
  } = useDialog();

  const navigate = useNavigate();
  const { logger } = useAppContext();

  function deleteRecording() {
    assetsContentUploadDestroy({ id: coreStore.uploadData!.id })
      .then(() => {
        navigate('/');
      })
      .catch(error => {
        closeDeleteConfirmationDialog();
        logger.error(error);
        showErrorToast("Whoops! Deleting the recording didn't go as planned. Check your connection and try again.");
      });
  }

  const { mutate: resetRecordingSpeakers, isLoading: isResetting } = useMutation(userSpeakerResetCreate, {
    onSuccess: updateSpeakers,
    onError: handleResetError
  });

  function resetSpeakers() {
    closeResetSpeakersConfirmationDialog();
    resetRecordingSpeakers({
      body: {
        content_id: coreStore.content!.id
      }
    });
  }

  function updateSpeakers() {
    EventBus.dispatch(CustomEvents.ReloadTranscript);
    EventBus.dispatch(CustomEvents.ReloadClip, { showLoader: true });
    core.update(data => ({ ...data, content: { ...data.content, allow_reset_speakers: false } }));

    setNewCacheSuffix(Math.random().toString());
  }

  function handleResetError(error) {
    logger.error(error);
    showErrorToast('Resetting the speaker encountered an issue. Refresh and give it another go.');
  }

  const speakersCount = Object.keys(transcriptStore.speakersWithDetails).length;

  return (
    <>
      {(speakersCount > 1 || coreStore.content?.allow_reset_speakers) && (
        <div className="flex flex-row items-center border-t border-t-slate-200 p-5 text-xs">
          <span>Reset all speakers to the original state</span>
          <Button
            variation="warn"
            buttonSize="small"
            disabled={!coreStore.content?.allow_reset_speakers || isResetting}
            className="ml-auto"
            trackingId="reset-speakers-button"
            onClick={openResetSpeakersConfirmationDialog}
          >
            {isResetting && <Loader size="xs" />} Reset Speakers
          </Button>
        </div>
      )}
      {!clipId && coreStore.uploadData && (
        <div className="flex flex-row items-center border-t border-t-slate-200 p-5 text-xs">
          <span>Delete recording and all associated data</span>
          <Button
            variation="warn"
            buttonSize="small"
            className="ml-auto"
            trackingId="delete-recording-button"
            onClick={openDeleteConfirmationDialog}
          >
            Delete Recording
          </Button>
        </div>
      )}
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={deleteRecording}
        title="Delete Recording"
        content="Are you sure you want to permanently remove this recording? This action is irreversible."
        confirmLabel="Yes"
      />
      <ConfirmationDialog
        isOpen={isResetSpeakersConfirmationOpen}
        onClose={closeResetSpeakersConfirmationDialog}
        onConfirm={resetSpeakers}
        title="Reset Speakers"
        content="Are you sure you want to reset speakers to initial state? This action is irreversible."
        confirmLabel="Yes"
      />
    </>
  );
}
