import { memo, useCallback } from 'react';
import { VoiceProfile } from '@goldcast/api/content';
import { VoiceProfileSteps } from './constants';
import IconButton from '@/components/atoms/Button/IconButton';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import useDialog from '@/components/organisms/useDialog';
import { deleteVoiceProfile, updateSelectedVoiceProfile, updateActiveStep } from '@/stores/voiceProfile';
import { showSuccessToast, showErrorToast } from '@/libs/toast/toast';

function VoiceProfilePreview({ voiceProfile }: { voiceProfile: VoiceProfile }): JSX.Element {
  const {
    isOpen: isDeleteConfirmationOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog
  } = useDialog();

  const handleDeleteVoiceProfile = async () => {
    try {
      await deleteVoiceProfile(voiceProfile.id);
      showSuccessToast('Voice profile deleted successfully');
      closeDeleteConfirmationDialog();
    } catch (err: any) {
      showErrorToast(err?.message || 'Failed to delete voice profile');
    }
  };

  const onEditVoiceProfile = useCallback(() => {
    updateSelectedVoiceProfile(voiceProfile);
    updateActiveStep(VoiceProfileSteps.TuneVoiceProfile);
  }, [voiceProfile]);

  return (
    <div className="flex aspect-[16/11] w-56 flex-col items-center justify-center overflow-hidden rounded-lg border border-slate-200">
      <div className="relative w-full grow border-b bg-slate-200">
        {voiceProfile.default && (
          <div className="absolute right-2 top-2">
            <span className="inline-flex items-center space-x-1 rounded-full bg-slate-50 px-2 py-1 text-center text-xs text-slate-600">
              Default
            </span>
          </div>
        )}
      </div>
      <div className="flex w-full items-center justify-between space-x-1.5  px-2.5">
        <div className="grow items-center truncate py-3 text-xs">{voiceProfile.name}</div>
        <div className="flex shrink-0 items-center gap-1">
          <IconButton
            icon="IconEdit"
            size="small"
            variation="text"
            onClick={onEditVoiceProfile}
            trackingId="edit-voice-profile-button"
          />
          <IconButton
            icon="IconTrash"
            size="small"
            variation="text"
            onClick={openDeleteConfirmationDialog}
            trackingId="delete-voice-profile-button"
          />
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmationDialog}
        onConfirm={handleDeleteVoiceProfile}
        title="Delete Voice Profile?"
        content="Are you sure you want to delete this brand tone?"
        confirmLabel="Delete"
      />
    </div>
  );
}
export default memo(VoiceProfilePreview);
