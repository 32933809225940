import React, { useEffect, useMemo, useRef } from 'react';
import { getFacePositionsForGrid } from '../CanvasPlayerUtils';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

export default function PreviewSpeakerVideoCanvas({ mainPlayer, secondaryPlayers, currentSpeaker, clipMetadata }) {
  const videoCanvasRef = useRef<HTMLCanvasElement>(null);
  const { clipId, speakersAnalysis } = useClipsContext();

  const showCaption = !!clipMetadata.subtitle;

  const clipSize = clipMetadata.size;
  const clipLayout = clipMetadata.layout;

  const { originalWidth, originalHeight } = useMemo(() => {
    let originalWidth = 0;
    let originalHeight = 0;

    if (mainPlayer.current) {
      // We may want to remove naturalWidth and naturalHeight from the condition.
      // It was added to be able to use this component for interim thumbnail images.
      originalWidth = mainPlayer.current.videoWidth || (mainPlayer.current as unknown as HTMLImageElement).naturalWidth;
      originalHeight =
        mainPlayer.current.videoHeight || (mainPlayer.current as unknown as HTMLImageElement).naturalHeight;
    }

    return { originalWidth, originalHeight };
  }, []);

  // config used for video canvas
  const videoSizeConfig = useMemo(
    () => getSizeConfig(clipSize, clipId, clipLayout, 0.5),
    [clipSize, clipId, clipLayout]
  );

  useEffect(() => {
    const videoCanvas = videoCanvasRef.current;
    if (!videoCanvas) return;

    const { width, height } = videoSizeConfig.getHeightAndWidth();

    videoCanvas.width = width;
    videoCanvas.height = height;

    computeFrame();
  }, [videoSizeConfig]);

  useEffect(() => {
    computeFrame();
  }, [videoCanvasRef, mainPlayer, speakersAnalysis, secondaryPlayers.length]);

  function drawSingleVideoOnCanvas(
    video,
    videoWidth,
    videoHeight,
    source_crop_x_offset = 0,
    source_crop_y_offset = 0,
    shouldUseDefaultLayoutValues = false
  ) {
    const videoCanvasContext = videoCanvasRef.current?.getContext('2d');
    if (!videoCanvasContext) return;

    videoSizeConfig.drawVideo(videoCanvasContext, video, {
      videoWidth,
      videoHeight,
      showCaption,
      source_crop_x_offset,
      source_crop_y_offset,
      clipMetadata,
      shouldUseDefaultLayoutValues
    });
  }

  function drawOnCanvasForDefault() {
    drawSingleVideoOnCanvas(mainPlayer.current!, originalWidth, originalHeight, 0, 0, true);
  }

  function drawOnCanvasForSpeakerRecording() {
    const videoRef = secondaryPlayers.find(player => player.current?.id === currentSpeaker?.key);
    if (!videoRef?.current) return;
    drawSingleVideoOnCanvas(videoRef.current!, videoRef.current.videoWidth, videoRef.current.videoHeight);
  }

  function drawOnCanvasForGridRecording(videoCanvasContext) {
    if (clipMetadata.visible_speakers && clipMetadata.visible_speakers?.length > 0) {
      clipMetadata.visible_speakers.forEach((speaker, index) => {
        const videoRef = secondaryPlayers.find(player => player.current?.id === speaker.key);
        if (!videoRef?.current) return;
        videoSizeConfig.drawVideo(videoCanvasContext, videoRef.current, {
          videoWidth: videoRef.current.videoWidth,
          videoHeight: videoRef.current.videoHeight,
          showCaption,
          totalVideos: clipMetadata.visible_speakers!.length,
          currentVideoNumber: index + 1,
          clipMetadata
        });
      });
    }
  }

  function drawOnCanvasForUpload(clipLayout, videoCanvasContext, index) {
    let facePositions =
      clipLayout === 'SPEAKER'
        ? speakersAnalysis.time_analysis[index].face_positions
        : getFacePositionsForGrid(index, speakersAnalysis);

    if (clipLayout === 'SPEAKER' && facePositions.length > 1) {
      facePositions = facePositions.filter(facePosition =>
        speakersAnalysis.speaker_mapping?.[currentSpeaker?.id]?.includes(facePosition.face_id)
      );
    }

    if (facePositions.length === 0) {
      drawOnCanvasForDefault();
      return;
    }

    facePositions.forEach((facePosition, index) => {
      const cropPosition = facePosition.crop_position || facePosition.crop_position_face;
      if (!cropPosition) {
        return;
      }

      videoSizeConfig.drawVideo(videoCanvasContext, mainPlayer.current!, {
        videoWidth: originalWidth * (cropPosition.bottom_right.x - cropPosition.top_left.x),
        videoHeight: originalHeight * (cropPosition.bottom_right.y - cropPosition.top_left.y),
        showCaption,
        source_crop_x_offset: Math.round(originalWidth * cropPosition.top_left.x),
        source_crop_y_offset: Math.round(originalHeight * cropPosition.top_left.y),
        totalVideos: facePositions.length,
        currentVideoNumber: index + 1,
        clipMetadata,
        optionsForNewSpeakerMapping: {
          allPositions: {
            crop_position: facePosition.crop_position,
            crop_position_face: facePosition.crop_position_face,
            crop_position_person: facePosition.crop_position_person
          },
          originalVideoWidth: originalWidth,
          originalVideoHeight: originalHeight
        }
      });
    });
  }

  function computeFrame() {
    if (!currentSpeaker && clipMetadata.layout !== 'DEFAULT') return;

    const videoCanvasContext = videoCanvasRef.current?.getContext('2d');
    if (!videoCanvasContext) return;

    videoCanvasContext.clearRect(0, 0, videoSizeConfig.getWidth(), videoSizeConfig.getHeight());

    if (clipMetadata.layout === 'DEFAULT') {
      drawOnCanvasForDefault();
      return;
    }

    const currentTime =
      mainPlayer.current!.currentTime ||
      parseFloat(mainPlayer.current.attributes?.currenttime?.value || '0') || // For interim thumbnail
      0;
    const index = speakersAnalysis.times.findLastIndex(t => t <= currentTime);

    if (index === -1) {
      if (clipMetadata.layout === 'SPEAKER') {
        drawOnCanvasForSpeakerRecording();
      } else if (clipMetadata.layout === 'GRID') {
        drawOnCanvasForGridRecording(videoCanvasContext);
      }
      return;
    } else {
      if (clipMetadata.layout === 'SPEAKER' || clipMetadata.layout === 'GRID') {
        drawOnCanvasForUpload(clipMetadata.layout, videoCanvasContext, index);
      }
    }
  }

  return <canvas id="video-canvas" ref={videoCanvasRef} className="absolute top-0 h-full w-full" />;
}
