import { memo, useSyncExternalStore } from 'react';
import { VoiceProfile } from '@goldcast/api/content';
import { tones } from '../constants';
import { SelectedVoiceProfile, TextSelectionType } from '../types';
import { classnames } from '@/libs/utils';
import useVoiceProfile from '@/hooks/useVoiceProfile';
import IconButton from '@/components/atoms/Button/IconButton';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';

function BrandVoiceTone({
  selectedVoiceProfile,
  onSelectedVoiceProfile,
  onCreateVoiceProfile
}: {
  selectedVoiceProfile: string;
  onSelectedVoiceProfile: ({ value, type }: SelectedVoiceProfile) => void;
  onCreateVoiceProfile: () => void;
}): JSX.Element {
  const { voiceProfiles } = useVoiceProfile();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const isBrandToneEnabled = featureFlags[FeatureFlagKeys.Use_CL_Brand_Tone];

  return (
    <>
      <div className="text-lg font-medium">Tone of Voice</div>
      <div className="text-sm text-slate-600">Craft your communications with on-brand character</div>
      <div className="mt-4 flex flex-wrap gap-2">
        {isBrandToneEnabled && (
          <>
            <div className="w-full text-xs text-slate-600">Saved Voice Profiles</div>
            {voiceProfiles.map((voiceProfile: VoiceProfile) => (
              <button
                key={voiceProfile.id}
                data-testid="saved-voice-profile-button"
                onClick={() => onSelectedVoiceProfile({ value: voiceProfile.id, type: 'custom' })}
                className={classnames(
                  'flex items-center space-x-2 rounded-full border px-7 py-3 text-sm text-slate-950',
                  voiceProfile.id !== selectedVoiceProfile
                    ? 'hover:bg-slate-100'
                    : 'bg-slate-100 ring-2 ring-slate-950 focus:ring-2'
                )}
              >
                <div>{voiceProfile.name}</div>
              </button>
            ))}
            <IconButton
              content="Create Voice Profile"
              trackingId="create-voice-profile"
              icon="IconPlus"
              buttonClassName="!rounded-full px-7 py-3 bg-slate-100 text-sm font-medium space-x-2 hover:!bg-slate-200 !ring-transparent focus:!ring-slate-100 hover:!text-slate-950"
              onClick={onCreateVoiceProfile}
            />
          </>
        )}
        <div className="mb-0.5 mt-3 w-full text-xs text-slate-600">Default Voice Profiles</div>
        {tones.map((tone: TextSelectionType) => (
          <button
            key={tone.value}
            data-testid="default-voice-profile-button"
            onClick={() => onSelectedVoiceProfile({ value: tone.value, type: 'default' })}
            className={classnames(
              'flex items-center space-x-2 rounded-full border px-7 py-3 text-sm text-slate-950',
              tone.value !== selectedVoiceProfile
                ? 'hover:bg-slate-100'
                : 'bg-slate-100 ring-2 ring-slate-950 focus:ring-2'
            )}
          >
            <div className="mr-1 text-xl">{tone.icon}</div>
            <div>{tone.label}</div>
          </button>
        ))}
      </div>
    </>
  );
}

export default memo(BrandVoiceTone);
