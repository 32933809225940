import { memo } from 'react';
import NewContentModal from '../Sessions/NewContentModal/NewContentModal';

function VideoImportUploadDialogPage() {
  return (
    <div className="z-[100] flex h-full w-full grow flex-col items-center justify-center">
      <div className="relative z-20 flex h-[33rem] w-[50rem] translate-y-2 flex-col rounded-2xl border border-slate-200 bg-[rgba(250,250,252,0.92)] py-6 pb-4 backdrop-blur-[20px] backdrop-saturate-[180%] transition-all duration-[750ms] ease-in-out">
        <NewContentModal
          title="Import Your Video"
          description="Get weeks worth of content out of your webinars, product demos, video podcasts, or any other long-form video content."
        />
      </div>
    </div>
  );
}

export default memo(VideoImportUploadDialogPage);
