import React, { useEffect, useRef, useState } from 'react';
import Square from '../MagicLayout/Audiogram/Square';
import Portrait from '../MagicLayout/Audiogram/Portrait';
import Landscape from '../MagicLayout/Audiogram/Landscape';
import { AudiogramCommonProps } from '../MagicLayout/Audiogram/types';
import { getTruncatedName, getTruncatedTitle } from '../CompositePlayer/SpeakerVideoUtils';
import { PreviewTemplate } from './types';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { ClipMetadata } from '@/domains/asset';

export default function AudiogramPreview({
  clipTemplate,
  currentSpeaker
}: {
  clipTemplate: PreviewTemplate | ClipMetadata;
  currentSpeaker: SpeakerWithDetails;
}) {
  const [truncatedTitle, setTruncatedTitle] = useState('');
  const [truncatedName, setTruncatedName] = useState('');

  const size = clipTemplate.size;
  const templatePreviewContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentSpeaker) {
      setTruncatedTitle(getTruncatedTitle(currentSpeaker, 'AUDIOGRAM', size));
      setTruncatedName(getTruncatedName(`${currentSpeaker.first_name} ${currentSpeaker.last_name}`, 'AUDIOGRAM', size));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpeaker]);

  const commonProps: AudiogramCommonProps = {
    backgroundColor: clipTemplate.magicLayout?.backgroundColor!,
    backgroundImage: clipTemplate.magicLayout?.backgroundImage,
    textColor: clipTemplate.magicLayout?.textColor!,
    width: templatePreviewContainer.current?.getBoundingClientRect().width || 0,
    height: templatePreviewContainer.current?.getBoundingClientRect().height || 0,
    activeSpeaker: currentSpeaker,
    truncatedTitle,
    truncatedName,
    fontFamilyStyle: {},
    isTemplatePreview: true
  };

  return (
    <>
      <div
        className="absolute left-0 top-0 h-full w-full rounded bg-white"
        data-testid="audiogram-container"
        ref={templatePreviewContainer}
      />

      {size === 'SQUARE' && <Square sizeMultiplier={0.5} {...commonProps} />}
      {size === 'PORTRAIT' && <Portrait sizeMultiplier={0.9} {...commonProps} />}
      {size === 'LANDSCAPE' && <Landscape sizeMultiplier={0.8} {...commonProps} />}
    </>
  );
}
