import { Fragment, useEffect, useMemo, useRef, useState, useSyncExternalStore } from 'react';
import {
  AssetsContentUploadUpdateProps,
  ContentUpload,
  PaginatedSpeakerResponseSerializerV2List,
  SpeakerResponseSerializerV2,
  assetsContentUploadUpdate,
  userSpeakerBulkCreateCreate,
  userSpeakerBulkUpdateCreate
} from '@goldcast/api/content';
import moment from 'moment';
import { useParams, useSearchParams } from 'react-router-dom';
import SetupContentModal from '../SetupContent/SetupContentModal';
import { RECORDING_DETAILS_STEP } from '../constants';
import { areAllDefaultSpeakers, getDefaultSpeakersValues, getSetupContentSteps, mapSpeakerToRequest } from './utils';
import { RecordingDetails, SetupContentStep } from '../types';
import { areSpeakerDetailsChanged, getInitialSpeakerValues } from '../SetupContent/utils';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import useDialog from '@/components/organisms/useDialog';
import { core } from '@/stores/core';
import ConfirmationDialog from '@/components/organisms/ConfirmationDialog';
import { useAppContext } from '@/context/AppContext/AppContext';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { isEmptyObject } from '@/libs/utils';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import { doesSpeakerSpeakInClip } from '@/Pages/Clip/CompositePlayer/CompositePlayerUtils';
import { speakersAnalysisStore, updateSpeakersAnalysisStoreWithId } from '@/stores/speakersAnalysis/speakersAnalysis';
import { isCustomUpload } from '@/libs/clipContentUtil';
import { getEnv } from '@/libs/env';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import useAnalytics from '@/hooks/useAnalytics';
import { currentClip } from '@/stores/clip';
import { Clip } from '@/domains/asset';

let clipData: Clip = {} as Clip;

export default function IdentifySpeakers() {
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const clipStore = useSyncExternalStore(currentClip.subscribe, currentClip.getSnapshot);
  const transcriptStore = useTranscriptContext();
  const { logger } = useAppContext();
  const [searchParams] = useSearchParams();
  const { clipId: routeClipId } = useParams<{ clipId: string }>();
  const featureFlags = useSyncExternalStore(featureFlagStore.subscribe, featureFlagStore.getSnapshot);
  const { trackSpeakersIdentified } = useAnalytics();
  const [isInlineIdentification, setIsInlineIdentification] = useState(false);

  const isEasyClipCustomizerEnabled = featureFlags[FeatureFlagKeys.Use_CL_Easy_Clip_Customizer];
  const clipId = useMemo(() => {
    return isEasyClipCustomizerEnabled ? searchParams.get('activeClipId') || undefined : routeClipId;
  }, [isEasyClipCustomizerEnabled, searchParams, routeClipId]);

  const postIdentifyCallbackRef = useRef<() => void>();

  const [dialogTitle, setDialogTitle] = useState(RECORDING_DETAILS_STEP.title);
  const [isVideoInitialization, setIsVideoInitialization] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dialogSteps, setDialogSteps] = useState<SetupContentStep[]>([]);
  const [speakerValues, setSpeakerValues] = useState<Record<string, SpeakerWithDetails>>({});
  const [recordingDetails, setRecordingDetails] = useState<RecordingDetails>({
    title: coreStore.uploadData?.title || '',
    recording_date: coreStore.uploadData?.recording_date || moment().format('YYYY-MM-DD')
  });
  const [shouldNotCloseDialog, setShouldNotCloseDialog] = useState(false);

  const { isOpen, openDialog, closeDialog } = useDialog();
  const {
    isOpen: isConfirmationOpen,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog
  } = useDialog();

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.OpenSpeakersIdentification, identifyRecordingSpeakers);
    return () => {
      EventBus.off(CustomEvents.OpenSpeakersIdentification, eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptStore.speakersWithDetails, speakerValues, clipData?.asset_metadata?.layout, clipStore]);

  const confirmationMessage = useMemo(() => {
    return isVideoInitialization
      ? 'Proceeding will auto-fill remaining speakers data with defaults. You can restart identification later in Settings.'
      : 'Closing dialog will discard all unsaved changes.';
  }, [isVideoInitialization]);

  useEffect(() => {
    if (
      !isEmptyObject(transcriptStore.speakersWithDetails) &&
      coreStore.uploadData &&
      areAllDefaultSpeakers(transcriptStore.speakersWithDetails)
    ) {
      initSteps(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptStore.speakersWithDetails]);

  useEffect(() => {
    if (speakerValues && !isEmptyObject(speakerValues) && !isOpen) {
      if (isVideoInitialization) {
        logger.error('Storing values with auto active speaker changes', speakerValues);
      }
      storeDefaultValues();
    }
  }, [speakerValues, isOpen]);

  function identifyRecordingSpeakers(params) {
    if (params && params.postIdentifyCallback) {
      postIdentifyCallbackRef.current = params.postIdentifyCallback;
    }
    const isInlineIdentification = !!params?.isInlineIdentification;
    setShouldNotCloseDialog(!!params?.shouldNotCloseDialog);
    setIsInlineIdentification(isInlineIdentification);
    setIsVideoInitialization(false);
    initSteps(false, params?.layout, isInlineIdentification);
    openDialog();
  }

  function initSteps(
    isInitialPopup: boolean,
    layout = clipData?.asset_metadata?.layout,
    isInlineIdentification?: boolean
  ) {
    const useOnlyClipSpeakers = isCustomUpload() && layout === 'SPEAKER' && !!clipId;
    let speakers = [...transcriptStore.usedSpeakers];
    // remove speakers who don't speak in the clip
    // This happens when callback is sent through for active speaker
    if (useOnlyClipSpeakers) {
      const clipData = clipStore[clipId];
      const clipStart = clipData.asset_metadata.start;
      const clipEnd = clipData.asset_metadata.end;

      const nonClipSpeakers = {};
      speakers = speakers.filter(speaker => {
        nonClipSpeakers[speaker.key] = speaker;
        return doesSpeakerSpeakInClip(speaker.speakingSlots, clipStart, clipEnd);
      });
    }
    const steps = getSetupContentSteps(speakers, isInitialPopup, clipId, isInlineIdentification);
    if (steps.length) {
      setDialogTitle(steps[0].title);
      setDialogSteps(steps);
      setSpeakerValues(getInitialSpeakerValues(steps));
    }
  }

  function storeDefaultValues() {
    if (isSaving) return;
    if (isVideoInitialization) {
      setIsSaving(true);
      const defaultSpeakersPayload = getDefaultSpeakersValues(speakerValues);
      Promise.all([storeRecordingDetails(), storeSpeakers(defaultSpeakersPayload)])
        .then(([_, speakersResponse]) => {
          // Map items from response (such as id, updated details)
          const updatedSpeakers = (speakersResponse as SpeakerResponseSerializerV2[]).reduce((acc, el) => {
            return {
              ...acc,
              [el.label]: {
                ...el,
                key: el.label
              }
            };
          }, {});
          updateSpeakersWithDetails(updatedSpeakers);
          runPostIdentifyCallbackRef();
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      closeConfirmationDialog();
      closeDialog();
    }
  }

  function saveFaceIdsInVision() {
    if (!clipId) return;
    const speakers = Object.values(speakerValues);

    // Get already existing speaker mappings
    const mappings = { ...speakersAnalysisStore.getSnapshot()[clipId].speaker_mapping };

    // update the speaker mappings only for the speakers whose info has been updated
    speakers.forEach(speaker => {
      if (speaker.face_ids?.length) {
        mappings[speaker.id] = speaker.face_ids;
      } else {
        delete mappings[speaker.id];
      }
    });

    if (shouldNotCloseDialog && !speakers.every(speaker => mappings[speaker.id]?.length)) {
      throw new Error('Please identify all speakers before saving.');
    }

    if (clipId) {
      updateSpeakersAnalysisStoreWithId(clipId, {
        speaker_mapping: mappings
      });
    }

    const mappingsArray: {
      face_id: number;
      id: string;
    }[] = [];

    // form mappings array from mappings object
    Object.entries(mappings).forEach(mapping => {
      const [id, face_ids] = mapping;
      face_ids.forEach(face_id => {
        mappingsArray.push({
          face_id,
          id
        });
      });
    });

    if (clipId) {
      fetch(`${getEnv('VISION_URL')}/recognize/speakers/${clipId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.accessToken}`
        },
        mode: 'cors',
        body: JSON.stringify({
          mappings: mappingsArray
        })
      })
        .then(res => res.json())
        .catch(err => {
          logger.error('Error updating speaker mapping', err);
        });
    }
  }

  function save() {
    if (isSaving) return;

    setIsSaving(true);
    try {
      saveFaceIdsInVision();

      if (!isVideoInitialization) {
        updateExistingSpeakers(speakerValues).then(() => setIsSaving(false));
      } else {
        Promise.all([storeRecordingDetails(), storeSpeakers(speakerValues)]).finally(() => {
          trackSpeakersIdentified();
          EventBus.dispatch(CustomEvents.ReloadTranscript);
          runPostIdentifyCallbackRef();
          setIsSaving(false);
        });
      }
    } catch (e: any) {
      logger.error('Error saving speaker details', e);
      showErrorToast(e.message);
      setIsSaving(false);
    }
  }

  function runPostIdentifyCallbackRef() {
    if (postIdentifyCallbackRef.current) {
      postIdentifyCallbackRef.current();
    }
  }

  function storeRecordingDetails(): Promise<ContentUpload> {
    return assetsContentUploadUpdate({
      id: coreStore.uploadData?.id,
      body: {
        ...coreStore.uploadData,
        ...recordingDetails
      }
    } as AssetsContentUploadUpdateProps);
  }

  function storeSpeakers(
    speakerValues: Record<string, SpeakerWithDetails>
  ): Promise<PaginatedSpeakerResponseSerializerV2List | void> {
    return userSpeakerBulkCreateCreate({
      queryParams: {},
      body: Object.keys(speakerValues).map(speakerKey => {
        return mapSpeakerToRequest(speakerValues[speakerKey], coreStore.content!.id);
      })
    }).catch(() => {
      showErrorToast('We encountered an issue while saving identified speakers. Please try again in a few minutes.');
    });
  }

  function updateExistingSpeakers(speakerValues: Record<string, SpeakerWithDetails>) {
    return userSpeakerBulkUpdateCreate({
      queryParams: {},
      body: Object.values(speakerValues).map(speaker => {
        return mapSpeakerToRequest(speaker, coreStore.content!.id);
      })
    })
      .then(() => {
        closeDialog();
        showSuccessToast('Speakers updated successfully.');
        updateSpeakersWithDetails(speakerValues);
        runPostIdentifyCallbackRef();
      })
      .catch(() => {
        showErrorToast('We encountered an issue while saving identified speakers. Please try again in a few minutes.');
      });
  }

  function updateSpeakersWithDetails(speakerValues: Record<string, SpeakerWithDetails>) {
    const updatedSpeakers = { ...transcriptStore?.speakersWithDetails };
    Object.keys(speakerValues).forEach(speakerKey => {
      const speaker = speakerValues[speakerKey];
      updatedSpeakers[speaker.key] = {
        ...updatedSpeakers[speaker.key],
        ...speaker
      };
    });
    if (transcriptStore) EventBus.dispatch(CustomEvents.SpeakersChanged, { updatedSpeakers });
  }

  function closeIdentifySpeakerDialog() {
    if (isVideoInitialization || areSpeakerDetailsChanged(speakerValues, transcriptStore.speakersWithDetails)) {
      openConfirmationDialog();
    } else {
      closeDialog();
    }
  }

  return (
    <Fragment>
      {isOpen && (
        <ContentDialog
          isOpen={isOpen}
          setIsOpen={closeIdentifySpeakerDialog}
          title={dialogTitle}
          disableBackdropClose={true}
          size="medium"
          isHidden={isConfirmationOpen}
          panelClassNames="!max-h-[150vh]"
          hideCloseIcon={shouldNotCloseDialog}
          titleSize="medium"
        >
          <SetupContentModal
            recordingDetails={recordingDetails}
            speakerValues={speakerValues}
            isVideoInitialization={isVideoInitialization}
            isInlineIdentification={isInlineIdentification}
            steps={dialogSteps}
            setRecordingDetails={setRecordingDetails}
            setDialogTitle={setDialogTitle}
            onSave={save}
            onSkip={openConfirmationDialog}
            setSpeakerValues={setSpeakerValues}
            isSaving={isSaving}
          />
        </ContentDialog>
      )}
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={closeConfirmationDialog}
        onConfirm={storeDefaultValues}
        confirmTrackingId="skip-speaker-identification-button"
        title="Close Speaker Identification?"
        content={confirmationMessage}
        confirmLabel="OK"
      />
    </Fragment>
  );
}
