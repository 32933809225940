import {
  AssetsListAPIQueryParams,
  AssetList,
  assetsAssetV2List,
  Asset,
  assetsClipUpdate,
  ClipAssetResponse,
  PaginatedAssetListList
} from '@goldcast/api/content';
import { Icon, IconDownload, IconEdit, IconPencil, IconTrash, IconSparkles } from '@tabler/icons-react';
import { IStringifyOptions } from 'qs';

export type MenuItemsLabelType = 'Download' | 'Rename' | 'Delete' | 'Edit' | 'Generate Social Post';

//TODO: use actual type i.e. TypeEnum
export const menuItems: Record<'CLIP' | 'TEXT', { label: MenuItemsLabelType; icon: Icon; clipListOnly?: boolean }[]> = {
  CLIP: [
    {
      label: 'Edit',
      icon: IconEdit
    },
    {
      label: 'Rename',
      icon: IconPencil
    },
    {
      label: 'Download',
      icon: IconDownload
    },
    {
      label: 'Generate Social Post',
      icon: IconSparkles
    },
    {
      label: 'Delete',
      icon: IconTrash
    }
  ],
  TEXT: [
    {
      label: 'Download',
      icon: IconDownload
    },
    {
      label: 'Delete',
      icon: IconTrash
    }
  ]
};

export function parseArrayQueryParam(queryParam: string | string[] | undefined): string[] {
  if (queryParam === undefined) {
    return [];
  }

  if (typeof queryParam === 'string') {
    return [queryParam];
  }

  return queryParam;
}

export const ASSET_TABLE_PAGE_LIMIT = 30;

type NonNullableAssetsListAPIQueryParams = {
  [K in keyof AssetsListAPIQueryParams]: NonNullable<Required<AssetsListAPIQueryParams>[K]>;
};

export type AssetsTableQueryParams = Pick<
  NonNullableAssetsListAPIQueryParams,
  'offset' | 'events' | 'broadcasts' | 'types' | 'search'
> & {
  sort_by?: keyof Pick<Asset, 'created_at' | 'title'>;
  desc?: boolean;
};

export const defaultQueryParams: AssetsTableQueryParams = {
  offset: 0,
  sort_by: 'created_at',
  desc: true
};

export function loadAssets(
  restQueryParams: AssetsTableQueryParams,
  ordering: string | undefined,
  offset: number | undefined
): Promise<PaginatedAssetListList> {
  return assetsAssetV2List({
    queryParams: {
      limit: ASSET_TABLE_PAGE_LIMIT,
      ordering,
      ...(offset !== undefined && { offset: offset * ASSET_TABLE_PAGE_LIMIT }),
      search: restQueryParams.search,
      types: restQueryParams.types,
      projects: restQueryParams.events,
      contents: restQueryParams.broadcasts
    },
    queryParamsStringifyOptions: {
      arrayFormat: 'comma'
    } as IStringifyOptions
  }).catch(err => Promise.reject(err));
}

export function updateAssetTitle(asset: AssetList): Promise<ClipAssetResponse> {
  if (!asset.asset_metadata.config) {
    return assetsClipUpdate({ id: asset.id, body: asset });
  }

  return assetsClipUpdate({
    id: asset.id,
    body: {
      ...asset,
      asset_metadata: {
        ...asset.asset_metadata,
        config: {
          ...asset.asset_metadata.config,
          filename: asset.title
        }
      }
    }
  });
}
