import { currentClip, updateClipInList, updateClipWithId } from '../clip';
import createStore from '../store';
import { defaultSharedAPIStore } from './constants';
import { speakersAnalysisStore } from '../speakersAnalysis/speakersAnalysis';
import { Clip, ClipMetadata } from '@/domains/asset';
import { getDefaultLayoutCanvasData } from '@/libs/sharedAPI/default';
import { getActiveSpeakerLayoutCanvasData } from '@/libs/sharedAPI/activeSpeaker';
import { updateClip } from '@/libs/clipContentUtil';
import { TranscriptContextData } from '@/context/TranscriptContext/TranscriptContextTypes';
import { SpeakerSegmentContextType } from '@/context/SpeakerSegmentContext/SpeakerSegmentContextTypes';
import { getSpeakerGridLayoutCanvasData } from '@/libs/sharedAPI/speakerGrid';
import { getAudiogramLayoutCanvasData } from '@/libs/sharedAPI/audiogram';

export interface SharedAPIStore {
  transcriptStore: TranscriptContextData;
  speakerSegmentStore: SpeakerSegmentContextType;
  mainPlayerRef: HTMLVideoElement | null;
  introPlayer: HTMLVideoElement | null;
  outroPlayer: HTMLVideoElement | null;
  speakerMap: Record<
    string,
    {
      videoRef: HTMLVideoElement;
    }
  >;
  speakerImageMap: Record<
    string,
    {
      imageRef: HTMLImageElement;
    }
  >;
}

export const sharedAPI = createStore<Record<string, SharedAPIStore>>({});

export function initSharedAPI(clipId: string) {
  sharedAPI.update(data => ({ ...data, [clipId]: defaultSharedAPIStore }));
}

export function updateSharedAPIWithId(clipId: string, data: Partial<SharedAPIStore>) {
  sharedAPI.update(store => ({ ...store, [clipId]: { ...store[clipId], ...data } }));
}

export function updateSpeakerImageMap(clipId: string, speakerKey: string, imageRef: HTMLImageElement) {
  sharedAPI.update(data => ({
    ...data,
    [clipId]: {
      ...data[clipId],
      speakerImageMap: {
        ...data[clipId].speakerImageMap,
        [speakerKey]: {
          ...data[clipId].speakerImageMap[speakerKey],
          imageRef
        }
      }
    }
  }));
}

export function updateSpeakerVideoMap(clipId: string, speakerKey: string, videoRef: HTMLVideoElement) {
  sharedAPI.update(data => ({
    ...data,
    [clipId]: {
      ...data[clipId],
      speakerMap: {
        ...data[clipId].speakerMap,
        [speakerKey]: {
          ...data[clipId].speakerMap[speakerKey],
          videoRef
        }
      }
    }
  }));
}

export function updateClipConfig(clipId, clip?: Clip) {
  if (!clip) {
    clip = currentClip.getSnapshot()[clipId];
  }
  let finalClip = { ...clip };
  try {
    let canvasJSON: ClipMetadata['config'] = null;
    const sharedAPIStoreForClip = sharedAPI.getSnapshot()[clipId];
    const commonParams = { clip: finalClip, sharedAPIStoreForClip };

    // TODO: @AshwinBhatkal Remove this with removal of Use_CL_Facial_Rec_Increment_AB
    const isAnalysisAccurate = !!speakersAnalysisStore.getSnapshot()[clipId].accurate;

    if (clip.asset_metadata.layout === 'DEFAULT') {
      canvasJSON = getDefaultLayoutCanvasData(commonParams);
    } else if (clip.asset_metadata.layout === 'SPEAKER') {
      canvasJSON = { ...getActiveSpeakerLayoutCanvasData(commonParams), isAnalysisAccurate };
    } else if (clip.asset_metadata.layout === 'GRID') {
      canvasJSON = { ...getSpeakerGridLayoutCanvasData(commonParams), isAnalysisAccurate };
    } else if (clip.asset_metadata.layout === 'AUDIOGRAM') {
      canvasJSON = getAudiogramLayoutCanvasData(commonParams);
    }

    // This update should contain clip param and not the current state of currentClip
    finalClip = {
      ...finalClip,
      asset_metadata: {
        ...finalClip.asset_metadata,
        config: canvasJSON
      }
    };
  } catch (e) {
    console.error('Error while updating clip config', e);
  }

  updateClipWithId(clipId, finalClip, true);
  updateClipInList(finalClip);

  return updateClip(currentClip.getSnapshot()[clipId]);
}
