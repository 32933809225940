import { useCallback, useState } from 'react';
import { ClipPlayerStore } from '@/stores/playerV2';
import classes from './Transcript.module.scss';

export default function useTranscriptHighlightHook() {
  const [srtIndex, setSrtIndex] = useState<number | undefined>();

  const highlightActiveWord = useCallback(
    (container: React.RefObject<HTMLDivElement>, playerStore: ClipPlayerStore) => {
      const currSrt = playerStore.currentSrtIndex;
      const currTime = playerStore.currentTime;
      container.current!.querySelector(`.${classes['active-word']}`)?.classList.remove(classes['active-word']);
      if (!container || isNaN(currSrt)) return;
      let tempSrtIndex = srtIndex;
      if (srtIndex !== currSrt - 1) {
        tempSrtIndex = currSrt > 0 ? currSrt - 1 : currSrt;
        setSrtIndex(tempSrtIndex);
      }
      if (!playerStore.paused) {
        const words = container.current!.querySelectorAll(`.srt-index-${tempSrtIndex}`);
        let highlightedWordElement;

        (words || []).forEach(word => {
          const { wordStartTime = '0', wordEndTime = '0' } = (word as HTMLDivElement).dataset;
          if (parseFloat(wordStartTime) <= currTime && parseFloat(wordEndTime) >= currTime) {
            highlightedWordElement = word as unknown as HTMLDivElement;
            (highlightedWordElement as HTMLDivElement)?.classList.add(classes['active-word']);
          }
        });

        if (highlightedWordElement) {
          const elementRect = highlightedWordElement.getBoundingClientRect();
          const containerRect = container.current!.getBoundingClientRect();
          // focus highlighted word only if it's in the viewport
          if (elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom) {
            highlightedWordElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        }
      }
    },
    [srtIndex]
  );

  return { highlightActiveWord };
}
